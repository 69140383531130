import styles from './tables.module.css';
import ArrowGreen from '../../../images/arrow-product-green-right.svg';
import { NavLink } from 'react-router-dom';
import { useActiveButton } from '../../../services/context/ActiveButtonContext';
import {useTheme} from "../../../services/hooks/useTheme";

function SubButtonLinks({ data }) {
    const {theme} = useTheme();
  const val = useActiveButton();

    const svgArrColor = () => {
        const color = theme === 'app_light_theme' ? '#047337' : '#37D881';
            return (
                <svg className={styles.arrowGreenImg} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M13.4142 4.92898L20.4853 12.0001M20.4853 12.0001L13.4142 19.0711M20.4853 12.0001H3.51472"
                          stroke={color} stroke-width="2"/>
                </svg>

            )
    }
    return (
        <div className={styles.buttonWrapper}>
            <NavLink to={'/glossary'} className={styles.subButton}>
                <div className={styles.buttonTxt}>Глоссарий инвестора</div>
                {svgArrColor()}
      </NavLink>
      <NavLink to={`/info/${data[0]?.instructions?.slug}`} className={styles.subButton}>
        <div className={styles.buttonTxt}>{data[0]?.instructions?.title}</div>
          {svgArrColor()}
      </NavLink>
    </div>
  );
}

export default SubButtonLinks;
