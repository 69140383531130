import styles from "./preloader-pay.module.css";



const Preloader = () => {

  return (
    <>
    <div className={styles.containerWrap}>
      <div className={styles.container}>
        <div className={styles.loader}></div>
        <div>
        <div className={styles.title}>
        Переходим в платежный шлюз
        </div>
        <div className={styles.subTitle}>
        Это может занять несколько секунд
        </div>
        </div>
      </div>
    </div>
    </>
  )
}

export default Preloader