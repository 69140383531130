import styles from "./first-description-product.module.css"
import { useMediaQuery } from "react-responsive"
import Logos from "../../../../../../../images/product-logos-company.svg"
import LogosMob from "../../../../../../../images/product-logos-company-mobile.svg"
import ArrowGreen from "../../../../../../../images/arrow-product-green-right.svg"
import logosGroup from "../../../../../../../images/product-logos-group-world.svg"
import logosGroupMob from "../../../../../../../images/product-logos-group-world-mob.svg"
import phone from "../../../../../../../images/product-phone-image-world.svg"
import { NavLink } from "react-router-dom"

const FirstDescription = () => {
  const isMobile = useMediaQuery({ query: "(max-width: 760px)" })

  return (
    <div className={styles.section}>
      <div className={styles.containerWrapper}>
        <div className={styles.mainTitle}>Повысите доходность</div>
        <div className={styles.container}>
          {!isMobile && <img src={phone} className={styles.phoneImg} />}
          <div className={styles.title}>
            Подскажем какие акции покупать и продавать
          </div>
          <div className={styles.subTitle}>
            Аналитические разборы 100 РФ 400 USA и 100 Китайских компаний,
            инвестиционные идеи
          </div>
          <NavLink
            to={
              "/analytics-and-news/williams-sonoma-vse-eshche-namnogo-deshevle-konkurentov"
            }
            className={styles.buttonWrapper}
          >
            <div className={styles.buttonTxt}>Пример</div>
            <img src={ArrowGreen} className={styles.arrowGreenImg} />
          </NavLink>
          {isMobile && (
            <div className={styles.imageWrapper}>
              <img src={phone} className={styles.phoneImg} />
              <img src={logosGroupMob} className={styles.logosGroupImgMob} />
            </div>
          )}
          {!isMobile && (
            <img src={logosGroup} className={styles.logosGroupImg} />
          )}
        </div>
      </div>
    </div>
  )
}

export default FirstDescription
