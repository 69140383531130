import styles from './can-might-be-actual-analytics.module.css';
import { NavLink, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useCallback } from 'react';

function CanMightBeActualAnalytics({ posts }) {
  // console.log(window.innerHeight);
  const subscriptionUser = JSON.parse(localStorage.getItem('subscription'));

  const anRecom = useCallback(() => {
    if (!!window.ym) {
      window.ym(96742836, 'reachGoal', 'an-recom');
    }
  }, []);

  // const checkSubscribe = (cardSubscribe) => {
  //   if (subscriptionUser) {
  //     return subscriptionUser.some((el) => {
  //       return el.subscription === cardSubscribe
  //     })
  //   }
  //   return false
  // }
  return (
    <div className={styles.canMightBeActualAnalytics}>
      <h3 className={styles.cmbiTitle}>Актуальная аналитика</h3>
      {posts.map((element) => (
        <NavLink
          onClick={anRecom}
          to={`/analytics-and-news/${element.slug}`}
          className={styles.linkBox}
          key={element.name}>
          <div className={styles.canMightBeInterestingCard}>
            {/* <div className={styles.cardBox}>
              
              { element.subscription === (null || undefined)  ? null : (
                <span
                  className={styles.subscription}
                >{`С подпиской ${element.subscription}`}</span>
              )}
             
            </div> */}
            <span className={styles.titleBox}>{element.name}</span>
            <span className={styles.pubDateBox}>
              {element.url ? element.pub_date_formated : element.pub_date_formated}
            </span>
          </div>
        </NavLink>
      ))}
    </div>
  );
}

export default CanMightBeActualAnalytics;
