import styles from "./PopUpCookie.module.css"
import { useCallback, useState } from "react"
import closeXSign from "../../images/close-x-sign-svg.svg"
import { setAuthChecked, setUser } from "../../services/reducers/user-slice"
import { useDispatch } from "react-redux"
import { useActiveButtonCountryTagChoice } from "../../services/context/CountryFilterContext"

const PopUpCookie = () => {
  const value = useActiveButtonCountryTagChoice()

  const handleClick = useCallback(() => {
    localStorage.setItem("cookie", false)
    value.setPopUpCookieVision(false)

  }, [])

  const handleClickOff = useCallback(() => {
    value.setPopUpCookieVision(false)
  }, [])
  return (
    <div className={styles.containerWrapper}>
      <div className={styles.container}>
        <div className={styles.columnBody}>
          <div className={styles.closeImg}>
            <img src={closeXSign} onClick={handleClickOff} />
          </div>
          <div className={styles.topPart}>
            <div className={styles.title}>
              Мы собираем cookie, данные об IP-адресе и местоположении
              пользователей. Оставаясь на сайте, вы соглашаетесь со сбором и
              обработкой данных
            </div>
          </div>

          <div className={styles.buttonWrapper}>
            <div className={styles.button} onClick={handleClick}>
            Согласен
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
export default PopUpCookie
