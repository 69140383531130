import styles from "./relevantSection.module.css"
import { useDispatch } from "react-redux"
import greenCheck from "../../images/green-check-svg.svg"
import { useMediaQuery } from "react-responsive"
import { setOrderingPoints } from "../../services/reducers/cards-filtred-slice"
import { setClearCards } from "../../services/reducers/cards-filtred-for-gallery-slice"
import { setOrderingVision } from "../../services/reducers/search-vision-points-slice"
import { useActiveFilters } from "../../services/context/ActiveFiltersContext"

const RelevantSection = ({ setActivRelevantWind, selectRelevant }) => {
  const dispatch = useDispatch()
  const filters = useActiveFilters()

  const funSelectRelevantPopular = () => {
    dispatch(setOrderingVision("Сначала популярные"))
    dispatch(setOrderingPoints("views"))
  }

  const funSelectRelevantNew = () => {
    dispatch(setOrderingVision("Сначала новые"))
    dispatch(setOrderingPoints("-pub_date"))
  }
  const funClearCards = (select) => {
      // dispatch(setClearCards())
    
  }
  const funActivRelevantWind = () => setActivRelevantWind(false)
  const funPageCount = () => {
    filters.setPageCount(1)
  }
  const isMobile = useMediaQuery({ query: "(max-width: 760px)" })

  return (
    <>
      {isMobile && (
        <div className={styles.containerWrapper}>
          <div className={styles.container}>
            <div className={styles.upTxt}>Сортировка</div>


           
            <div
            className={styles.button}
            onClick={() => {
              // funClearCards(selectRelevant)
              funSelectRelevantNew()
              funActivRelevantWind()

              funPageCount()
            }}
          >
              <div className={styles.checkWrapper}>
                {selectRelevant === "Сначала новые" && (
                  <img src={greenCheck} className={styles.img} />
                )}
              </div>
              Сначала новые
            </div>

            <div
            className={styles.button}
            onClick={() => {
              // funClearCards(selectRelevant)
              funSelectRelevantPopular()
              funActivRelevantWind()

              funPageCount()

            }}
          >
              <div className={styles.checkWrapper}>
                {selectRelevant === "Сначала популярные" && (
                  <img src={greenCheck} className={styles.img} />
                )}
              </div>
              Сначала популярные
            </div>





 






            <div
              className={styles.canselBut}
              onClick={() => {
                funActivRelevantWind()
              }}
            >
              Отмена
            </div>
          </div>
        </div>
      )}

      {!isMobile && (
        <div className={styles.container}>



        <div
        className={styles.button}
        onClick={() => {
          // funClearCards(selectRelevant)
          funSelectRelevantNew()
          funActivRelevantWind()

          funPageCount()
        }}
      >
        <div className={styles.checkWrapper}>
          {selectRelevant === "Сначала новые" && (
            <img src={greenCheck} className={styles.img} />
          )}
        </div>
        Сначала новые
      </div>


          <div
            className={styles.button}
            onClick={() => {
              // funClearCards(selectRelevant)
              funSelectRelevantPopular()
              funActivRelevantWind()

              funPageCount()

            }}
          >
            <div className={styles.checkWrapper}>
              {selectRelevant === "Сначала популярные" && (
                <img src={greenCheck} className={styles.img} />
              )}
            </div>
            Сначала популярные
          </div>










        </div>
      )}
    </>
  )
}

export default RelevantSection
