import styles from "./first-description-product.module.css"
import { useMediaQuery } from "react-responsive"
import phone from "../../../../../../images/product-phone-image-ideas-world.svg"
const FirstDescription = () => {
  const isMobile = useMediaQuery({ query: "(max-width: 760px)" })

  return (
    <div className={styles.section}>
      <div className={styles.containerWrapper}>
        <div className={styles.container}>
          <div className={styles.descriptionWrapper}>
            <div className={styles.title}>
              Посвящайте инвестициям 10 минут в день и обгоняйте индекс
            </div>
            <div className={styles.subTitle}>
              Торговые сигналы с обоснованием приходят в Telegram. Структура
              портфеля и потенциал роста доступны в онлайн режиме
            </div>
          </div>

          <img src={phone} className={styles.phoneImg} />
        </div>
      </div>
    </div>
  )
}

export default FirstDescription
