import styles from "./PopUpForExitSection.module.css"
import { useCallback, useState } from "react"
import closeXSign from "../../images/close-x-sign-svg.svg"
import { setAuthChecked, setUser } from "../../services/reducers/user-slice"
import { useDispatch } from "react-redux"
import { useActiveButtonCountryTagChoice } from "../../services/context/CountryFilterContext"
import { useNavigate } from "react-router-dom"
import {useTheme} from "../../services/hooks/useTheme";

const PopUpForExitSection = () => {
  const navigate = useNavigate()
  const value = useActiveButtonCountryTagChoice()
  const dispatch = useDispatch()
  // const isMobile = useMediaQuery({ query: "(max-width: 760px)" })
  const [selectValue, setSelectValue] = useState(false)
  const {theme} = useTheme();

  const handleClick = useCallback((event) => {
    if (event.target === event.currentTarget) {
      // Обработчик вызывается только если событие произошло на родительском элементе
      value.setPopUpExitVision((prev) => !prev)
    }
  }, [])
  const logout = useCallback(() => {
    localStorage.removeItem("token")
    localStorage.removeItem("subscription")
    dispatch(setUser(null))
    dispatch(setAuthChecked(false))
    navigate("/")
    value.setPopUpExitVision((prev) => !prev)
    // window.location.reload()
  }, [])
  return (
    <div className={`${styles.containerWrapper} ${theme}`} onClick={handleClick}>
      <div className={styles.container}>
        <div className={styles.columnBody}>
          <div className={styles.closeImg}>
            <img src={closeXSign} onClick={handleClick} />
          </div>
          <div className={styles.topPart}>
            <div className={styles.title}>Вы уверены, что хотите выйти?</div>
          </div>

          <div className={styles.buttonWrapper}>
            <div className={styles.button} onClick={logout}>
              Да
            </div>
            <div className={styles.buttonNoActive} onClick={handleClick}>
              Нет
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
export default PopUpForExitSection
