import {createSlice} from "@reduxjs/toolkit";

const initialState = {
    nextUrl: '',
}

const nextUrlSlice = createSlice({
    name: 'nextUrl',
    initialState,
    reducers: {
        setNextUrl: (state, action) => {
            state.nextUrl = action.payload
        }
    }
})

export const {
    setNextUrl
} = nextUrlSlice.actions

export default nextUrlSlice.reducer;