import styles from "./gallery-tables.module.css"
import { useState } from "react"
import leftArr from "../../../../../../../images/product-swiper-arrow-black-right.svg"
import Graph1Img from "../../../../../../../images/rus1-unity-graph.svg"
import Graph2Img from "../../../../../../../images/rus2-unity-graph.svg"
import Graph3Img from "../../../../../../../images/rus3-unity-graph.svg"
import Graph4Img from "../../../../../../../images/rus4-unity-graph.svg"
import Graph5Img from "../../../../../../../images/usa1-unity-graph.svg"
import Graph6Img from "../../../../../../../images/usa2-unity-graph.svg"
import Graph7Img from "../../../../../../../images/usa3-unity-graph.svg"

import { Swiper, SwiperSlide } from "swiper/react"
import "swiper/css"
// import 'swiper/css/pagination';
// import 'swiper/css/effect-cards';
// import 'swiper/css/effect-creative';
import {
  Pagination,
  Autoplay,
  EffectCoverflow,
  EffectCreative,
} from "swiper/modules"
import { useMediaQuery } from "react-responsive"
import LastBlock from "./last-block/last-block"

function GalleryTablesProduct() {
  const isMobile = useMediaQuery({ query: "(max-width: 760px)" })
  const hideBlock = useMediaQuery({ query: "(max-width: 310px)" })
  const [swiper, setSwiper] = useState(null)

  return (
    <div className={styles.section}>
      <div className={styles.containerWrapper}>
        <div className={styles.navigationBox}>
          <div className={styles.chartsTitle}>Наша доходность</div>
          {!isMobile && (
            <div className={styles.swipeBtns}>
              <button
                className={styles.swipeBtn}
                onClick={() => {
                  swiper && swiper.slidePrev()
                }}
              >
                <img src={leftArr} className={styles.leftArr} />
              </button>
              <button
                className={styles.swipeBtn}
                onClick={() => {
                  swiper && swiper.slideNext()
                }}
              >
                <img src={leftArr} className={styles.rightArr} />
              </button>
            </div>
          )}
        </div>

        <div className={styles.slider}>
          {!isMobile && (
            <Swiper
              onInit={(swiper) => setSwiper(swiper)}
              slidesPerView={2.5}
              spaceBetween={20}
              modules={[Autoplay, EffectCoverflow]}
              className={styles.swiper}
              style={{ "--swiper-theme-color": "#06813E" }}
              loop={false}
              rewind={false}
              scroll={false}
            >
              <SwiperSlide>
                <img src={Graph1Img} className={styles.img} />
              </SwiperSlide>
              <SwiperSlide>
                <img src={Graph2Img} className={styles.img} />
              </SwiperSlide>
              <SwiperSlide>
              <img src={Graph3Img} className={styles.img} />
            </SwiperSlide>
            <SwiperSlide>
              <img src={Graph4Img} className={styles.img} />
            </SwiperSlide>
            <SwiperSlide>
              <img src={Graph5Img} className={styles.img} />
            </SwiperSlide>
            <SwiperSlide>
              <img src={Graph6Img} className={styles.img} />
            </SwiperSlide>
            <SwiperSlide>
              <img src={Graph7Img} className={styles.img} />
            </SwiperSlide>
              <SwiperSlide>
                <div className={styles.img}>
                  <LastBlock
                    subName={"Ideas AllCategories"}
                    subHref={"PriceSection"}
                  />
                </div>
              </SwiperSlide>
            </Swiper>
          )}

          {isMobile && (
            <Swiper
              onInit={(swiper) => setSwiper(swiper)}
              slidesPerView={2}
              spaceBetween={8}
              // pagination={{
              //     clickable: true,
              // }}
              modules={[Autoplay, EffectCoverflow]}
              className={styles.swiper}
              style={{ "--swiper-theme-color": "#06813E" }}
              loop={false}
              rewind={false}
            >
            <SwiperSlide>
            <img src={Graph1Img} className={styles.img} />
          </SwiperSlide>
          <SwiperSlide>
            <img src={Graph2Img} className={styles.img} />
          </SwiperSlide>
          <SwiperSlide>
          <img src={Graph3Img} className={styles.img} />
        </SwiperSlide>
        <SwiperSlide>
          <img src={Graph4Img} className={styles.img} />
        </SwiperSlide>
        <SwiperSlide>
          <img src={Graph5Img} className={styles.img} />
        </SwiperSlide>
        <SwiperSlide>
          <img src={Graph6Img} className={styles.img} />
        </SwiperSlide>
        <SwiperSlide>
          <img src={Graph7Img} className={styles.img} />
        </SwiperSlide>
              {!hideBlock && <SwiperSlide>
                <div className={styles.img}>
                  <LastBlock
                    subName={"Ideas AllCategories"}
                    subHref={"PriceSection"}
                  />
                </div>
              </SwiperSlide>}
              <SwiperSlide />
            </Swiper>
          )}
        </div>
      </div>
    </div>
  )
}

export default GalleryTablesProduct
