function BreadcrumbList() {
  return (
    <script type="application/ld+json">
{`{
    "@context": "https://schema.org",
    "@type": "BreadcrumbList",
    "itemListElement": [{"@type":"ListItem","position":1,"name":"Главная","item":"https://${window.location.hostname}/"},{"@type":"ListItem","position":2,"name":"Аналитика и Новости","item":"https://${window.location.hostname}/analytics-and-news/"}]
}`}
</script>
  )
}

export default BreadcrumbList
