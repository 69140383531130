import cancel from '../../../images/cancel.png'
import attention from '../../../images/attention.svg'
import styles from './pop-up-for-safari.module.css'

export const PopUpForSafari = ({handleClosePopUp}) => {
    return (
        <div className={styles.popUpContainer}>
            <div className={styles.infoWrapper}>
                <p className={styles.header}>
                    <img src={attention} alt="attention icon" width={24}/>
                    Используйте другой браузер
                </p>
                <p>При использовании Safari сайт может работать нестабильно. Если у вас есть возможность, пожалуйста, используйте другой браузер</p>
            </div>
            <button onClick={handleClosePopUp} className={styles.btn}>
                <img src={cancel} alt="cancel icon" width={12}/>
            </button>
        </div>
    );
};