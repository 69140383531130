import React from "react";
import {getBackgroundColor} from "../utilsForMarketMap";
import styles from './custom-tooltip.module.css'

export const CustomTooltip = ({active, payload }) => {

    if (active && payload && payload.length) {
        const data = payload[0].payload;
        const dataWithoutHoveredItem = data.root.children.filter(el => el.name !== data.name)

        return (
            <div className={styles.customTooltip}>
                <p className={styles.title}>{data.root.name}</p>

                <div className={styles.hoveredItem}
                     style={{
                         backgroundColor: getBackgroundColor(data.priceChange),
                         borderRadius: dataWithoutHoveredItem.length ? '' : '8px'
                     }}>
                    <div className={styles.hoveredItemInfo}>
                        <p>{data.ticker}</p>
                        <div>
                            <p>{parseFloat(data.price.toFixed(2))}</p>
                            <p>{parseFloat(data.priceChange.toFixed(2))}%</p>
                        </div>
                    </div>
                    <div className={styles.fullName}>
                        {data.name}
                    </div>
                </div>

                {dataWithoutHoveredItem.map((el, index) => {
                    return <div key={index} className={styles.item}>
                        <p>{el.ticker}</p>
                        <div>
                            <p>{parseFloat(el.price.toFixed(2))}</p>
                            <p style={{color: getBackgroundColor(el.priceChange)}}>{parseFloat(el.priceChange.toFixed(2))}%</p>
                        </div>
                    </div>
                })}

            </div>
        );
    }

    return null;
};