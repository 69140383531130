import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink, useParams } from 'react-router-dom';
import { useActiveButton } from '../../../services/context/ActiveButtonContext';
import SubButtonLinks from './subButtonLink';
import CanMightBeActualAnalytics from '../../can-might-be-actual-analytics/can-might-be-actual-analytics';
import SEO from '../../../utils/SEO';
import BreadcrumbList from './seo-script';
import ArrowUp from '../../../images/Arrow--up-right.svg';
import Preloader from '../../preloader/preloader';
import styles from './tables.module.css';
import {useTheme} from "../../../services/hooks/useTheme";
// import {setSubForFetch, setTableName} from '../../../services/reducers/tables-slice';
// import CountryPageRussia from '../CountryPage/countryPageRussia/CountryPageRussia';

function Tables() {
  const { slug } = useParams();
  const { countryInfo } = useSelector((state) => state.countryInfo);
  const { isLoading } = useSelector((state) => state.preloader);
  const filteredObject = useSelector((store) => store.cardsMainAnalytics.mainAnalyticsCards);
  const dispatch = useDispatch();
  const val = useActiveButton();
  const {theme} = useTheme();
  const countArr = [
    { name: 'Ideas РФ', id: 1, link: 'ideas-russia' },
    { name: 'Ideas World', id: 2, link: 'ideas-world' },
    { name: 'Unity РФ', id: 3, link: 'unity-russia' },
    { name: 'Unity World', id: 4, link: 'unity-world' },
  ];

  const instrArr = [
    {
      active: 'ideas-russia',
      name: 'Ideas РФ',
      instructions: {
        title: 'Как использовать IDEAS Россия',
        slug: 'ideas-rf-instruktsiya',
      },
    },
    {
      active: 'ideas-world',
      name: 'Ideas World',
      instructions: {
        title: 'Как использовать IDEAS World',
        slug: 'ideas-world-instruktsiya',
      },
    },
    {
      active: 'unity-russia',
      name: 'Unity РФ',
      instructions: {
        title: 'Как использовать Unity Россия',
        slug: 'unity-rf-instruktsiya',
      },
    },
    {
      active: 'unity-world',
      name: 'Unity World',
      instructions: {
        title: 'Как использовать Unity World',
        slug: 'unity-world-instruktsiya',
      },
    },
  ];
  const [countryTitle, setCountryTitle] = useState(countArr);
  const [countryInstr, setCountryInstr] = useState([]);
  const [activeCountry, setActiveCountry] = useState(slug);

  /*  const objectWithSub = object.filter(
    (item) =>
      item.page_category[0].name === "Аналитика" && item.subscription !== null
  )
  const objectWithoutSub = object.filter(
    (item) =>
      item.page_category[0].name === "Аналитика" && item.subscription === null
  )
  let finalObject

  if (objectWithSub.length > 0) {
    finalObject = [
      ...objectWithSub.slice(0, 1),
      ...objectWithoutSub.slice(0, 2),
    ]
  } else {
    finalObject = objectWithoutSub.slice(0, 3)
  }

  const handleClick = (subName) => {
    dispatch(setSubForFetch(subName))
    // dispatch(setTableName(name))
  }*/

  useEffect(() => {
    val.getCardsMainAnalyticsContext();
  }, [val]);

  useEffect(() => {
    val.CanMightBeInteresting();
  }, [val]);

  useEffect(() => {
    val.getInfoForPathCounryApi(`${activeCountry}`);
  }, [activeCountry]);

  useEffect(() => {
    if (slug !== activeCountry) {
      setActiveCountry(slug);
      setCountryInstr(instrArr.filter((el) => el.active === slug));
    }
  }, [slug, activeCountry, instrArr]);

  useEffect(() => {
    setCountryInstr(instrArr.filter((el) => el.active === slug));
  }, []);

  const themeClassNameColor = theme === 'app_light_theme' ? '#323A47' : '#FFFFFF';

  const visibleElemsForIdeasRussia = () => {
    return countryInfo[0]?.tables[0]?.table?.map((item, index) => {
      const title = item?.title;

      const linksPortfolio = item?.links?.filter((linkItem, linkIndex) => {
        return linkItem?.portfolio;
      });

      const linksPortfolioElems = linksPortfolio[0]?.portfolio?.map((linkItem, linkIndex) => {
        return (
          <div className={styles.portfolioItem} key={linkIndex}>
            {Object.entries(linkItem)?.map(([key, value]) => {
              return (
                <div className={styles.portfolioItemWrapper} key={key}>
                  <div className={styles.portfolioKey}>{key}</div>
                  <div className={styles.dottedLine}></div>
                  <div
                    className={styles.portfolioValue}
                    style={{
                      color: `${
                        !key.includes('%')
                          ? 'themeClassNameColor'
                          : value > 0
                          ? '#539662'
                          : value < 0
                          ? '#FF5555'
                          : 'themeClassNameColor'
                      }`,
                    }}>
                    {value}
                  </div>
                </div>
              );
            })}
          </div>
        );
      });

      const links = item?.links?.filter((linkItem, linkIndex) => {
        return !linkItem?.portfolio;
      });

      const linksElems = links?.map((linkItem, linkIndex) => {
        return (
          <NavLink
            className={styles.navLink}
            to={linkItem.url.slice(0, linkItem.url.length - 1)}
            key={linkIndex}>
            {linkItem.name}
            <img src={ArrowUp} alt="arrow" />
          </NavLink>
        );
      });

      return (
        <div className={styles.infoForApi} key={index}>
          <div className={styles.title}>{title}</div>
          <div className={styles.navLinkContainer}>
            <div className={styles.portfolio}>{linksPortfolioElems}</div>
            <div className={styles.navLinkWrapper}>{linksElems}</div>
          </div>
        </div>
      );
    });
  };

  const visibleElemsForIdeasWorld = () => {
    const array = countryInfo[0]?.tables?.map((item, index) => {
      const mainTitle = item?.main_title;
      const mainTitleElems = (
        <div className={styles.mainTitle} key={index}>
          {mainTitle}
        </div>
      );

      const tableArray = item?.table?.map((item, index) => {
        const title = item?.title;

        const linksPortfolio = item?.links?.filter((linkItem, linkIndex) => {
          return linkItem?.portfolio;
        });

        const linksPortfolioElems = linksPortfolio[0]?.portfolio?.map((linkItem, linkIndex) => {
          return (
            <div className={styles.portfolioItem} key={linkIndex}>
              {Object.entries(linkItem)?.map(([key, value]) => {
                return (
                  <div className={styles.portfolioItemWrapper} key={key}>
                    <div className={styles.portfolioKey}>{key}</div>
                    <div className={styles.dottedLine}></div>
                    <div
                      className={styles.portfolioValue}
                      style={{
                        color: `${
                          !key.includes('%')
                            ? 'themeClassNameColor'
                            : value > 0
                            ? '#539662'
                            : value < 0
                            ? '#FF5555'
                            : 'themeClassNameColor'
                        }`,
                      }}>
                      {value}
                    </div>
                  </div>
                );
              })}
            </div>
          );
        });

        const links = item?.links?.filter((linkItem, linkIndex) => {
          return !linkItem?.portfolio;
        });

        const linksElems = links?.map((linkItem, linkIndex) => {
          return (
            <NavLink
              className={styles.navLink}
              to={linkItem?.url?.slice(0, linkItem?.url?.length - 1)}
              key={linkIndex}>
              {linkItem.name}
              <img src={ArrowUp} alt="arrow" />
            </NavLink>
          );
        });
        return (
          <div className={styles.infoForApi} key={index}>
            <div className={styles.title}>{title === 'null' ? '' : title}</div>
            <div className={styles.navLinkContainer}>
              <div className={styles.portfolio}>{linksPortfolioElems}</div>
              <div className={styles.navLinkWrapper}>{linksElems}</div>
            </div>
          </div>
        );
      });

      return { mainTitleElems, tableArray };
    });
    return (
      array &&
      array.map(({ mainTitleElems, tableArray }, index) => (
        <div key={index}>
          {mainTitleElems}
          {tableArray}
        </div>
      ))
    );
  };

  const visibleElemsUnityRussia = () => {
    const links = countryInfo[0]?.tables[0]?.table[0]?.links?.map((item, i) => {
      return (
        <NavLink
          to={item?.url?.slice(0, item?.url?.length - 1)}
          key={i}
          className={styles.unityLink}>
          {item?.name} <img src={ArrowUp} alt="arrow" />
        </NavLink>
      );
    });
    return <div className={styles.unityLinkWrapUp}>{links}</div>;
  };
  const visibleElemsUnityWorld = () => {
    const data = countryInfo[0]?.tables?.map((item, index) => {
      const title = (
        <div
          style={item.main_title === 'null' ? { display: 'none' } : null}
          className={styles.unityMainTitle}
          key={index}>
          {item?.main_title === 'null' ? '' : item?.main_title}
        </div>
      );

      const links = item?.table?.map((item, i) => {
        return (
          <div className={styles.unityLinkWrapUp} key={i}>
            {item?.links?.map((item, i) => {
              return (
                <NavLink
                  key={i}
                  to={item?.url?.slice(0, item?.url?.length - 1)}
                  className={styles.unityLink}>
                  {item?.name} <img src={ArrowUp} alt="arrow" />
                </NavLink>
              );
            })}
          </div>
        );
      });

      return {
        title,
        links,
      };
    });

    return (
      data &&
      data.map(({ title, links }, index) => (
        <div key={index}>
          {title} {links}
        </div>
      ))
    );
  };

  return (
    <>
      <SEO
        title={`${countryInfo[0]?.name ? countryInfo[0]?.name: 'Invest Era'} - собрать инвестиционный портфель | Invest Era`}
        description={`${countryInfo[0]?.name} и инвестидеи от Инвест Эра. Помогаем собрать инвестиционный ${countryInfo[0]?.name}. Рекомендации от специалистов по инвестированию`}
      />
      <section className={styles.section}>
        <div className={styles.containerWrapper}>
          <div className={styles.linkWrapper}>
            <NavLink to={'/'} className={styles.link}>
              <div className={styles.arrow}></div>
              Главная
            </NavLink>
          </div>

          <div className={styles.container}>
            <div className={styles.leftSide}>
              <h2 className={styles.title}>Портфели</h2>

              <div >
                <div className={styles.countryNames}>
                  {countryTitle?.map((item, i) => (
                      <NavLink
                          to={`/tables/${item.link}`}
                          className={`${styles.countryNameItem} ${
                              activeCountry === item.link ? styles.active : ''
                          }`}
                          key={i}>
                        {item.name}
                      </NavLink>
                  ))}
                </div>
                {isLoading && (
                    <div className={styles.preloader}>
                      <Preloader/>
                    </div>
                )}
                <h1 className={styles.titleh1}>{countryInfo[0]?.name}</h1>
                {activeCountry === 'ideas-russia' && visibleElemsForIdeasRussia()}
                {activeCountry === 'ideas-world' && visibleElemsForIdeasWorld()}
                {activeCountry === 'unity-russia' && visibleElemsUnityRussia()}
                {activeCountry === 'unity-world' && visibleElemsUnityWorld()}
                {<SubButtonLinks data={countryInstr}/>}
                {/* <CountryPageRussia path={activeCountry} /> */}
              </div>
            </div>
            <div className={styles.monitor}>
              <CanMightBeActualAnalytics posts={filteredObject}/>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Tables;
