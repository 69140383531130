import ReactDOM from "react-dom";
import styles from './modal.module.css'
import {useDispatch, useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
import {closeModal} from "../../services/reducers/modal-slice";
import {useEffect} from "react";
import Overlay from "../modal-overlay/modal-overlay";
import {setError} from "../../services/reducers/error-slice";

const modalRoot = document.getElementById('react-modals')
function Modal({children}) {

    const dispatch = useDispatch()
    const navigate = useNavigate()

    const onClose = () => {
        dispatch(closeModal(false))
        /*navigate('/')*/
        dispatch(setError(''))
    }

    useEffect(() => {
        function closeEsc(e) {
            if (e.key === 'Escape') {
                onClose()
            }
        }
        document.addEventListener('keydown', closeEsc)
        return () => document.removeEventListener('keydown', closeEsc)
    }, []);


    return ReactDOM.createPortal(
        (
            <>
                <div className={styles.modal}>
                    <button onClick={onClose} className={styles.button}>
                        <div className={styles.icon}></div>
                    </button>
                    {children}
                </div>
                <Overlay onClose={onClose}/>
            </>
        ), modalRoot
    );
}

export default Modal