import {createSlice} from "@reduxjs/toolkit";

const initialState = {
    mainAnalyticsCards: []
}

const cardsMainAnalyticsSlice = createSlice({
    name: 'cardsMainAnalytics',
    initialState,
    reducers: {
        setCardsMainAnalytics: (state, action) => {
            state.mainAnalyticsCards = action.payload
        }
    }
})

export const {
    setCardsMainAnalytics
} = cardsMainAnalyticsSlice.actions

export default cardsMainAnalyticsSlice.reducer;