import { useCallback, useContext, useState  } from "react";
import React from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setCardsMainAnalytics } from "../reducers/cards-main-analytics-slice"
import { setIsLoading } from "../reducers/preloader-slice"
import { getCardsMainAnalytics } from "../../utils/api";

const MainPageContext = React.createContext()

export const useMainPage = () => {
return useContext(MainPageContext)
}

export const MainPageProvider = ({ children }) => {
const dispatch = useDispatch()
const navigate = useNavigate()
  // useEffect(() => {
  //   getData()
  //   dispatch(getCardsMainNew())
  //   dispatch(getCardsMainAnalytics())
  // }, [])
  const getCardsMainAnalyticsContext = () => {
    getCardsMainAnalytics()
    .then((res) => {
      dispatch(setCardsMainAnalytics(res.results))
    })
    .catch((err) => {
      console.log(err)
    })
    .finally(() => {
      dispatch(setIsLoading(false))
    })
  }
  return (
    <MainPageContext.Provider value={{
      getCardsMainAnalyticsContext

    }}>
      {/* children */}
      <Outlet/>
    </MainPageContext.Provider>
  )
}