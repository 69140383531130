import {createContext, useState, useMemo} from "react";

export const ThemeContext = createContext({});

export const LOCAL_STORAGE_THEME_KEY = 'theme';

const defaultTheme = localStorage.getItem(LOCAL_STORAGE_THEME_KEY) || 'app_light_theme';

const ThemeProvider = ({children}) => {

    const [theme, setTheme] = useState(  defaultTheme || 'app_light_theme');

    const defaultProps = useMemo(() => ({
        theme,
        setTheme,
    }), [theme]);

    return (
        <ThemeContext.Provider value={defaultProps}>
            {children}
        </ThemeContext.Provider>
    );
};

export default ThemeProvider;
