import styles from "../pop-up-styles.module.css"
import closeXSign from "../../../../images/close-x-sign-svg.svg"
import successImg from "../../../../images/subscription-succes-img.svg"
import { useProfile } from "../../../../services/context/ProfileContext"
import { useCallback } from "react"
const SubscriptionCancelSecond = ({setShowWarningCancelModal, showWarningCancelModal}) => {
  const contextValue = useProfile()
  const handleTargetClick = useCallback((event) => {
    if (event.target === event.currentTarget) {
          contextValue.setCancelSubscription(prev => !prev)
          setShowWarningCancelModal(prev => !prev)
    }
  })
  const handleClick = useCallback(() => {
          contextValue.setCancelSubscription(prev => !prev)
          setShowWarningCancelModal(prev => !prev)
  })
  return (
    <div className={styles.containerWrapper} onClick={handleTargetClick}>
      <div className={styles.container}>
        <img src={closeXSign} className={styles.closeImg} onClick={handleTargetClick}/>
        
        <div className={styles.topBody}>

          <div className={styles.topTxtBody}>
          <img src={successImg} className={styles.successImg}/>
            Подписка на {showWarningCancelModal.name} отменена
          </div>
          <div className={styles.buttonTxtBody}>
          Вы можете продолжить пользоваться сервисом до {showWarningCancelModal.next_payment_date}
          </div>
        </div>
        
      <div className={styles.bottomBody}>
      <div className={styles.buttonWrapper} onClick={handleClick}>
        <div className={styles.buttonTxt}>
          Хорошо
        </div>
      </div>
    </div>
      </div>
    </div>
  )
}
export default SubscriptionCancelSecond
