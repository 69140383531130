import { NavLink, useNavigate, useParams } from "react-router-dom"
import { useEffect, useMemo, useState } from "react"
import { useProfile } from "../../../../../services/context/ProfileContext"
import TopMainTitle from "./unity-world-sections/top-main-title/top-main-title"
import GraphSliderGallery from "./unity-world-sections/graph-slider-gallery/graph-slider-gallery"
import FirstDescription from "./unity-world-sections/first-description-product/first-description-product"
import SecondFlagsDescription from "./unity-world-sections/second-flags-description-product/second-flags-description-product"
import EasyStart from "./unity-world-sections/easy-start/easy-start-product"
import SubscriptionPrice from "./unity-world-sections/subscription-price-section/subscription-price"
import PromotionPromocode from "./unity-world-sections/promotion-promocode/promotion-promocode"
import Review from "./unity-world-sections/reviews/reviews"
import VideoSection from "./unity-world-sections/video-section/video-section"
import GalleryTablesProduct from "./unity-world-sections/gallery-tables/gallery-tables"
import SEO from "../../../../../utils/SEO"
function SeoScript() {
  return (
    <script type="application/ld+json">
{`{
  "@context": "https://schema.org",
  "@type": "Product",
  "description": "Аналитические разборы 400 Американских и 100 Китайских компаний, инвестиционные идеи",
  "name": "Unity World",
  "image": "_.img",
  "offers": {
    "@type": "Offer",
    "price": "959.00",
    "priceCurrency": "RUB"
  }
}`}
</script>
  )
}
const UnityWorld = () => {
  const valueContext = useProfile()
  const { slug } = useParams()
  const navigate = useNavigate()

  useMemo(() => {
    // valueContext.pageFromSlugUrl(slug)
  }, [slug])

  // const card = valueContext.urlPageData
  return (
    <>
      <SEO
        title="Unity World - подписка от Invest Era"
        description="Индивидуально помогаем каждому подписчику увеличить доходность портфеля. В реальном времени отвечаем на ваши вопросы. • Доходность модельного портфеля вдвое обгоняет индекс,
        • Аналитические разборы по 500 компаниям США, Китая и других стран,
        • Обучение и вебинары"
        ogTitle="Unity World - подписка от Invest Era"
        ogDescription="• Доходность модельного портфеля вдвое обгоняет индекс,
        • Аналитические разборы по 500 компаниям США, Китая и других стран,
        • Обучение и вебинары"
        script={SeoScript}
      />
      <TopMainTitle />
      <GalleryTablesProduct />
      <FirstDescription />
      <EasyStart />
      <SecondFlagsDescription />
      <VideoSection />
      <SubscriptionPrice />
      {/*
      <PromotionPromocode />
      */}
      <Review />
    </>
  )
}

export default UnityWorld
