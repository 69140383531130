import styles from "./easy-start-product.module.css"
import { useMediaQuery } from "react-responsive"
import UsaFlag from "../../../../../../../images/product-circle-flag-usa.svg"
import ChainFlag from "../../../../../../../images/product-circle-flag-china.svg"
import EarthFlag from "../../../../../../../images/product-circle-flag-earth.svg"
import RusFlag from "../../../../../../../images/product-circle-flag-russia.svg"
import { useState } from "react"
const FirstDescription = () => {
  const isMobile = useMediaQuery({ query: "(max-width: 760px)" })
  const isIpad = useMediaQuery({ query: "(max-width: 1280px)" })

  const [state, setState] = useState(false)
  return (
    <div className={styles.section}>
      <div className={styles.containerWrapper}>
        <div className={styles.container}>
          <div className={styles.title}>Портфель под любой запрос</div>
          <div className={styles.subTitleWrapper}>
            <div className={styles.subTitle}>
              Выберите то, что подходит вам:
            </div>
          </div>

          <div className={state ? styles.flagBoxesLong : styles.flagBoxes}>
            <div className={styles.flagSectionWrapper}>
              <div className={styles.flagSection}>
                <img src={UsaFlag} className={styles.RusFlagImg} />
                <div className={styles.contextWrapper}>
                  <div className={styles.contextTitle}>США спекулятивный</div>
                  <div className={styles.contextSubTitle}>
                    Активная торговля
                  </div>
                </div>
              </div>
              <div className={styles.percentSection}>
                <div className={styles.contextSubTitle}>
                Обгоняет индекс {!isIpad ? <br/> : null} за 2022 — 2023
                </div>
                <div className={styles.percentWrapper}>
                  на <div className={styles.percent}>25%</div>
                </div>
              </div>
            </div>

            <div className={styles.flagSectionWrapper}>
              <div className={styles.flagSection}>
                <img src={UsaFlag} className={styles.RusFlagImg} />
                <div className={styles.contextWrapper}>
                  <div className={styles.contextTitle}>США оптимальный</div>
                  <div className={styles.contextSubTitle}>
                    Сбалансированный долгосрочный портфель
                  </div>
                </div>
              </div>
              <div className={styles.percentSection}>
                <div className={styles.contextSubTitle}>
                  Обгоняет индекс {!isIpad ? <br/> : null} за 2022 — 2023
                </div>
                <div className={styles.percentWrapper}>
                  на <div className={styles.percent}>9,9%</div>
                </div>
              </div>
            </div>

            <div className={styles.flagSectionWrapper} style={!isMobile ? null : !state ? {"padding-bottom":"200px"} : null}>
              <div className={styles.flagSection}>
                <img src={UsaFlag} className={styles.RusFlagImg} />
                <div className={styles.contextWrapper}>
                  <div className={styles.contextTitle}>США дивидендный</div>
                  <div className={styles.contextSubTitle}>
                    Целевая дивидендная доходность более 4%
                  </div>
                </div>
              </div>
              <div className={styles.percentSection} >
                <div className={styles.contextSubTitle}>
                  Обгоняет индекс {!isIpad ? <br/> : null} за 2022 — 2023
                </div>
                <div className={styles.percentWrapper}>
                  на <div className={styles.percent}>19,1%</div>
                </div>
              </div>
            </div>

            <div className={styles.flagSectionWrapper}>
              <div  className={styles.flagSection}>
                <img src={ChainFlag} className={styles.RusFlagImg} />
                <div className={styles.contextWrapper}>
                  <div className={styles.contextTitle}>Китай долгосрочный</div>
                  <div className={styles.contextSubTitle}>
                    Покупаем китайские акции на многолетних минимумах
                  </div>
                </div>
              </div>

              
              <div className={styles.percentSection}>
                <div className={styles.contextSubTitle}>
                  Обгоняет индекс {!isIpad ? <br/> : null} за 2022 — 2023
                </div>
                <div className={styles.percentWrapper}>
                  на <div className={styles.percent}>13%</div>
                </div>
              </div>
            </div>

            <div className={styles.flagSectionWrapper}>
              <div className={styles.flagSection}>
                <img src={EarthFlag} className={styles.RusFlagImg} />
                <div className={styles.contextWrapper}>
                  <div className={styles.contextTitle}>ETF all world</div>
                  <div className={styles.contextSubTitle}>
                    На акции, облигации, сырье со всего мира
                  </div>
                </div>
              </div>
              <div className={styles.percentSection}>
                <div className={styles.contextSubTitle}>
                  Обгоняет индекс {!isIpad ? <br/> : null} за 2022 — 2023
                </div>
                <div className={styles.percentWrapper}>
                  на <div className={styles.percent}>12,3%</div>
                </div>
              </div>
            </div>

            <div className={styles.flagSectionWrapper}>
              <div className={styles.flagSection}>
                <img src={RusFlag} className={styles.RusFlagImg} />
                <div className={styles.contextWrapper}>
                  <div className={styles.contextTitle}>РФ спекулятивный</div>
                  <div className={styles.contextSubTitle}>
                    Активная торговля
                  </div>
                </div>
              </div>
              <div className={styles.percentSection}>
                <div className={styles.contextSubTitle}>
                  Обгоняет индекс {!isIpad ? <br/> : null} за июнь 2022 — апрель 2024
                </div>
                <div className={styles.percentWrapper}>
                  на <div className={styles.percent}>28,2%</div>
                </div>
              </div>
            </div>

            <div className={styles.flagSectionWrapper}>
              <div className={styles.flagSection}>
                <img src={RusFlag} className={styles.RusFlagImg} />
                <div className={styles.contextWrapper}>
                  <div className={styles.contextTitle}>РФ долгосрочный</div>
                  <div className={styles.contextSubTitle}>
                    Обгоняем рынок за счет грамотного выбора активов
                  </div>
                </div>
              </div>
              <div className={styles.percentSection}>
                <div className={styles.contextSubTitle}>
                  Обгоняет индекс {!isIpad ? <br/> : null} за июнь 2022 — апрель 2024
                </div>
                <div className={styles.percentWrapper}>
                  на <div className={styles.percent}>52,3%</div>
                </div>
              </div>
            </div>

            <div className={styles.flagSectionWrapper}>
              <div className={styles.flagSection}>
                <img src={RusFlag} className={styles.RusFlagImg} />
                <div className={styles.contextWrapper}>
                  <div className={styles.contextTitle}>
                    Российские облигации
                  </div>
                  <div className={styles.contextSubTitle}>
                    ВДО + корпоративные облигации
                  </div>
                </div>
              </div>
              <div className={styles.percentSection}>
                <div className={styles.contextSubTitle}>

                  Обгоняет индекс {!isIpad ? <br/> : null} за май 2023 — апрель 2024
                </div>
                <div className={styles.percentWrapper}>
                  на <div className={styles.percent}>18,5%</div>

                </div>
              </div>
            </div>



          </div>

          {isMobile && (
            <div
              className={state ? styles.button : styles.buttonActive}
              onClick={() => {
                setState((prev) => !prev)
              }}
            >
              Посмотреть всё
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default FirstDescription
