import styles from "./easy-start-product.module.css"
import { useMediaQuery } from "react-responsive"
import UsaFlag from "../../../../../../images/product-circle-flag-usa.svg"
import ChainFlag from "../../../../../../images/product-circle-flag-china.svg"
import EarthFlag from "../../../../../../images/product-circle-flag-earth.svg"
import RusFlag from "../../../../../../images/product-circle-flag-russia.svg"
const FirstDescription = () => {
  const isMobile = useMediaQuery({ query: "(max-width: 760px)" })
  const isIpad = true;

  return (
    <div className={styles.section}>
      <div className={styles.containerWrapper}>
        <div className={styles.container}>
          <div className={styles.title}>Портфель под любой запрос</div>
          <div className={styles.subTitleWrapper}>
            <div className={styles.subTitle}>
              Выберите то, что подходит вам:
            </div>
          </div>

          <div className={styles.flagBoxes}>
            <div className={styles.flagSectionWrapper}>
              <div className={styles.flagSection}>
                <img src={RusFlag} className={styles.RusFlagImg} />
                <div className={styles.contextWrapper}>
                  <div className={styles.contextTitle}>РФ спекулятивный</div>
                  <div className={styles.contextSubTitle}>
                    Активная торговля
                  </div>
                </div>
              </div>
              <div className={styles.percentSection}>
                <div className={styles.contextSubTitle}>
                Обгоняет индекс {!isIpad ? <br/> : null} за июнь 2022 — апрель 2024
                </div>
                <div className={styles.percentWrapper}>
                  на <div className={styles.percent}>28,2%</div>
                </div>
              </div>
            </div>

            <div className={styles.flagSectionWrapper}>
              <div className={styles.flagSection}>
                <img src={RusFlag} className={styles.RusFlagImg} />
                <div className={styles.contextWrapper}>
                  <div className={styles.contextTitle}>РФ долгосрочный</div>
                  <div className={styles.contextSubTitle}>
                    Обгоняем рынок за счет грамотного выбора активов
                  </div>
                </div>
              </div>
              <div className={styles.percentSection}>
                <div className={styles.contextSubTitle}>
                  Обгоняет индекс {!isIpad ? <br/> : null} за июнь 2022 — апрель 2024
                </div>
                <div className={styles.percentWrapper}>
                  на <div className={styles.percent}>52,3%</div>
                </div>
              </div>
            </div>

            <div className={styles.flagSectionWrapper}>
              <div className={styles.flagSection}>
                <img src={RusFlag} className={styles.RusFlagImg} />
                <div className={styles.contextWrapper}>
                  <div className={styles.contextTitle}>
                    Российские облигации
                  </div>
                  <div className={styles.contextSubTitle}>
                    ВДО + корпоративные облигации
                  </div>
                </div>
              </div>
              <div className={styles.percentSection}>
                <div className={styles.contextSubTitle}>

                  Обгоняет индекс {!isIpad ? <br/> : null} за май 2023 — апрель 2024
                </div>
                <div className={styles.percentWrapper}>
                  на <div className={styles.percent}>18,5%</div>

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default FirstDescription
