import styles from "./reviews.module.css"
import { useState } from "react"
import {ReactComponent as LeftArr} from "../../../../../../../images/product-swiper-arrow-black-right.svg"
import { Swiper, SwiperSlide } from "swiper/react"
import "swiper/css"
// import 'swiper/css/pagination';
// import 'swiper/css/effect-cards';
// import 'swiper/css/effect-creative';
import {
  Pagination,
  Autoplay,
  EffectCoverflow,
  EffectCreative,
} from "swiper/modules"
import { useMediaQuery } from "react-responsive"

function Review() {
  const isMobile = useMediaQuery({ query: "(max-width: 760px)" })
  const hiddenIpad = useMediaQuery({ query: "(max-width: 1024px)" })

  const hiddenMob = useMediaQuery({ query: "(max-width: 360px)" })
  const [swiper, setSwiper] = useState(null)
  const [expanded, setExpanded] = useState([])

  const reviews = [
    {
      name: "Никита",
      text: "Приобрел подписку на IE Unity около 3 месяцев назад. Изначально следил за товарищами на протяжении 4 месяцев,стараясь понять профессионализм (привет инфоцыганам). Качество выходивших обзоров компаний и происходящего на рынке убедило в умениях и навыках ребят. Приобрел подписку и не пожалел, так как получил еще больше качественных обзоров и разборов компаний, глубокого анализа ситуаций на рынке. Отдельно стоит упомянуть учебные материалы, которые дают более глубокое понимание происходящего на финансовых рынках.",
    },
    {
      name: "Дарья",
      text: "Я всего полгода с Вами, но вижу как стремительно развивается компания, насколько велико желание создать действительно полезный сервис; на любой вопрос поступает ответ, причем очень быстро, чаще всего в течении пары минут. Это удивляет и восхищает, думаю, что это требует большой мобилизации и дисциплины, часто ещё и терпения. Поверьте, мне есть с чем сравнить. Я была подписана на ХХХХХ платный канал и могу сказать, что обратной связи там совсем нет. Есть конечно стримы, где можно задать вопрос, но если возникает вопрос помимо и пишешь вопрос в поддержку, то ответ приходит в лучшем случае дня через 4. Я писала раза 4-5. Всякий раз одно и тоже. Через 4 дня поезд уже ушел, мне уже не нужно. Поэтому я страшно довольна, что я здесь. Огромное спасибо за ОЧЕНЬ информативный и полезный сервис!!!",
    },
    {
      name: "Иван",
      text: "Инвестирую в течении нескольких лет. Приходилось самому собрать различную информацию по компаниям и делать макроэкономическую, политическую и пр. оценки факторов, влияющих на рынки, формировать стратегию, которая на всегда была удачной. После подписки на канал мой «кругозор» инвестора расширился, фундаментальный и технический анализ команда канала делает великолепно, вовремя меняют стратегию и дают сигналы. И самое главное, на падающем рынке мой портфель растёт, что абсолютно точно заслуга Invest Era!",
    },
    {
      name: "Владислав",
      text: "Добрый вечер. Да, конечно, без проблем. Я считаю, что подписка у вас отличная, даёте очень много разной информации, как по отдельным компаниям, так и по рынкам, отраслям. Нравится что подаете материал достаточно подробно, очень часто публикуете посты (и не просто пару строк, а хорошие тексты). Нравится, что следите за состоянием рынка, портфелями, ищете разные интересные метрики – и вообще, чувствуется фундаментальный подход к анализу. Поэтому у меня, как у клиента, есть уверенность в вашей работе. Посмотрим, как это будет все работать дальше, пока проблем нет:) надеюсь будущий рост рынков позволит заработать с вашей помощью.",
    },
    {
      name: "Кирилл",
      text: "Огромную пользу приносят стримы Артёма, но я всегда смотрю их в записи. Из прикладных моментов, которые я не сделал бы без Вашего сервиса - это вход в банки, такие как ВАС или С, а также идея с TLT. Если выстрелит, я окуплю подписку с головой. В остальном, я ни капли не жалею инвестиций (не триты) на Вашу подписку, потому, что отказался от многих других ресурсов. Теперь всё в одном месте. В условиях дефицита времени это огромный плюс. В заключении скажу, что мне очень нравится интеллигентность и экспертность в подходе. Нет политики, нет нагнетания, нет кошмаров и сенсаций. Всё чётко. Как-то так. Если есть уточняющие вопросы, готов прокомментировать. Спасибо.",
    },
    {
      name: "Никита",
      text: "Здравствуйте! Сотрудничаю с командой Invest Era больше года. Очень ценю высокий профессионализм, концентрацию на результате, краткий и ёмкий стиль подачи информации. При этом фин результат говорит сам за себя. Так держать! Всем рекомендую!",
    },
  ]

  const handleExpand = (index) => {
    setExpanded((prevExpanded) => {
      const newExpanded = [...prevExpanded]
      newExpanded[index] = !newExpanded[index]
      return newExpanded
    })
  }

  return (
    <div className={styles.section}>
      <div style={{ "padding-top": "40px" }} className={styles.navigationBox}>
        <div className={styles.chartsTitle}>Отзывы</div>
        <div className={styles.swipeBtns}>
          <button
            className={styles.swipeBtn}
            onClick={() => swiper && swiper.slidePrev()}
          >

            <LeftArr className={styles.leftArr}/>
          </button>
          <button
            className={styles.swipeBtn}
            onClick={() => swiper && swiper.slideNext()}
          >
            <LeftArr className={styles.rightArr} style={{ stroke: "var(--arrow-svg-color)" }}/>

          </button>
        </div>
      </div>

      <div className={styles.slider}>
        {!isMobile && (
          <Swiper
            onInit={(swiper) => setSwiper(swiper)}
            slidesPerView={4}
            spaceBetween={20}
            // pagination={{
            //     clickable: true,
            // }}
            modules={[Autoplay, EffectCoverflow]}
            className={styles.swiper}
            style={{ "--swiper-theme-color": "#06813E" }}
            loop={false}
            rewind={false}
            // breakpoints={{
            //   767: {
            //     slidesPerView: 4,
            //     spaceBetween: 20,
            //   },
            // }}
          >
            {reviews.map((element, index) => (
              <SwiperSlide key={index}>
                <div className={styles.card}>
                  <div
                    className={styles.box}
                    style={{ maxHeight: expanded[index] ? "1000px" : "220px" }}
                  >
                    <h5 className={styles.name}>{element.name}</h5>
                    <span className={styles.review}>{element.text}</span>
                  </div>
                  <button
                    className={styles.expandButton}
                    onClick={() => handleExpand(index)}
                  >
                    {expanded[index] ? (
                      <span> Свернуть &#9650;</span>
                    ) : (
                      <span> Еще &#9660;</span>
                    )}
                  </button>
                </div>
              </SwiperSlide>
            ))}
            <SwiperSlide/>
            {hiddenIpad && <SwiperSlide/>}
          </Swiper>
        )}

        {isMobile && (
          <Swiper
            onInit={(swiper) => setSwiper(swiper)}
            slidesPerView={3}
            spaceBetween={16}
            // pagination={{
            //     clickable: true,
            // }}
            modules={[Autoplay, EffectCoverflow]}
            className={styles.swiper}
            style={{ "--swiper-theme-color": "#06813E" }}
            loop={false}
            rewind={false}
            // breakpoints={{
            //     1767: {
            //         slidesPerView: 2,
            //         spaceBetween: 16
            //     }
            // }}
          >
            {reviews.map((element, index) => (
              <SwiperSlide key={index}>
                <div className={styles.card}>
                  <div
                    className={styles.box}
                    style={{ maxHeight: expanded[index] ? "1000px" : "220px" }}
                  >
                    <h5 className={styles.name}>{element.name}</h5>
                    <span className={styles.review}>{element.text}</span>
                  </div>
                  <button
                    className={styles.expandButton}
                    onClick={() => handleExpand(index)}
                  >
                    {expanded[index] ? (
                      <span> Свернуть &#9650;</span>
                    ) : (
                      <span> Еще &#9660;</span>
                    )}
                  </button>
                </div>
              </SwiperSlide>
            ))}
            <SwiperSlide/>
            {hiddenMob && <SwiperSlide/>}

          </Swiper>
        )}
      </div>
    </div>
  )
}

export default Review
