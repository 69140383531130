import {createSlice} from "@reduxjs/toolkit";

const initialState = {
    openedCheckboxes: [],
}

const openedCheckboxesSlice = createSlice({
    name: 'opened-checkboxes',
    initialState,
    reducers: {
        setOpenedCheckboxes: (state, action) => {
            state.openedCheckboxes = action.payload
        }
    }
})

export const {
    setOpenedCheckboxes
} = openedCheckboxesSlice.actions

export default openedCheckboxesSlice.reducer;