import React, {useEffect, useState} from "react";
import {Dropdown, Menu, Space} from "antd";
import {CompanyItem} from "./company-item/company-item";
import crossIcon from "../../../../../images/cross.svg";
import arrowDown from "../../../../../images/arrow-down-sign-to-navigate-svg.svg";
import loop from '../../../../../images/loop.svg'
import cross from '../../../../../images/cross-search.svg'
import {getSearchList, getShareList} from "../../../../../utils/api";
import Preloader from "../../../../preloader/preloader";
import {SearchListItem} from "./search-list-item/search-list-item";
import styles from "./screener-main.module.css";

export const ScreenerMain = () => {
    const [companies, setCompanies] = useState([])
    const [sectors, setSectors] = useState([])
    const [selectedSector, setSelectedSector] = useState({id: 0, name: 'Отрасль'})
    const [loading, setLoading] = useState(false)
    const [isSearchFocusActive, setIsSearchFocusActive] = useState(false)
    const [searchValue, setSearchValue] = useState('')
    const [searchResults, setSearchResults] = useState([])
    const [isShowSearchList, setIsShowSearchList] = useState(false)
    const [isShowCrossIcon, setIsShowCrossIcon] = useState(false)

    const onChangeHandler = (e) => {
        setSearchValue(e.target.value)
    }

    const onFocusSearchHandler = () => {
        setIsSearchFocusActive(true)
        setIsShowSearchList(true)
        setIsShowCrossIcon(true)
    }

    const onBlurSearchHandler = () => {
        setIsSearchFocusActive(false)
        setIsShowSearchList(false)
        setIsShowCrossIcon(false)
    }

    const onSectorClick = (e) => {
        const sector = sectors.find(el => el.id === +e.key)
        setSelectedSector(sector)
    }

    const removeSelection = () => {
        setSelectedSector({id: 0, name: 'Отрасль'})
    }

    const clearSearchInput = () => {
        setSearchValue('')
    }

    useEffect(() => {
        setSearchValue('')
        setLoading(true)
        const sectorSearchId = selectedSector.id !== 0 ? selectedSector.id : ''
        getShareList(sectorSearchId).then(async (res) => {
            if (res.ok) {
                setLoading(false)
                const data = await res.json()
                setSectors(data.sectors)
                setCompanies(data.companies)
            } else {
                setLoading(false)
                console.log(res)
            }
        })
    }, [selectedSector]);

    useEffect(() => {
        if (searchValue !== '') {
            getSearchList(searchValue).then(async (res) => {
                if (res.ok) {
                    const data = await res.json()
                    setSearchResults(data)
                } else {
                    console.log(res)
                }
            })
        } else {
            setSearchResults([])
        }
    }, [searchValue]);

    const menu = (
        <Menu onClick={onSectorClick}>
            {sectors.length > 0 && sectors.map(item => (
                <Menu.Item
                    key={item.id}
                    className={`${item.id === selectedSector.id ? styles.selectedDropdownItem : ''} ${item.id === 0 && styles.notSelectItem}`}
                >
                    {item.name}
                </Menu.Item>
            ))}
        </Menu>
    );

    return (
        <div className={`${styles.screenerMain} ${loading && styles.screenerMainLoader}`}>

            <div className={styles.content}>
                <h2 className={styles.title}>Компании</h2>
                <div className={styles.searchContainer}>
                    <div
                        className={`${styles.searchInput} ${isSearchFocusActive && styles.fullWidthSearchInput}`}>
                        <input className={styles.search} type="text" onChange={onChangeHandler} value={searchValue}
                               placeholder={'Поиск'} onFocus={onFocusSearchHandler}
                               onBlur={onBlurSearchHandler}/>
                        <img src={loop} alt="loop icon" className={styles.loopIcon}/>
                        {isShowCrossIcon && searchValue && <img src={cross}
                                                 alt="cross icon"
                                                 className={styles.crossIcon}
                                                 onMouseDown={(e) => e.preventDefault()}
                                                 onClick={clearSearchInput}/>}
                        {searchResults.length > 0 && isShowSearchList && <div className={styles.searchList}>
                            {searchResults.map(item => {
                                return <SearchListItem key={`${item.ticker}-${item.id}`} title={item.name}
                                                    ticker={item.ticker} sector={item.sector[0].name}/>
                            })}
                        </div>}
                    </div>

                    {sectors.length > 0 && <Dropdown
                        className={`${styles.dropdown} ${selectedSector.id === 0 && styles.notValue} ${isSearchFocusActive && styles.notVisible}`}
                        overlay={menu}
                        trigger={['click']}
                    >
                        <a onClick={(e) => e.preventDefault()}>
                            <Space
                                className={styles.space}
                            >
                                <div className={styles.dropdownSpaceContainer}>
                                    {selectedSector.id !== 0 && <button className={styles.removeSelection}
                                                                        onClick={(e) => removeSelection(e)}
                                    >
                                        <img src={crossIcon} alt="крестик" width={10}/>
                                    </button>}
                                    {selectedSector.name}
                                </div>
                                <img src={arrowDown} alt="arrow down"/>
                            </Space>
                        </a>
                    </Dropdown>}
                </div>
                <div className={styles.itemsContainer}>
                    {loading ?
                        <div className={styles.preloaderContainer}>
                            <Preloader/>
                        </div> : companies.map((item) => {
                            return <CompanyItem key={`${item.ticker}-${item.id}`} img={item.logo} title={item.name}
                                                ticker={item.ticker} sector={item.sector.name}/>
                        })}
                </div>
            </div>
        </div>
    )
        ;
};


