import React, {useEffect, useState} from 'react';
import {Cell, Pie, PieChart, ResponsiveContainer, Tooltip} from "recharts";

import styles from './FractionDiagram.module.css'

const FractionDiagram = ({table}) => {
    const [activeIndex, setActiveIndex] = useState(null);
    const [hoveredListItem, setHoveredListItem] = useState(null);
    const [dataTable, setDataTable] = useState([]);
    const [isSortedValue, setIsSortedValue] = useState(true);


    // Фиксированные цвета
    const defaultColors = [
        '#00FF00', // Зеленый
        '#9ACD32', // Желтозеленый
        '#FFFF00', // Желтый
        '#FFD700', // Желто-оранжевый
        '#FFA500', // Оранжевый
        '#FF4500', // Красн-оранжевый
        '#FF0000', // Красный
        '#C71585', // Красно-фиолетовый (пурпурный)
        '#8A2BE2', // Фиолетовый
        '#4B0082', // Сине-фиолетовый
        '#0000FF', // Синий
        '#20B2AA', // Сине-зеленый
    ];

    // Функция интерполяции между двумя цветами
    function getRandomColor() {
        let letters = '0123456789ABCDEF'.split('');
        let color = '#';
        for (let i = 0; i < 6; i++) {
            color += letters[Math.round(Math.random() * 15)];
        }
        return color;
    }

    const CustomTooltip = (props) => {
        const { active, payload } = props;

        if (active && payload && payload.length) {
            const { name, value } = payload[0];
            return (
                <div className={styles.tooltip}>
                    <p>{`${name}: ${value} %`}</p>
                </div>
            );
        }

        if (hoveredListItem !== null) {
            const item = dataTable[hoveredListItem];
            return (
                <div className={styles.tooltip}>
                    <p>{`${item.name}: ${item.value}`}</p>
                </div>
            );
        }
        return null;
    };

    const arraySorted = (term, data) => {
        if ((term === 'Доля, %' || term === 'Доля') && !isSortedValue) {
            setDataTable(data.sort((a, b) => b.value - a.value));
            setIsSortedValue(true);
        } else if ((term === 'Доля, %' || term === 'Доля') && isSortedValue) {
            setDataTable(data.sort((a, b) => a.value - b.value));
            setIsSortedValue(false);
        }
    };

    const closeTooltipForMobile = () => {
        setHoveredListItem(null);
        setActiveIndex(null);
    };

    useEffect(() => {
        if (table.length > 0) {
            const transformData = table.filter((item) => {
                return (
                    item['Название'] &&
                    (item['Доля, %'] || item['Доля'])
                );
            });

            let colorGradientArr = [];

            // Заполняем массив до максимального размера из defaultColors
            for (let i = 0; i < Math.min(transformData.length, defaultColors.length); i++) {
                colorGradientArr.push(defaultColors[i]);
            }

            // Если данных больше, чем предопределенных цветов, добавляем случайные цвета
            while (transformData.length > colorGradientArr.length) {
                let randomColor = getRandomColor();
                colorGradientArr.push(randomColor);
            }

            const transformedDataForValueAndName = transformData
                .map((item, index) => {
                    const colorIndex = index % colorGradientArr.length;
                    const value = Number(item['Доля, %']) || Number(item['Доля']);

                    // Если value - NaN или undefined, установить значение 0
                    const validValue = isNaN(value) ? 0 : value;
                    return {
                        name: item['Название'] || item['Компания'],
                        value: validValue,
                        color: colorGradientArr[colorIndex],
                    };
                })
                .sort((a, b) => b.value - a.value);

            const transformedData = transformedDataForValueAndName.map((item, index) => ({
                ...item,
                color: colorGradientArr[index],
                index: index,
            }));

            setDataTable(transformedData);
        }
    }, [table]);
    return (
        <div className={styles.diagramm}>
            <div className={styles.container}>
                <div onClick={closeTooltipForMobile} className={styles.left}>
                    <div className={styles.topWrapper}>
                        <ResponsiveContainer>
                            <PieChart width="100%" height="100%" className={styles.pie}>
                                <Pie
                                    className={styles.pieWrapper}
                                    dataKey="value"
                                    data={dataTable}
                                    innerRadius={60}
                                    outerRadius={120}
                                    paddingAngle={0}
                                    // isAnimationActive={false}
                                    onMouseEnter={(data, index) => setActiveIndex(index)}
                                    onMouseLeave={() => setActiveIndex(null)}>
                                    {dataTable.map((entry, index) => (
                                        <Cell
                                            key={`cell-${index}`}
                                            className={`${styles.cell} ${activeIndex === index ? styles.active : ''}`}
                                            fill={entry.color}
                                        />
                                    ))}
                                </Pie>
                                {/* <Tooltip /> */}
                                <Tooltip content={<CustomTooltip data={dataTable[2]}/>}/>
                            </PieChart>
                        </ResponsiveContainer>
                    </div>
                    <div
                        className={`${styles.tooltipWrapper} ${hoveredListItem !== null ? styles.visible : ''}`}>
                        {hoveredListItem !== null && (
                            <div className={styles.tooltip}>
                                <p>{`${dataTable[hoveredListItem].name}: ${dataTable[hoveredListItem].value} %`}</p>
                            </div>
                        )}
                    </div>
                </div>

                <div className={styles.right}>
                    <div className={styles.titleTable}>
                        <div className={styles.tableNameTitle}>Название</div>
                        <div
                            onClick={() => arraySorted('Доля, %', dataTable)}
                            className={styles.tablePercentTitle}>
                            Доля, % {!isSortedValue ? '↑' : '↓'}
                        </div>
                        <div className={styles.tableColorTitle}>Цвет</div>
                    </div>
                    {dataTable.map((item, index) => (
                        <div
                            className={styles.rowtable}
                            key={index}
                            onMouseEnter={() => {
                                setActiveIndex(index);
                                setHoveredListItem(index);
                            }}
                            onMouseLeave={() => {
                                setActiveIndex(null);
                                setHoveredListItem(null);
                            }}>
                            <div className={`${styles.tableNameTitle} ${styles.tableText}`}>{`${index + 1} ${
                                item.name
                            }`}</div>
                            <div className={`${styles.tablePercentTitle} ${styles.tableText}`}>{item.value} %</div>
                            <div className={`${styles.tableColorTitle} ${styles.tableText}`}>
                                <div className={styles.blockColor} style={{backgroundColor: item.color}}></div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    )

};

export default FractionDiagram;
