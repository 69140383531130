import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { setStatusTimer } from '../../../services/reducers/timer';
import { Link } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
import StocksGift from '../../../images/stocksGift.png';
import MobileGift from '../../../images/mobileGift.png';
import StocksText from '../../../images/stocksText.png';
import MobileStocksText from '../../../images/mobileStocksText.png';
import Close from '../../../images/close-black-icon.svg';
import { getTimeRemaining, getZero } from '../../../utils/timer';

import styles from './stocks-all-page.module.css';

const StocksAllPage = () => {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [openFlag, setOpenFlag] = useState(false);
  const [mobileOpen, setMobileOpen] = useState(false);
  const [allTime, setAllTime] = useState(0);
  const [daysTimer, setDaysTimer] = useState(0);
  const [hoursTimer, setHoursTimer] = useState(0);
  const [minutesTimer, setMinutesTimer] = useState(0);
  const [secondsTimer, setSecondsTimer] = useState(0);
  const [timeDifference, setTimeDifference] = useState(false);
  const dayTitles = ['день', 'дня', 'дней'];
  const daysText = `${daysTimer}  ${getDeclension(daysTimer, dayTitles)}`;
  const isMobile = useMediaQuery({ query: '(max-width: 760px)' });

  function getDeclension(number, titles) {
    const cases = [2, 0, 1, 1, 1, 2];
    return titles[
      number % 100 > 4 && number % 100 < 20
        ? 2
        : cases[number % 10 < 5 ? number % 10 : 5]
    ];
  }

  const closeModalForDesctop = () => {
    setOpen(false);
    setOpenFlag(true);
  };

  const closeModalForMobile = () => {
    setMobileOpen(false);
    setOpenFlag(true);
  };

  useEffect(() => {
    const checkTimeDifference = () => {
      const targetDate = new Date('2024-06-26T16:30:00Z'); // 19:00 MSK (UTC +3)
      // const targetDate = new Date('2024-06-21T05:38:00Z'); // 19:00 MSK (UTC +3)
      const currentDate = new Date();
      const timeZeroForShowModal = targetDate - currentDate;

      if (timeZeroForShowModal <= 0) {
        setTimeDifference(true);
        dispatch(setStatusTimer(true));
        clearInterval(dateInterval);
      }
      // console.log('timeZeroForShowModal');
    };

    const dateInterval = setInterval(checkTimeDifference, 1000);

    return () => clearInterval(dateInterval);
  }, [dispatch]);

  useEffect(() => {
    let interval;

    if (timeDifference) {
      interval = setInterval(() => {
        const timer = getTimeRemaining('2024-07-02T18:00:00Z');
        // const timer = getTimeRemaining('2024-06-21T05:39:00Z');
        setAllTime(timer.total);
        setDaysTimer(timer.days);
        setHoursTimer(getZero(timer.hours));
        setMinutesTimer(getZero(timer.minutes));
        setSecondsTimer(getZero(timer.seconds));
        // console.log('tiktak');
      }, 1000);
    } else {
      clearInterval(interval);
    }

    if (allTime > 0) {
      setOpen(true);
    }

    if (allTime < 0 || localStorage.getItem('noPopUp')) {
      setOpen(false);
      setMobileOpen(false);
      setTimeDifference(false);
      dispatch(setStatusTimer(false));
      // console.log('tiktak close');
      clearInterval(interval);
    }

    if (openFlag) {
      setOpen(false);
      setMobileOpen(false);
      clearInterval(interval);
    }

    return () => clearInterval(interval);
  }, [allTime, dispatch, openFlag, timeDifference]);

  useEffect(() => {
    let timer;
    if (isMobile && timeDifference) {
      timer = setTimeout(() => {
        setMobileOpen(true);
      }, 10000);
    }

    return () => clearTimeout(timer);
  }, [isMobile, timeDifference]);

  return !isMobile ? (
    <div
      style={open === false ? { display: 'none' } : null}
      className={styles.stocksAllPage}
    >
      <div className={styles.wrapper}>
        <div className={styles.textImgBlock}>
          <img className={styles.textImg} src={StocksText} alt="gift" />
        </div>
        <div className={styles.monthStocks}>
          <div className={styles.gift}>До 2 месяцев в подарок</div>
          <div className={styles.giftTime}>
            При покупке подписки от полугода
          </div>
        </div>
        <div className={styles.stocksImg}>
          <img src={StocksGift} alt="gift" />
        </div>
        <div className={styles.stocksTimer}>
          {daysText} {hoursTimer}:{minutesTimer}:{secondsTimer}
        </div>
        <Link to={'/profile'} className={styles.btn}>
          Приобрести подписку
        </Link>
        <div onClick={closeModalForDesctop} className={styles.close}>
          <img className={styles.closeImg} src={Close} alt="close" />
        </div>
      </div>
    </div>
  ) : (
    <div
      style={mobileOpen === false ? { display: 'none' } : null}
      className={styles.stocksAllPage}
    >
      <div className={styles.textImgBlock}>
        <img className={styles.textImg} src={MobileStocksText} alt="gift" />
      </div>
      <div className={styles.wrapper}>
        <div onClick={closeModalForMobile} className={styles.close}>
          <img className={styles.closeImg} src={Close} alt="close" />
        </div>
        <div className={styles.monthStocks}>
          <div className={styles.gift}>До 2 месяцев в подарок</div>
          <div className={styles.giftTime}>
            При покупке подписки от полугода
          </div>
        </div>

        <div className={styles.stocksTimer}>
          {daysText} {hoursTimer}:{minutesTimer}:{secondsTimer}
        </div>
        <div className={styles.stocksImg}>
          <img src={MobileGift} alt="gift" />
        </div>
        <Link
          onClick={closeModalForMobile}
          to={'/profile'}
          className={styles.btn}
        >
          Приобрести подписку
        </Link>
      </div>
    </div>
  );
};

export default StocksAllPage;
