function SeoScriptMainAnn() {
  return (
    <script type="application/ld+json">
{`{
    "@context": "https://schema.org",
    "@type": "BreadcrumbList",
    "itemListElement": [{"@type":"ListItem","position":1,"name":"Главная","item":"https://${window.location.hostname}/"}]
}`}
</script>
  )
}

export default SeoScriptMainAnn
