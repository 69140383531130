import styles from "./easy-start-product.module.css"
import { useMediaQuery } from "react-responsive"
import numberOne from "../../../../../../../images/circle-green-number-one.svg"
import numberTwo from "../../../../../../../images/circle-green-number-two.svg"
import numberThree from "../../../../../../../images/circle-green-number-three.svg"
import numberFour from "../../../../../../../images/circle-green-number-four.svg"
import numberFive from "../../../../../../../images/circle-green-number-five.svg"
import numberSix from "../../../../../../../images/circle-green-number-six.svg"
import numberSeven from "../../../../../../../images/circle-green-number-seven.svg"
import numberEight from "../../../../../../../images/circle-green-number-eight.svg"
import ChainFlag from "../../../../../../../images/product-circle-flag-china.svg"
import EarthFlag from "../../../../../../../images/product-circle-flag-earth.svg"
import { useState } from "react"

const FirstDescription = () => {
  const isMobile = useMediaQuery({ query: "(max-width: 760px)" })
  const [state, setState] = useState(false)

  return (
    <div className={styles.section}>
      <div className={styles.containerWrapper}>
        <div className={styles.container}>
          <div className={styles.mainTitle}>Как проходит работа</div>

          <div className={state ? styles.contentLong : styles.content}>
            <div className={styles.descriptionWrapper}>
              <div className={styles.titleWrapper}>
                <div className={styles.title}>Старт</div>
              </div>
              <div className={styles.boxesWrapper}>
                <div className={styles.boxes}>
                  <img src={numberOne} className={styles.numberImage} />
                  <div className={styles.subTitle}>Заполняете форму (бриф)</div>
                </div>

                <div className={styles.boxes}>
                  <img src={numberTwo} className={styles.numberImage} />
                  <div className={styles.subTitle}>
                    Проходите тест на риск-профиль
                  </div>
                </div>

                <div className={styles.boxes}>
                  <img src={numberThree} className={styles.numberImage} />
                  <div className={styles.subTitle}>
                    Обсуждаем проблемы портфеля и ваши инвестиционные цели
                  </div>
                </div>
              </div>
            </div>

            <div className={styles.descriptionWrapper}>
              <div className={styles.titleWrapper}>
                <div className={styles.title}>Работа над портфелем</div>
              </div>
              <div className={styles.boxesWrapper}>
                <div className={styles.boxes}>
                  <img src={numberFour} className={styles.numberImage} />
                  <div className={styles.subTitle}>
                    Проводим ребалансировку вашего портфеля
                  </div>
                </div>

                <div className={styles.boxes}>
                  <img src={numberFive} className={styles.numberImage} />
                  <div className={styles.subTitle}>
                    Проводим онлайн-консультацию и отвечаем на ваши вопросы
                  </div>
                </div>

                <div className={styles.boxes}>
                  <img src={numberSix} className={styles.numberImage} />
                  <div className={styles.subTitle}>
                    Дорабатываем портфель с учетом ваших пожеланий
                  </div>
                </div>
              </div>
            </div>

            <div className={styles.descriptionWrapper}>
              <div className={styles.titleWrapper}>
                <div className={styles.title}>Поддержка</div>
              </div>
              <div className={styles.boxesWrapper}>
                <div className={styles.boxes}>
                  <img src={numberSeven} className={styles.numberImage} />
                  <div className={styles.subTitle}>
                    Помогаем управлять портфелем
                  </div>
                </div>

                <div className={styles.boxes}>
                  <img src={numberEight} className={styles.numberImage} />
                  <div className={styles.subTitle}>
                    Раз в несколько месяцев проводим ребалансировк
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {isMobile && (
          <div
            className={state ? styles.button : styles.buttonActive}
            onClick={() => {
              setState((prev) => !prev)
            }}
          >
            Посмотреть всё
          </div>
        )}
      </div>
    </div>
  )
}

export default FirstDescription
