import styles from "./sectionSectorCheckBoxPoints.module.css"
import { setClearCards } from "../../services/reducers/cards-filtred-for-gallery-slice"
import { useDispatch } from "react-redux"
import checkBoxEmpty from "../../images/check-box-empty.svg"
import checkBoxFull from "../../images/check-box-full.svg"
import { useMediaQuery } from "react-responsive"
import closeXSign from "../../images/close-x-sign-svg.svg"
import { useState } from "react"
import searchImg from "../../images/search-svg.svg"
import { setSelectSectorPoint, setCheckBoxChoice, setClearSelectCompanyPoint } from "../../services/reducers/search-vision-points-slice"
import { setSectorPoints, setClearCompanyPoints } from  "../../services/reducers/cards-filtred-slice"
import { useActiveFilters } from "../../services/context/ActiveFiltersContext"


const FilterSection = ({
  // setPageCount,
  // setUpData,
  // setSelectSectorPoint,
  selectSectorPoint,
  sectorPoints,
  // setCheckBoxChoice,
  checkBoxChoice,
  selectCompanyPoint,
  // setSelectCompanyPoint,
  setActivSectorMenu,
}) => {
  const dispatch = useDispatch()
  const filters = useActiveFilters()

  const isMobile = useMediaQuery({ query: "(max-width: 760px)" })

  const funSetCheckBoxChoice = (type) => {
    let index = null
    const finder = checkBoxChoice.filter((el, i) => {
      if (el.id === type.id) {
        index = i
      }
      return el.id === type.id
    })
    if (finder.length !== 0) {
      // const numberToDelete = checkBoxChoice[index]
      // const filteredNumbers = checkBoxChoice.filter(
      //   (number) => number !== numberToDelete
      // )
      const reFreshCheckBox = checkBoxChoice.filter((el) => {
        const currentId = el.id

        return (
          !selectCompanyPoint.find((s) => s.id === currentId) &&
          el.id !== type.id
        )
      })
      
      dispatch(setCheckBoxChoice(reFreshCheckBox))
      // dispatch(setCheckBoxChoice(filteredNumbers))
    } else {
      const val = [...checkBoxChoice, type]
      dispatch(setCheckBoxChoice(val))
    }
  }

  const funSetSelectSectorPoint = (type) => {
    let index = null
    const finder = selectSectorPoint.filter((el, i) => {
      // если finder нашел повторение его длинна увеличится
      if (el.id === type.id) {
        index = i
      }
      return el.id === type.id
    })
    if (finder.length !== 0) {
      const numberToDelete = selectSectorPoint[index]
      const filteredNumbers = selectSectorPoint.filter(
        (number) => number !== numberToDelete
      )

      const reFreshCheckBox = checkBoxChoice.filter((el) => {
        const currentId = el.id

        return (
          !selectCompanyPoint.find((s) => s.id === currentId) &&
          el.id !== type.id
        )
      })

      dispatch(setSelectSectorPoint(filteredNumbers)) // для нижнего чек бокса 
      dispatch(setSectorPoints(filteredNumbers)) // для галлереи
      dispatch(setCheckBoxChoice(reFreshCheckBox))
      dispatch(setClearSelectCompanyPoint())
      dispatch(setClearCompanyPoints())

      // setSelectCompanyPoint([])
    } else {
      const val = [...selectSectorPoint, type]
      dispatch(setSelectSectorPoint(val)) // для нижнего чек бокса 
      dispatch(setSectorPoints(val)) // для галлереи
    }
  }

  // const funSetUpData = () => setUpData((prev) => !prev)
  // const funPageCount = () => filters.showMoreCardsBtn(1)

  const funClearCards = () => {
    // dispatch(setClearCards())
  }

  const [query, setQuery] = useState("")
  function inputChange(e) {
    setQuery(e.target.value)
  }

  const newArr = sectorPoints.filter((el) => {
    const newString = el.name.toLowerCase()
    const newString2 = query.toLowerCase()
    return newString.includes(newString2)
  })

  return (
    <div className={styles.containerWrapper}>
      {isMobile && <div className={styles.mobileFilterTxt}>Сектор</div>}
      <div className={styles.container}>
        <div className={styles.searchInputWrapper}>
          <img src={searchImg} className={styles.searchImg} />
          <input
            type="text"
            placeholder="Поиск"
            className={styles.input}
            onChange={inputChange}
          />
        </div>
        <div className={styles.mapButton}>
          {newArr.map((el) => (
            <div
              className={styles.button}
              key={el.id}
              onClick={() => {
                funClearCards()
                filters.setPageCount(1)

                funSetSelectSectorPoint(el)
                funSetCheckBoxChoice(el)
              }}
            >
              <div className={styles.checkWrapper}>
                {!!selectSectorPoint.find((point) => point.id === el.id) ===
                  true && <img src={checkBoxFull} className={styles.img} />}
                {!!selectSectorPoint.find((point) => point.id === el.id) ===
                  false && <img src={checkBoxEmpty} className={styles.img} />}
              </div>
              <div className={styles.txt}>{el.name}</div>
            </div>
          ))}
          {isMobile && <div className={styles.underSpaceToScroll}/>}
        </div>
      </div>
      
      {isMobile && (
        <div className={styles.aceptButtonWrapper}>
          
            <div
              className={styles.acceptButtonActive}
              onClick={() => {
                setActivSectorMenu(false)
              }}
            >
              Применить
            </div>
          
        </div>
      )}
      {isMobile && (
        <div className={styles.closeImg}>
          <img
            src={closeXSign}
            onClick={() => {
              setActivSectorMenu(false)
            }}
          />
        </div>
      )}
    </div>
  )
}

export default FilterSection
