export const extraining = {
  begin: {
    title1: 'Начинающим',
    beginnerData: [
      {
        id: 1,
        name: 'Личные финансы',
        navigate: '/education/lichnie-finansi',
      },
      { id: 2, name: 'Облигации', navigate: '/education/obligatsii-18' },
      {
        id: 3,
        name: 'Основы инвестиций',
        navigate: '/education/osnovi-investitsij',
      },
      {
        id: 4,
        name: 'Составляем портфель',
        navigate: '/education/sostavlyaem-portfel',
      },
      {
        id: 5,
        name: 'Фондовый рынок: основы',
        navigate: '/education/fondovij-rinok',
      },
    ],
  },
  expert: {
    title2: 'Опытным',
    exprertData: [
      {
        id: 6,
        name: 'Фондовый рынок: для опытных',
        navigate: '/education/obligatsii',
      },
      {
        id: 7,
        name: 'Личные финансы',
        navigate: '/education/lichnie-finansi',
      },
      { id: 8, name: 'Облигации', navigate: '/education/obligatsii-18' },
      {
        id: 9,
        name: 'Основы инвестиций',
        navigate: '/education/osnovi-investitsij',
      },
      {
        id: 10,
        name: 'Составляем портфель',
        navigate: '/education/sostavlyaem-portfel',
      },
    ],
  },
  advanced: {
    title3: 'Продвинутым',
    advancedData: [
      {
        id: 11,
        name: 'Фондовый рынок: профи',
        navigate: '/education/fondovij-rinok-profi',
      },
      {
        id: 12,
        name: 'Личные финансы',
        navigate: '/education/lichnie-finansi',
      },
      { id: 13, name: 'Облигации', navigate: '/education/obligatsii-18' },
      {
        id: 14,
        name: 'Основы инвестиций',
        navigate: '/education/osnovi-investitsij',
      },
      {
        id: 15,
        name: 'Составляем портфель',
        navigate: '/education/sostavlyaem-portfel',
      },
    ],
  },
};

export const portfel = [
  {
    id: 1,
    name: 'Ideas РФ',
    navigate: 'ideas-russia',
  },
  {
    id: 2,
    name: 'Ideas World',
    navigate: 'ideas-world',
  },
  {
    id: 3,
    name: 'Unity РФ',
    navigate: 'unity-russia',
  },
  {
    id: 4,
    name: 'Unity World',
    navigate: 'unity-world',
  },
];

export const profile = [
  {
    id: 1,
    name: 'Личный кабинет',
    navigate: 'profile',
  },
  {
    id: 2,
    name: 'Вебинары',
    navigate: 'webinars',
  },
];
