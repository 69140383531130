function BreadcrumbList(title, slug) {
  return (
    <script type="application/ld+json">
{`{
    "@context": "https://schema.org",
    "@type": "BreadcrumbList",
    "itemListElement": [{"@type":"ListItem","position":1,"name":"Обучения","item":"https://${window.location.hostname}/education"},{"@type":"ListItem","position":2,"name":"${title}","item":"https://${window.location.hostname}/education/${slug}"}]
}`}
</script>
  )
}

export default BreadcrumbList
