import { useContext, useState } from "react"
import React from "react"
import { Outlet, useNavigate } from "react-router-dom"
import { useDispatch } from "react-redux"
import { getLesson, getThemesCard, getThemesEducationCards } from "../../utils/api"
import { setIsLoading } from "../../services/reducers/preloader-slice"
import { useActiveButtonCountryTagChoice } from "./CountryFilterContext"

const EducationContext = React.createContext()

export const useEducationContext = () => {
  return useContext(EducationContext)
}

export const EducationProvider = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [themesData, setThemesData ] = useState([])
  const [lessonsData, setLessonsData] = useState([])
  const [lessonCardData, setLessonCardData ] = useState([])
  const val = useActiveButtonCountryTagChoice()


  const getThemesDataApi = () => {
    getThemesEducationCards()
    .then(async (res) => {
      const path = {
        404: "/not-found",
        // 401: "/authorization",
        403: "/error-403",
        500: "/error-500",
      }
      if (res.ok) {
        const data = await res.json()
        // console.log(data)
        setThemesData(data.results)
      } else if (res.status in path) {
        val.setBackendErrorCheck(true)
        navigate(path[res.status])
      } else {
        return res.json()
      }
    })
    .catch((err) => {
      console.log(err)
    })
    .finally(() => {
      dispatch(setIsLoading(false))
    })
      // .then((res) => {
      //   dispatch(setThemesCards(res.results))
      //   dispatch(setLessonsCards(res.results.map((element) => element.lessons)))
      // })
  }

const getLessonsDataApi = (slug) => {
  getThemesCard(slug)
  .then(async (res) => {
    const path = {
      // 404: "/not-found",
      // 401: "/authorization",
      403: "/error-403",
      500: "/error-500",
    }
    if (res.ok) {
      const data = await res.json()
      // console.log(data)
      setLessonsData(data)
    } else if (res.status in path) {
      navigate(path[res.status])
    } else {
      return res.json()
    }
  })
  .catch((err) => {
    console.log(err)
  })
  .finally(() => {
    dispatch(setIsLoading(false))
  })
  // .then((res) => {
  //   dispatch(setCardTheme(res))
  //   dispatch(setLessonsCards(res.lessons))
  // })
}

const getLessonCardDataApi = (name) => {
  getLesson(name)
  .then(async (res) => {
    const path = {
      // 404: "/not-found",
      // 401: "/authorization",
      403: "/error-403",
      500: "/error-500",
    }
    if (res.ok) {
      const data = await res.json()
      // console.log(data)
      setLessonCardData(data)
    } else if (res.status in path) {
      navigate(path[res.status])
    } else {
      return res.json()
    }
  })
  .catch((err) => {
    console.log(err)
  })
  .finally(() => {
    dispatch(setIsLoading(false))
  })
}









  // const dispatch = useDispatch()
  // const navigate = useNavigate()
  return (
    <EducationContext.Provider value={{
      themesData,
      getThemesDataApi,
      lessonsData,
      getLessonsDataApi,
      lessonCardData,
      getLessonCardDataApi
    }}>
      {/* children */}
      <Outlet />
    </EducationContext.Provider>
  )
}
