import { NavLink } from 'react-router-dom';
import { useState } from 'react';
import Close from '../../../images/close-x-sign-svg.svg';
import styles from './tables.module.css';
import styles1 from './tablesSubPanel.module.css';

function tablesSubPanel() {
  return (
    <div className={styles.notification}>
      <div className={styles.backgroundImage}></div>
      <div className={styles.textBox}>
        <p className={styles.notifTitle}>Портфели доступны с подпиской</p>
        <p className={styles.notifText}>
          Оформите подписку и получите доступ к свежей <br /> аналитике и новостям
        </p>
      </div>
      <NavLink
        to={'/profile'}
        // target={"_blank"}
        className={styles.buttonLink}>
        Оформить подписку
      </NavLink>
    </div>
  );
}

export default tablesSubPanel;

export const TableModalPanel = ({ data, close }) => {
  return (
    <div className={styles1.tableModalContainer}>
      <div onClick={close} className={styles1.closeButton}>
        <img src={Close} alt="close" />
      </div>
      <div className={styles1.tableModalTitle}>
        Полная версия доступна с подпиской {data[0]?.subscription}
      </div>
      <div className={styles1.tableModalSubTitle}>
        Оформите подписку {data[0]?.subscription} и получите портфель "{data[0]?.name}" и другие
        преимущества
      </div>
      <NavLink to={'/profile'} className={styles1.tableModalBtn}>
        Оформить подписку
      </NavLink>
      <div className={styles1.tableModalLoginBtn}>
        Если у Вас уже есть подписка, то
        <NavLink to={'/profile'} className={styles1.tableModalLoginBtnLink}>
          войдите
        </NavLink>
      </div>
    </div>
  );
};
