function SeoScriptMain() {
  return (
    <script type="application/ld+json">
{`{
    "@context": "https://schema.org",
    "@type": "ItemList",
    "url": "https://invest-era.ru",
    "numberOfItems": "7",
    "itemListElement": [
        {
            "@type": "Product",
            "url": "https://invest-era.ru/products/unity-russia",
            "name": "Unity Россия",
            "description": "Аналитические разборы 100 компаний РФ и инвестиционные идеи",
            "image": "_.img",
            "offers": {
                "@type": "Offer",
                "priceCurrency": "RUB",
                "price": "791.00"
            }
        },
      	{
            "@type": "Product",
            "url": "https://invest-era.ru/products/unity-world",
            "name": "Unity World",
            "description": "Аналитические разборы 400 Американских и 100 Китайских компаний, инвестиционные идеи",
            "image": "_.img",
            "offers": {
                "@type": "Offer",
                "priceCurrency": "RUB",
                "price": "959.00"
            }
        },
  		{
            "@type": "Product",
            "url": "https://invest-era.ru/products/unity-all",
            "name": "Unity All",
            "description": "Аналитические разборы 100 РФ 400 USA и 100 Китайских компаний, инвестиционные идеи",
            "image": "_.img",
            "offers": {
                "@type": "Offer",
                "priceCurrency": "RUB",
                "price": "1042.00"
            }
        },
  		{
            "@type": "Product",
            "url": "https://invest-era.ru/products/ideas-russia",
            "name": "Ideas Россия",
            "description": "Торговые сигналы с обоснованием приходят в Telegram. Структура портфеля и потенциал роста доступны в онлайн режиме",
            "image": "_.img",
            "offers": {
                "@type": "Offer",
                "priceCurrency": "RUB",
                "price": "1834.00"
            }
        },
  		{
            "@type": "Product",
            "url": "https://invest-era.ru/products/ideas-world",
            "name": "Ideas World",
            "description": "Торговые сигналы с обоснованием приходят в Telegram. Структура портфеля и потенциал роста доступны в онлайн режиме",
            "image": "_.img",
            "offers": {
                "@type": "Offer",
                "priceCurrency": "RUB",
                "price": "2000.00"
            }
        },
  		{
            "@type": "Product",
            "url": "https://invest-era.ru/products/ideas-all",
            "name": "Ideas All",
            "description": "Торговые сигналы с обоснованием приходят в Telegram. Структура портфеля и потенциал роста доступны в онлайн режиме",
            "image": "_.img",
            "offers": {
                "@type": "Offer",
                "priceCurrency": "RUB",
                "price": "2167.00"
            }
        },
  		{
            "@type": "Product",
            "url": "https://invest-era.ru/products/premium",
            "name": "Premium",
            "description": "Следуйте нашим торговым сигналам и обгоняйте индекс",
            "image": "_.img",
            "offers": {
                "@type": "Offer",
                "priceCurrency": "RUB",
                "price": "7500.00"
            }
        }
    ]
}`}
</script>
  )
}

export default SeoScriptMain
