import {createSlice} from "@reduxjs/toolkit";

const initialState = {
    isLoading: false,
}

const preloaderSlice = createSlice({
    name: 'preloader',
    initialState,
    reducers: {
        setIsLoading: (state, action) => {
            
            state.isLoading = action.payload
        }
    }
})

export const {
    setIsLoading
} = preloaderSlice.actions

export default preloaderSlice.reducer;