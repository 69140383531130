import styles from './second-flags-description-product.module.css';
import { useMediaQuery } from 'react-responsive';
import man from '../../../../../../images/main-page-man-mind-product-img.svg';
import BGElipse from '../../../../../../images/Ellipse-products-red-flag-section.svg';
import { NavLink } from 'react-router-dom';

const SecondDescription = () => {
  const isMobile = useMediaQuery({ query: '(max-width: 760px)' });

  return (
    <div className={styles.section}>
      <div className={styles.containerWrapper}>
        <img src={BGElipse} className={styles.background} />
        <div className={styles.container}>
          <div className={styles.description}>
            <div className={styles.title}>Все преимущества Unity</div>
            <div className={styles.subTitle}>Получите все то, что есть у подписчиков Unity</div>
          </div>
          <img src={man} className={styles.manImg} />
        </div>
      </div>
    </div>
  );
};

export default SecondDescription;
