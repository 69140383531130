import styles from "./last-block.module.css"
import arrow from "../../../../../../../../images/product-arrow-down-black.svg"
import Background from "../../../../../../../../images/product-last-block-background.svg"
function LastBlock({ subName, subHref }) {
  const scrollToAnchor = (anchorName) => {
    const anchorElement = document.getElementById(anchorName)
    if (anchorElement) {
      anchorElement.scrollIntoView({ behavior: "smooth", block: "start" })
    }
  }
  return (
    <div className={styles.section}>
    <img src={Background} className={styles.backgroundImage}/>
    
      <div className={styles.containerWrapper}>
        <div className={styles.title}>Выберите подписку {subName}</div>
        <div className={styles.buttonWrapper}
        // href="#price"
        onClick={() => {
          scrollToAnchor(subHref)
        }}
        >
          <div className={styles.buttonTxt}>
            Смотреть тарифы и цены <img src={arrow} className={styles.arrow}/>
          </div>
        </div>
      </div>
    </div>
  )
}

export default LastBlock
