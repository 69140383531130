import { useContext, useState } from 'react';
import React from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import {
  getTable,
  getAvailableTables,
  getInfoForPathCounry,
  getConfigurationNewTable,
  sendDataConfigNewTableToServer,
  getTableForUser,
  getUserSubscriptions,
} from '../../utils/api-tables';
import { useDispatch } from 'react-redux';
import { setIsLoading } from '../../services/reducers/preloader-slice';
import { setTables } from '../../services/reducers/tables-slice';
import { setAuthChecked, setUser } from '../../services/reducers/user-slice';
import { isClickEnter, isUserDelete } from '../reducers/modal-slice';
import { AnalyticsAndNewsDataApi } from '../../utils/api';
import { setObject } from '../../services/reducers/cards-slice';
import { getCardsMainAnalytics } from '../../utils/api';
import { setCardsMainAnalytics } from '../reducers/cards-main-analytics-slice';
import { useActiveButtonCountryTagChoice } from './CountryFilterContext';
import {
    setCountryInfo,
    setConfigNewTable,
    setUserSubs, setStatusLoad,
} from '../reducers/infoCountry/countryInfo';

const ActiveButtonContext = React.createContext();

export const useActiveButton = () => {
  return useContext(ActiveButtonContext);
};

export const ActiveButtonProvider = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [activeButton, setActiveButton] = useState(null);
  const [availableTables, setАvailableTables] = useState([]);
  const [tabNumber, setTabNumber] = useState(0);
  const val = useActiveButtonCountryTagChoice();

  const toggle = (num) => {
    setActiveButton(num);
  };
  const logout = () => {
    navigate('/authorization');
    localStorage.removeItem('token');
    localStorage.removeItem('subscription');
    dispatch(setUser(null));
    dispatch(setAuthChecked(false));
    dispatch(isUserDelete(true));
    dispatch(isClickEnter(false));
    // window.location.reload()
  };
  const getAvailableTablesContext = () => {
    if (!!localStorage.getItem('token')) {
      getAvailableTables()
        .then(async (res) => {
          if (res.ok) {
            const data = await res.json();

            setАvailableTables(data.available_tables);
          } else {
            return res.json();
          }
        })
        .then((res) => {
          if (!!res) {
            // console.log(res.detail);
          }
          if (!!res && res.detail === 'Недопустимый токен.') {
            logout(); // Включить для выброса!!!!!!!!!!!!!!!!!
          }
        });
    }
  };
  const CanMightBeInteresting = () => {
    AnalyticsAndNewsDataApi().then((res) => {
      dispatch(setObject(res.results));
    });
  };

  const getCardsMainAnalyticsContext = () => {
    getCardsMainAnalytics()
      .then((res) => {
        dispatch(setCardsMainAnalytics(res.results));
      })
      .catch((err) => {
        // console.log(err);
      })
      .finally(() => {
        dispatch(setIsLoading(false));
      });
  };

  const getTableFromApi = (sub, slug) => {
    dispatch(setIsLoading(true));
    dispatch(setTables([])); // чтоб старые данные таблиц не отображлись до подгрузки новых
    getTable(sub, slug)
      .then(async (res) => {
        // console.log(res, 'res');
        const path = {
          404: '/not-found',
          // 401: "/authorization",
          403: '/error-403',
          500: '/error-500',
        };

        if (res.ok) {
          const data = await res.json();
          // console.log(data, 'data');
          dispatch(setTables(data));
        } else if (res.status in path) {
          val.setBackendErrorCheck(true);
          navigate(path[res.status]);
        } else {
          return res.json();
        }
      })
      .then((res) => {
        // console.log(res);
        if (!!res) {
          // console.log(res.detail);
        }
        if (!!res && res.detail === 'Недопустимый токен.') {
          logout(); // Включить для выброса!!!!!!!!!!!!!!!!!
        }
      })
      .finally(() => {
        dispatch(setIsLoading(false));
      });
  };

  const getInfoForPathCounryApi = (sub) => {
    dispatch(setIsLoading(true));
      dispatch(setCountryInfo([]));
    getInfoForPathCounry(sub)
      .then(async (res) => {
        const path = {
          404: '/not-found',
          // 401: "/authorization",
          403: '/error-403',
          500: '/error-500',
        };

        if (res.ok) {
          const data = await res.json();
          dispatch(setCountryInfo(data));
          dispatch(setIsLoading(false));
        } else if (res.status in path) {
          val.setBackendErrorCheck(true);
          // navigate(path[res.status]);
        } else {
          return res.json();
        }
      })
      .then((res) => {
        if (!!res) {
          // console.log(res.detail);
        }
        if (!!res && res.detail === 'Недопустимый токен.') {
          logout(); // Включить для выброса!!!!!!!!!!!!!!!!!
        }
      })
      .finally(() => {
        dispatch(setIsLoading(false));
      });
  };

  const getConfigurationNewTableApi = () => {
      dispatch(setStatusLoad(true));
    getConfigurationNewTable()
      .then(async (res) => {
        const path = {
          404: '/not-found',
          // 401: "/authorization",
          403: '/error-403',
          500: '/error-500',
        };

        if (res.ok) {
          const data = await res.json();
          dispatch(setConfigNewTable(data));
            dispatch(setStatusLoad(false));
        } else if (res.status in path) {
          val.setBackendErrorCheck(true);
            dispatch(setStatusLoad(false));
          // navigate(path[res.status]);
        } else {
          return res.json();
        }
      })
      .then((res) => {
        // console.log(res);
        if (!!res) {
          // console.log(res.detail);
        }
        if (!!res && res.detail === 'Недопустимый токен.') {
          logout(); // Включить для выброса!!!!!!!!!!!!!!!!!
        }
      })
      .finally(() => {
          dispatch(setStatusLoad(false));
      });
  };

  const getUserSubscriptionsApi = () => {
    getUserSubscriptions()
      .then(async (res) => {
        const path = {
          404: '/not-found',
          // 401: "/authorization",
          403: '/error-403',
          500: '/error-500',
        };

        if (res.ok) {
          const data = await res.json();
          dispatch(setUserSubs(data));
        } else if (res.status in path) {
          val.setBackendErrorCheck(true);
          // navigate(path[res.status]);
        } else {
          return res.json();
        }
      })
      .finally(() => {
        dispatch(setIsLoading(false));
      });
  };

  // const sendDataConfigNewTableToServerApi = ( data) => {
  //   sendDataConfigNewTableToServer( data)
  //     .then(async (res) => {
  //       const path = {
  //         404: '/not-found',
  //         401: '/authorization',
  //         403: '/error-403',
  //         500: '/error-500',
  //       };

  //       if (res.ok) {
  //         const responseData = await res.json();
  //         dispatch(setCountryInfo(responseData));
  //       } else if (res.status in path) {
  //         val.setBackendErrorCheck(true);
  //         // navigate(path[res.status]);
  //       } else {
  //         return res.json();
  //       }
  //     })
  //     .then((res) => {
  //       console.log(res);
  //       if (!!res) {
  //         console.log(res.detail);
  //       }
  //       if (!!res && res.detail === 'Недопустимый токен.') {
  //         logout(); // Включить для выброса!!!!!!!!!!!!!!!!!
  //       }
  //     })
  //     .finally(() => {
  //       dispatch(setIsLoading(false));
  //     });
  // };
  return (
    <ActiveButtonContext.Provider
      value={{
        visible: activeButton,
        toggle,
        getTableFromApi,
        getInfoForPathCounryApi,
        getConfigurationNewTableApi,
        // sendDataConfigNewTableToServerApi,
        getAvailableTablesContext,
        getUserSubscriptionsApi,
        availableTables,
        CanMightBeInteresting,
        tabNumber,
        setTabNumber,
        getCardsMainAnalyticsContext,
      }}
    >
      <Outlet />
    </ActiveButtonContext.Provider>
  );
};
