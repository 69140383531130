import styles from "../pop-up-styles.module.css"
import closeXSign from "../../../../images/close-x-sign-svg.svg"
import warningImg from "../../../../images/subscription-warning-img.svg"
import { useProfile } from "../../../../services/context/ProfileContext"
import { NavLink, useNavigate } from "react-router-dom"
import { useCallback } from "react"
const SubscriptionWarning = () => {
  const contextValue = useProfile()
  const navigate = useNavigate()

  // const redirecTo = () => {
  //   navigate("/profile")
  //   contextValue.setPopUpActive(false)
  // }
  const handleTargetClick = useCallback((event) => {
    if (event.target === event.currentTarget) {
      // Обработчик вызывается только если событие произошло на родительском элементе
      contextValue.setPopUpActive(prev => !prev)
      navigate("/profile")
      
    }
  })
  const handleClick = useCallback(() => {
      // Обработчик вызывается только если событие произошло на родительском элементе
      contextValue.setPopUpActive(prev => !prev)
      navigate("/profile")
  })
  return (
    <div className={styles.containerWrapper} onClick={handleTargetClick}>
      <div className={styles.container}>
        <img src={closeXSign} className={styles.closeImg} onClick={handleTargetClick}/>
        
        <div className={styles.topBody}>

          <div className={styles.topTxtBody}>
          <img src={warningImg} className={styles.successImg}/>

            Оплата не прошла
          </div>
          <div className={styles.buttonTxtBody} >
            Попробуйте еще раз или свяжитесь с техподдержкой 
          </div>
        </div>
        
      <div className={styles.bottomBody}>
        
      <div className={styles.bottomBody}>
      <div className={styles.buttonWrapper} onClick={handleClick}>
        <div className={styles.buttonTxt}>
          Попробовать еще раз
        </div>
      </div>
      <NavLink to={'https://t.me/ie_contact'} target={'_blank'} className={styles.buttonWrapper}>
        <div className={styles.buttonTxt}>
          Написать в техподдержку
        </div>
      </NavLink>
    </div>
    </div>
      </div>
    </div>
  )
}
export default SubscriptionWarning
