import { setTables } from '../services/reducers/tables-slice';
import { setIsLoading } from '../services/reducers/preloader-slice';
// import { responseStatus } from "./api"
// import { useSelector } from "react-redux"
const responseStatus = async (res) => {
  // console.log(res.json());

  if (res.ok) {
    const contentType = res.headers.get('content-type');
    if (contentType && contentType.indexOf('application/json') !== -1) {
      return res.json();
    } else {
      throw new TypeError('Ошибка JSON');
    }
  }
  return Promise.reject(`Ошибка ${res.status}`);
};

export const getAvailableTables = () => {
  const token = localStorage.getItem('token');
  const headers = {
    'Content-Type': 'application/json',
    "Cache-Control": "no-cache",
  };
  if (!!token) {
    headers.Authorization = `Token ${token}`;
  }

  return fetch(`api/users/available-tables/`, {
    method: 'GET',
    headers,
  });
  // .then(responseStatus)
};

export const getTable = (sub, slug) => {
  const token = localStorage.getItem('token');
  const headers = {
    'Content-Type': 'application/json',
    "Cache-Control": "no-cache",
  };
  if (!!token) {
    headers.Authorization = `Token ${token}`;
  }
  return fetch(`api/tables/${sub}/${slug}/`, {
    method: 'GET',
    headers,
  });
  // .then(responseStatus)
  // .then((res) => {

  //   dispatch(setTables(res))
  // })
  // .catch((err) => {
  //   // console.log('!!!!!');

  //   console.log(err)
  // })
  // .finally(() => {
  //   dispatch(setIsLoading(false))
  // })
};

export const getInfoForPathCounry = (sub) => {
  const token = localStorage.getItem('token');
  const headers = {
    'Content-Type': 'application/json',
    "Cache-Control": "no-cache",
  };
  if (!!token) {
    headers.Authorization = `Token ${token}`;
  }
  return fetch(`api/tables/${sub}/`, {
    method: 'GET',
    headers,
  });
};

//получение конфига для таблица(ideas unity, РФ, unity WOrld, Россия)
export const getConfigTable = (slug) => {
  const token = localStorage.getItem('token');
  const headers = {
    'Content-Type': 'application/json',
    "Cache-Control": "no-cache",
  };
  if (!!token) {
    headers.Authorization = `Token ${token}`;
  }
  return fetch(`api/tables/${slug}/`, {
    method: 'GET',
    headers,
  });
};

//получение конфига в new-table(если там выбрали какие-то параметры)
export const getConfigurationNewTable = () => {
  const token = localStorage.getItem('token');
  const headers = {
    'Content-Type': 'application/json',
    "Cache-Control": "no-cache",
  };
  if (!!token) {
    headers.Authorization = `Token ${token}`;
  }
  return fetch(`api/tables-config/get-config/`, {
    method: 'GET',
    headers,
  });
};

export const sendDataConfigNewTableToServer = (data) => {
  const token = localStorage.getItem('token');
  const headers = {
    'Content-Type': 'application/json',
  };
  if (!!token) {
    headers.Authorization = `Token ${token}`;
  }
  return fetch(`api/tables-config/create-config/`, {
    method: 'POST',
    headers,
    body: JSON.stringify(data),
  });
};

//получение списка подписок
export const getUserSubscriptions = () => {
  const token = localStorage.getItem('token');
  const headers = {
    'Content-Type': 'application/json',
    "Cache-Control": "no-cache",
  };
  if (!!token) {
    headers.Authorization = `Token ${token}`;
  }
  return fetch(`api/users/profile/subscriptions/`, {
    method: 'GET',
    headers,
  });
};
