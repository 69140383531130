import styles from "./error-403.module.css"
import notFoundImg from "../../../images/403-img-svg.svg"
import {useLocation, useNavigate} from "react-router-dom"
import React, { useCallback } from "react"
import {MetaTagsForPagesWithGetParams} from "../../../utils/metaTagsForPagesWithGetParams";
import {Helmet} from "react-helmet-async";

function NotFound() {
  const location = useLocation();
  const navigate = useNavigate()
  const toMainMenu = useCallback(() => {
    navigate("/")
  },[])
  const goBackPage = useCallback(() => {
    // window.location.reload()
    // window.history.back(2)
    // window.history.go(-2);
    navigate(-2)
  },[])
  return (
    <>
      <Helmet>
        <meta name="robots" content="noindex, nofollow" />
        <link rel="canonical" href={window.location.href.replace(location.search, '')}/>
      </Helmet>
      <div className={styles.section}>
        <div className={styles.contentWrapper}>
          <img src={notFoundImg} className={styles.notFoundImg}/>
          <div className={styles.title}>
          У вас нет доступа к этой странице
          </div>

          <div className={styles.buttonSection}>
            <button className={styles.returnButton} onClick={(goBackPage)} >
              Вернуться назад
            </button>
            <button className={styles.mainButton} onClick={toMainMenu}>
              На главную
            </button>
          </div>
        </div>
      </div>
    </>
  )
}

export default NotFound
