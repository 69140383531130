import { createSelector } from "@reduxjs/toolkit"
import {} from "../services/reducers/cards-filtred-slice"
import {} from "../services/reducers/search-vision-points-slice" // searchSectionVision
import {} from "../services/reducers/cards-filtred-for-checkbox-points-slice" //  checkBoxPoints
import {} from "../services/reducers/cards-filtred-for-gallery-slice" // filtredCardsForGallery
import {} from "../services/reducers/cards-filtred-slice" // cardsFiltred API



export const selectOrderingVision = (store) => store.searchSectionVision.orderingVision
export const categoryPrefilterSetPoints= (store) => store.checkBoxPoints.page_category
export const selectCategoryVision = (store) => store.searchSectionVision.selectCategoryPoint

export const countryPrefilterSetPoints = (store) => store.checkBoxPoints.country_tag
export const selectCountryVision = (store) => store.searchSectionVision.selectCountryPoint

export const sectorPrefilterSetPoints = (store) => store.checkBoxPoints.sector
export const selectSectorVision = (store) => store.searchSectionVision.selectSectorPoint

export const companyPrefilterSetPoints = (store) => store.checkBoxPoints.company_tag
export const companyCategoryVision = (store) => store.searchSectionVision.selectCompanyPoint

export const checkBoxChoiceVision = (store) => store.searchSectionVision.checkBoxChoice

export const selectAnalysisVision = (store) => store.searchSectionVision.selectAnalysisPoint
export const selectSubscriptionVision = (store) => store.searchSectionVision.selectSubscriptionPoint
export const selectBondsVision = (store) => store.searchSectionVision.selectBondsPoint
export const selectPortfoliosVision = (store) => store.searchSectionVision.selectChangeInPortfolios


export const countryToApi= ((store) => store.cardsFiltred.countryPoints)
export const sectorToApi= ((store) => store.cardsFiltred.sectorPoints)
export const categoryToApi = ((store) => store.cardsFiltred.categoryPoints)


export const selectVisionPointsByFilter = createSelector(
[countryPrefilterSetPoints],
(countryPrefilterSetPoints) => {

}
)
