import styles from "./chackBoxChoiseSection.module.css"
import cancel from "../../images/cancel-svg.svg"
import { setClearCards } from "../../services/reducers/cards-filtred-for-gallery-slice"
import { useDispatch, useSelector } from "react-redux"
import { setClearAllVisionSection, setClearSelectCompanyPoint, setCheckBoxChoice, setSelectCategoryPoint, setSelectCountryPoint, setSelectSectorPoint, setSelectCompanyPoint } from "../../services/reducers/search-vision-points-slice"
import { setClearAllFiltersCard, setClearCompanyPoints, setSectorPoints, setCategoryPoints, setCountryPoints, setCompanyPoints } from "../../services/reducers/cards-filtred-slice"
import { useEffect } from "react"
import { useMediaQuery } from "react-responsive"
import { useActiveFilters } from "../../services/context/ActiveFiltersContext"

const ChackBoxChoiseSection = ({
  checkBoxChoice,
  selectCountryPoint,
  selectCategoryPoint,
  selectSectorPoint,
  selectCompanyPoint,
}) => {
  const isMobile = useMediaQuery({ query: "(max-width: 760px)" })
  const filters = useActiveFilters()

  const dispatch = useDispatch()

  const onChipsSelect = (el) => {
    const isSector = selectSectorPoint.filter((point) => {
      return point.id === el.id
    }).length

    const isCountry = selectCountryPoint.filter((point) => {
      return point.id === el.id
    }).length

    const isCompany = selectCompanyPoint.filter((point) => {

      return point.id === el.id
    }).length

    const isCategory = selectCategoryPoint.filter((point) => {
      return point.id === el.id
    }).length

    // dispatch(setCheckBoxChoice(el))

    if (isSector === 1) {
      funSetSelectSectorPoint(el)
    } else if (isCountry === 1) {
      funSetSelectCountryPoint(el)
    } else if (isCompany === 1) {

      funSetSelectCompanyPoint(el)
    } else if (isCategory === 1) {
      funSetSelectCategoryPoint(el)
    }
    // funSetUpData()
    filters.setPageCount(1)

  }

  const funSetSelectCategoryPoint = (type) => {
    let index = null
    const finder = selectCategoryPoint.filter((el, i) => {
      // если finder нашел повторение его длинна увеличится
      if (el.id === type.id) {
        index = i
      }
      return el.id === type.id
    })

    if (finder.length !== 0) {
      const numberToDelete = selectCategoryPoint[index]
      const filteredNumbers = selectCategoryPoint.filter(
        (number) => number !== numberToDelete
      )

      const reFreshCheckBox = checkBoxChoice.filter((el) => {
        const currentId = el.id

        return (
          !selectCompanyPoint.find((s) => s.id === currentId) &&
          el.id !== type.id
        )
      })
      dispatch(setSelectCategoryPoint(filteredNumbers)) // для нижнего чек бокса 
      dispatch(setCategoryPoints(filteredNumbers)) // для галлереи
      dispatch(setCheckBoxChoice(reFreshCheckBox))
      dispatch(setClearSelectCompanyPoint())
      dispatch(setClearCompanyPoints())
    } else {
      const val = [...selectCategoryPoint, type]
      dispatch(setSelectCategoryPoint(val)) // для нижнего чек бокса 
      dispatch(setCategoryPoints(val)) // для галлереи
    }
  }
  const funSetSelectSectorPoint = (type) => {
    let index = null
    const finder = selectSectorPoint.filter((el, i) => {
      // если finder нашел повторение его длинна увеличится
      if (el.id === type.id) {
        index = i
      }
      return el.id === type.id
    })

    if (finder.length !== 0) {
      const numberToDelete = selectSectorPoint[index]
      const filteredNumbers = selectSectorPoint.filter(
        (number) => number !== numberToDelete
      )
      const reFreshCheckBox = checkBoxChoice.filter((el) => {
        const currentId = el.id
        return (
          !selectCompanyPoint.find((s) => s.id === currentId) &&
          el.id !== type.id
        )
      })
      dispatch(setSelectSectorPoint(filteredNumbers)) // для нижнего чек бокса 
      dispatch(setSectorPoints(filteredNumbers)) // для галлереи
      dispatch(setCheckBoxChoice(reFreshCheckBox))
      dispatch(setClearSelectCompanyPoint())
      dispatch(setClearCompanyPoints())
    } else {
      const val = [...selectSectorPoint, type]
      dispatch(setSelectSectorPoint(val)) // для нижнего чек бокса 
      dispatch(setSectorPoints(val)) // для галлереи
    }
  }

  const funSetSelectCountryPoint = (type) => {
    let index = null
    const finder = selectCountryPoint.filter((el, i) => {
      // если finder нашел повторение его длинна увеличится
      if (el.id === type.id) {
        index = i
      }
      return el.id === type.id
    })
    if (finder.length !== 0) {
      const numberToDelete = selectCountryPoint[index]
      const filteredNumbers = selectCountryPoint.filter(
        (number) => number !== numberToDelete
      )

      const reFreshCheckBox = checkBoxChoice.filter((el) => {
        const currentId = el.id

        return (
          !selectCompanyPoint.find((s) => s.id === currentId) &&
          el.id !== type.id
        )
      })
      dispatch(setSelectCountryPoint(filteredNumbers)) // для нижнего чек бокса 
      dispatch(setCountryPoints(filteredNumbers)) // для галлереи
      dispatch(setCheckBoxChoice(reFreshCheckBox))
      dispatch(setClearSelectCompanyPoint())
      dispatch(setClearCompanyPoints())
    } else {
      const val = [...selectCountryPoint, type]
      dispatch(setSelectCountryPoint(val)) // для нижнего чек бокса 
      dispatch(setCountryPoints(val)) // для галлереи
    }
  }

  const funSetSelectCompanyPoint = (type) => {
    let index = null
    const finder = selectCompanyPoint.filter((el, i) => {
      // если finder нашел повторение его длинна увеличится
      if (el.id === type.id) {
        index = i
      }
      return el.id === type.id
    })
    
    if (finder.length !== 0) {
      const numberToDelete = selectCompanyPoint[index]
      const filteredNumbers = selectCompanyPoint.filter(
        (number) => number !== numberToDelete
      )
      
      const reFreshCheckBox = checkBoxChoice.filter((el) => {
        const currentId = el.id // 14, 1238, 1297

        return (
          el.id !== type.id
        )
      })

      dispatch(setSelectCompanyPoint(filteredNumbers)) // для нижнего чек бокса 
      dispatch(setCompanyPoints(filteredNumbers)) // для галлереи
      dispatch(setCheckBoxChoice(reFreshCheckBox))

      
    } else {
      const val = [...selectCompanyPoint, type]
      dispatch(setSelectCompanyPoint(val)) // для нижнего чек бокса 
      dispatch(setCompanyPoints(val)) // для галлереи
    }
  }


  const funClearSetCheckBoxChoice = () => {
    dispatch(setClearAllVisionSection())
    dispatch(setClearAllFiltersCard())
    filters.setPageCount(1)
  }
  return (
    <>
      <div className={styles.container}>
        {checkBoxChoice.length !== 0 && (
          <div
            onClick={() => {
              funClearSetCheckBoxChoice()

            }}
            className={styles.clearFiltersButton}
          >
            Сбросить все
          </div>
        )}

        {checkBoxChoice.map((el) => (
          <div className={styles.allFiltersButton} key={el.name}>
            {el.name}
            <img
              onClick={() => {onChipsSelect(el)}}
              src={cancel}
              className={styles.cancelImg}
            />
          </div>
        ))}
      </div>
    </>
  )
}

export default ChackBoxChoiseSection
