import { NavLink, useNavigate, useParams } from 'react-router-dom';
import { useEffect, useMemo, useState } from 'react';
import styles from './about-us.module.css';
import { Helmet } from 'react-helmet';
import { useDispatch, useSelector } from 'react-redux';
import BGElipse from '../../images/about-us-bg.svg';
import BGArrow from '../../images/about-us-middle-arrow.svg';
import { useMediaQuery } from 'react-responsive';
import OurTeam from '../our-team/our-team';
import AboutCardMailSection from '../pages/about-card-mail/about-card-mail';

const AboutUs = () => {
  const isMobile = useMediaQuery({ query: '(max-width: 760px)' });

  return (
    <>
      <section className={styles.section}>
        <Helmet>
          <title>О нас</title>
          <meta name="description" content={'О проекте invest-era'} />
        </Helmet>
        <div className={styles.backgroundWrapper}>
          <img src={BGElipse} className={styles.background} />
        </div>
        <div className={styles.containerWrapper}>
          <OurTeam />

          <div className={styles.mainTitle}>История InvestEra</div>
          {!isMobile && (
            <div className={styles.container}>
              <img src={BGArrow} className={styles.backgroundArrow} />
              <div className={styles.leftBoxWrapper}>
                <div className={styles.leftBox}>
                  <div className={styles.boxTitle}>Ноябрь 2019</div>
                  <div className={styles.boxSubTitle}>Cоздание проекта</div>
                </div>
                <div className={styles.leftBox}>
                  <div className={styles.boxTitle}>Май 2021</div>
                  <div className={styles.boxSubTitle}>
                    Канал достигает <b>60 000</b> подписчиков
                  </div>
                </div>
                <div className={styles.leftBox}>
                  <div className={styles.boxTitle}>Декабрь 2022</div>
                  <div className={styles.boxSubTitle}>
                    По итогам года, <b>все</b> портфели <b>обгоняют</b> индекс
                  </div>
                </div>
                <div className={styles.leftBox}>
                  <div className={styles.boxTitle}>Декабрь 2023</div>
                  <div className={styles.boxSubTitle}>
                    По итогам года, <b>все</b> портфели <b>обгоняют</b> индекс
                  </div>
                </div>
              </div>
              <div className={styles.rightBoxWrapper}>
                <div className={styles.rightBox}>
                  <div className={styles.boxTitle}>Май 2020</div>
                  <div className={styles.boxSubTitle}>
                    Запуск <b>телеграм канала</b> Invest Era, который быстро стал одним из
                    крупнейших в тематике
                  </div>
                </div>
                <div className={styles.rightBox}>
                  <div className={styles.boxTitle}>Февраль 2022</div>
                  <div className={styles.boxSubTitle}>
                    Запуск продуктов <b>Unity</b> и <b>Ideas</b>
                  </div>
                </div>
                <div className={styles.rightBox}>
                  <div className={styles.boxTitle}>Июнь 2023</div>
                  <div className={styles.boxSubTitle}>
                    Запущен уникальный инструмент с прогнозами по прибылям и дивидендам российских
                    компаний
                  </div>
                </div>
                <div className={styles.rightBox}>
                  <div className={styles.boxTitle}>Март 2024</div>
                  <div className={styles.boxSubTitle}>Запуск сайта</div>
                </div>
              </div>
            </div>
          )}
          {isMobile && (
            <div className={styles.container}>
              <img src={BGArrow} className={styles.backgroundArrow} />
              <div className={styles.leftBox}>
                <div className={styles.boxTitle}>Ноябрь 2019</div>
                <div className={styles.boxSubTitle}>Cоздание проекта</div>
              </div>
              <div className={styles.leftBox}>
                <div className={styles.boxTitle}>Май 2020</div>
                <div className={styles.boxSubTitle}>
                  Запуск <b>телеграм канала</b> Invest Era, который быстро стал одним из крупнейших
                  в тематике
                </div>
              </div>
              <div className={styles.leftBox}>
                <div className={styles.boxTitle}>Май 2021</div>
                <div className={styles.boxSubTitle}>
                  Канал достигает <b>60 000</b> подписчиков
                </div>
              </div>
              <div className={styles.leftBox}>
                <div className={styles.boxTitle}>Февраль 2022</div>
                <div className={styles.boxSubTitle}>
                  Запуск продуктов <b>Unity</b> и <b>Ideas</b>
                </div>
              </div>
              <div className={styles.leftBox}>
                <div className={styles.boxTitle}>Декабрь 2022</div>
                <div className={styles.boxSubTitle}>
                  По итогам года, <b>все</b> портфели <b>обгоняют</b> индекс
                </div>
              </div>
              <div className={styles.leftBox}>
                <div className={styles.boxTitle}>Июнь 2023</div>
                <div className={styles.boxSubTitle}>
                  Запущен уникальный инструмент с прогнозами по прибылям и дивидендам российских
                  компаний
                </div>
              </div>
              <div className={styles.leftBox}>
                <div className={styles.boxTitle}>Декабрь 2023</div>
                <div className={styles.boxSubTitle}>
                  По итогам года, <b>все</b> портфели <b>обгоняют</b> индекс
                </div>
              </div>
              <div className={styles.leftBox}>
                <div className={styles.boxTitle}>Март 2024</div>
                <div className={styles.boxSubTitle}>Запуск сайта</div>
              </div>
            </div>
          )}

          <AboutCardMailSection
            section={styles.emailBox}
            classTitle={styles.emailBoxTitle}
            classSubTitle={styles.emailBoxSubTitle}
            classSpan={styles.emailBoxSpan}
          />
        </div>
      </section>
    </>
  );
};

export default AboutUs;
