import React from "react";
import styles from './custom-legend.module.css'
import {indicatorValue} from "../../utilsForFinanceTab";

export const CustomLegend = ({payload}) => {
    return (
        <div className={styles.legend}>
            {payload.map((entry, index) => {
                const isCompare = entry.value.includes('Compare')
                    return !isCompare && <div key={`item-${index}`} className={styles.legendItem}>
                        <span className={styles.legendSquare} style={{backgroundColor: entry.color}}></span>
                        {indicatorValue(entry.value)}
                    </div>
                }
            )}
        </div>
    );
};