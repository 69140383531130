import styles from "./reauthentication-alert.module.css";
import {useDispatch} from "react-redux";
import {isClickEnter, isUserDelete} from "../../../services/reducers/modal-slice";
import { useLocation, useNavigate } from "react-router-dom";
import { useCallback, useEffect, useMemo, useRef } from "react";


function ReauthenticationAlert() {
    const location = useLocation()
    const dispatch = useDispatch()
    const mounted = useRef()

    const onClick = () => {
        dispatch(isUserDelete(false))
        dispatch(isClickEnter(false))
    }

  
    return (
        <section className={styles.section}>
            <div style={{marginBottom:"0px"}} className={styles.h3}>Авторизуйтесь заново</div>
        {/*    <span className={styles.text}>Ваша сессия прервалась, так как произошла авторизация с другого устройства</span> */}
            <div>Ваша сессия истекла или в ваш аккаунт вошли с другого устройства</div>
            <button className={styles.button} onClick={onClick}>
                <span className={styles.buttonTxt}>Хорошо</span>
            </button>
        </section>
    )
}

export default ReauthenticationAlert