import {useEffect, useState} from "react"
import {useDispatch, useSelector} from "react-redux"
import {login} from "../../../services/actions/user"
import {setIsLoading} from "../../../services/reducers/preloader-slice"
import Preloader from "../../app-authorization/preloader-authorization/preloader-auth"
import {setError} from "../../../services/reducers/error-slice"
import styles from "./login-page.module.css"

function LoginPage() {
    const dispatch = useDispatch()
    const [email, setEmail] = useState("")
    const error = useSelector((store) => store.error.error)
    const isLoading = useSelector((store) => store.preloader.isLoading)

    const onChangeEmail = (evt) => {
        setEmail(evt.target.value)
    }

    const handleKeyPress = (event) => {
        if (event.key === 'Enter') onClick()
    };

    const onClick = () => {
        const deviceId = window.amplitude.getDeviceId();
        const formattedEmail = email.toLowerCase()
        dispatch(setIsLoading(true))
        dispatch(login(formattedEmail.trim(), deviceId))
    }

    useEffect(() => {
        dispatch(setError(""))

        return () => {
            localStorage.removeItem("token")
            localStorage.removeItem("subscription")
        }
    }, [email])

    return (
        <section className={styles.section}>

            {isLoading && <Preloader/>}

            <div style={{marginBottom: "40px"}} className={styles.h3}>Авторизуйтесь через почту</div>

            <div className={styles.containerWrapper}>

                <div className={styles.inputBox}>
                    <input
                        onChange={onChangeEmail}
                        onKeyPress={handleKeyPress}
                        value={email}
                        className={error ? styles.errorInputEmail : styles.input}
                        placeholder={"Почта"}
                        name={"email"}
                        required
                        inputMode="email"
                    />

                    <div className={styles.errorBox}>
                        {error && <span className={styles.errorText}>{error}</span>}
                        <span
                            className={
                                error
                                    ? styles.errorTextNavigateActive
                                    : styles.errorTextNavigateDisabled
                            }
                        >
                      Попробуйте ввести другую или обратитесь к {" "}
                            <a
                                href={"https://t.me/ie_contact"}
                                target={"_blank"}
                                className={styles.errorLink}
                            >
                        менеджеру
                      </a>
                    </span>
                    </div>

                    <span className={styles.text}>
                    {error ? "" : "Отправим на эту почту одноразовый код авторизации"}
                  </span>

                </div>

                <button
                    className={styles.button}
                    onClick={onClick}
                >
                    {!isLoading &&
                        <span className={styles.buttonTxt}>
              {error ? `Попробовать еще` : `Далее`}
            </span>
                    }
                </button>
            </div>

            <div className={styles.politicWrapper}>
                <div className={styles.politicTxt}>
                    Нажимая «Далее», я соглашаюсь с{" "}
                    <a href={"https://invest-era.ru/info/politika-konfidentsialnosti"}>Политикой конфиденциальности</a>
                </div>
            </div>
        </section>
    )
}

export default LoginPage
