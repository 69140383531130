import styles from "./first-description-product.module.css"
import { useMediaQuery } from "react-responsive"
import targetImg from "../../../../../../images/premium-target-icon.svg"
import cloudImg from "../../../../../../images/premium-cloud-icon.svg"

const FirstDescription = () => {
  const isMobile = useMediaQuery({ query: "(max-width: 760px)" })

  return (
    <div className={styles.section}>
      <div className={styles.containerWrapper}>
        <div className={styles.container}>
          <img src={targetImg} className={styles.img} />
          <div className={styles.title}>Выстроим портфель под ваши цели</div>
          <div className={styles.subTitle}>
            В индивидуальном порядке составим портфель, на основе ваших целей и
            уровня риска. Поможем управлять портфелем
          </div>
        </div>

        <div className={styles.container}>
          <img src={cloudImg} className={styles.img} />
          <div className={styles.title}>Личный менеджер</div>
          <div className={styles.subTitle}>
            За вами будет закреплен портфельный управляющий, который всегда
            сможет ответить на вопросы и помочь с ребалансировкой
          </div>
        </div>
      </div>
    </div>
  )
}

export default FirstDescription
