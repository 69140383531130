import { useState } from "react"

export const useInput = (initialVal) => {
  const [val, setVal] = useState(initialVal)

  const reset = () => {
    setVal(initialVal)
  }

  const removeNonDigits = (str) => {
    return str.replace(/\D/g, '');
  }
  const bind = {
    value: val,
    onChange: (e) => {
      
      setVal(removeNonDigits(e.target.value))}
  }


  return {val, reset, bind}

}