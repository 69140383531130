import styles from "./main-section-info.module.css"
import { useMediaQuery } from "react-responsive"
import LeftGradient from "../../images/main-section-info-ellipse-left.svg"
import RightGradient from "../../images/main-section-info-ellipse-right.svg"
import rusFlag from "../../images/main-section-flag-rus-img.svg"
import usaFlag from "../../images/main-section-flag-usa-img.svg"

const scrollToAnchor = (anchorName) => {
  const anchorElement = document.getElementById(anchorName)
  if (anchorElement) {
    anchorElement.scrollIntoView({ behavior: "smooth", block: "start" })
  }
}

const TopMainTitle = () => {
  const isMobile = useMediaQuery({ query: "(max-width: 760px)" })

  return (
    <section className={styles.section}>
      <img src={LeftGradient} className={styles.backgroundLeft} />
      <img src={RightGradient} className={styles.backgroundRight} />
      <div className={styles.mainContentWrapper}>
        <div className={styles.descriptionWrapper}>
          <div className={styles.mainTitle}>
          Invest Era — почувствуй разницу
          </div>
          <div className={styles.subTitleWrapper}>
            <div className={styles.subTitle}>
            Поможем увеличить доходность {isMobile && <br />} вашего {!isMobile && <br />}портфеля с помощью {isMobile && <br />} аналитики и инвест идей
              {/*
              Аналитика и новости по рынкам {isMobile && <br />} России, США и
              Китая
              */}
            </div>
            <div className={styles.subTitle}>
            Оперативно анализируем события{isMobile && <br />} на рынках{!isMobile && <br />} Россия, США и Китая{isMobile && <br />} и даем рекомендации
              {/*
              Инвестиционные идеи {isMobile && <br />}и автоследование
              */}
            </div>
            <div className={styles.subTitle}>
              Обучение – от азов до продвинутого {isMobile && <br />}уровня
            </div>
          </div>

          {!isMobile && (
            <div className={styles.buttonWrapper}>
              <div
                className={styles.button}
                href="#product"
                onClick={() => {
                  scrollToAnchor("product")
                }}
              >
                Наши продукты
              </div>
            </div>
          )}
        </div>
        <div className={styles.imagesWrapper}>
          <div className={styles.topBoxWrapper}>
            <img src={rusFlag} className={styles.flagImg} />
            <div className={styles.topBox}>
              <div className={styles.boxTitle}>РФ Долгосрочный</div>
              <div className={styles.percentWrapper}>
                <div>Обогнал iMOEX на</div>
                <div className={styles.txtBox}>
                  <div className={styles.percent}>52,3%</div>
                </div>
              </div>
            </div>
          </div>

          <div className={styles.bottomBoxWrapper}>
            <div className={styles.bottomBox}>
              <div className={styles.boxTitle}>США Дивидендный</div>
              <div className={styles.percentWrapper}>
                <div>Обогнал S&P на</div>
                <div className={styles.txtBox}>
                  <div className={styles.percent}>19,1%</div>
                </div>
              </div>
            </div>
            <img src={usaFlag} className={styles.flagImg} />
          </div>
        </div>
        {isMobile && (
          <div
            className={styles.buttonWrapper}
            onClick={() => {
              scrollToAnchor("product")
            }}
          >
            <div className={styles.button}>Наши продукты</div>
          </div>
        )}
      </div>
    </section>
  )
}

export default TopMainTitle
