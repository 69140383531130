import {useDispatch, useSelector} from "react-redux";
import {useEffect, useState} from "react";
import {getLessonsCards} from "../../utils/api";
import styles from "./education-cards-section.module.css";
import {NavLink} from "react-router-dom";
import {setIsLoading} from "../../services/reducers/preloader-slice";
import Preloader from "../preloader/preloader";
import {setThemesSlug} from "../../services/reducers/cards-slice";
import Coins from "../../images/column-of-coins.png"
import { useEducationContext } from "../../services/context/EducationContext";
import TwoLevelMenu from "../two-level-menu/two-level-menu";
function EducationCardsSection() {
    const dispatch = useDispatch()
    const value = useEducationContext()
    const [visible, setVisible] = useState(6)
    const [open, setOpen] = useState(false);


    // const themesCards = useSelector((store) => store.cards.themesCards)
    // console.log(value.themesData);

    const isLoading = useSelector((store) => store.preloader.isLoading)


    useEffect(() => {
        dispatch(setIsLoading(true))
        // dispatch(getLessonsCards())
        value.getThemesDataApi()
    }, [])

    const showMoreCards = () => {
        setVisible(prevValue => prevValue + 12)
    }

    return (
        <section className={styles.section}>
            {isLoading ? <div className={styles.preloader}> <Preloader/> </div> :
                <div className={styles.gallery}>
                    {value.themesData.slice(0, visible).map(element => (
                        <div className={styles.card} key={element.name}>
                            <div className={styles.wrapper}>
                                <ul className={styles.ul}>
                                    {/*
                            <li className={styles.li}>
                                {item.sub_sector.map(item => (
                                    item.name.length > 0 && <span className={styles.sectorText} key={item.name}>{item.name}</span>
                                ))}
                            </li>*/}

                                    {/*
                                    {element.subscription
                                        ? <span className={styles.subscription}>{`С подпиской ${element.subscription}`}</span>
                                        : null}
                                    */}
                                    {element.subscription
                                        ? <span className={styles.subscription}>{`С подпиской Unity`}</span>
                                        : null}
                                    <li className={styles.li}>
                                        <span className={styles.lvl}>{element.level}</span>
                                    </li>
                                </ul>
                                <NavLink to={`/education/${element.slug}`} className={styles.link}>
                                    <div className={styles.container}>
                                        <div className={styles.box}>
                                            {/*<div className={styles.overlay}></div>*/}
                                            {/*<img alt='' src={item.banner} className={styles.image}/>*/}
                                            <div className={styles.gradient}>
                                            {element.banner !== null && <img src={element.banner ? `${element.banner}` : Coins} className={styles.banner}/>}
                                            </div>
                                            <div className={styles.textBox}>

                                                <span className={styles.data}>{`Количество уроков: ${element.lessons_count}`}</span>


                                                <h3 className={styles.h3}>{element.name}</h3>
                                                {/*<div dangerouslySetInnerHTML={{__html: element.text}}></div>*/}
                                                {/*<span className={styles.text}>{item.description}</span>*/}

                                            </div>
                                        </div>
                                    </div>
                                </NavLink>
                            </div>
                        </div>
                    ))}
                </div>
            }
            { visible < value.themesData.length  &&
                <button className={styles.button} onClick={showMoreCards}>
                    <span className={styles.textBtn}>Смотреть еще</span>
                    <div className={styles.arrow}></div>
                </button> }
        </section>
    )
}

export default EducationCardsSection