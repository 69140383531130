import React from 'react';
import { Link } from 'react-router-dom';
import GreenMoney from '../../images/greenMoneyBg.png';

import styles from './promocode-tenDays.module.css';

const TenDaysPromocode = () => {
  const copyToClipboard = (text) => {
    navigator.clipboard
      .writeText(text)
      .then(() => {
        window.ym(96742836, 'reachGoal', 'main-start');
        console.log('Copied to clipboard!');
      })
      .catch((err) => {
        console.error('Failed to copy: ', err);
      });
  };

  return (
    <div className={styles.promocode}>
      <div className={styles.container}>
        <img className={styles.bg} src={GreenMoney} alt="bg" />
        <div className={styles.left}>
          <div className={styles.title}>10 дней за 10 рублей</div>
        </div>
        <div className={styles.right}>
          <div className={styles.text}>
            Для новых пользователей по промокоду START первые 10 дней за 10 рублей на любую
            подписку*
          </div>
          <Link to="/profile" onClick={() => copyToClipboard('START')} className={styles.btn}>
            Скопировать промокод
          </Link>
          <div className={styles.link}>и перейти к оформлению</div>

          <div className={styles.info}>
            *По истечении 10 дней продление подписки происходит автоматически, в соответствии, с
            ценной, указанной на сайте invest-era.ru
          </div>
        </div>
      </div>
    </div>
  );
};

export default TenDaysPromocode;
