import React, {useEffect, useRef, useState} from "react";
import {useNavigate} from "react-router-dom";
import glossary from '../../../images/glossary.png'
import arrowLeft from '../../../images/arrow-left-navigate-button.svg'
import arrowUp from '../../../images/Arrow-full-up.svg'
import {GlossaryItem} from "./glossary-item/glossary-item";
import {getGlossary} from "../../../utils/api";
import styles from './glossary.module.css'
import Preloader from "../../preloader/preloader";

export const Glossary = () => {
    const navigate = useNavigate()
    const [isVisible, setIsVisible] = useState(false);
    const [data, setData] = useState();
    const headBlockWrapperRef = useRef(null);
    const anchorRef = useRef(null);
    const [isSticky, setIsSticky] = useState(false);
    const [loading, setLoading] = useState(false);

    const backArrowClick = () => {
        navigate('/')
    }

    const toggleVisibility = () => {
        if (window.pageYOffset > 300) {
            setIsVisible(true);
        } else {
            setIsVisible(false);
        }
    };

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    };

    useEffect(() => {
        window.addEventListener('scroll', toggleVisibility);
        return () => {
            window.removeEventListener('scroll', toggleVisibility);
        };
    }, []);

    function sortObjectByKeys(obj) {
        const sortedKeys = Object.keys(obj).sort((a, b) => a.localeCompare(b, 'ru'));
        const sortedObj = {};
        sortedKeys.forEach(key => {
            sortedObj[key] = obj[key];
        });
        return sortedObj;
    }

    useEffect(() => {
        setLoading(true)
        getGlossary().then(async (res) => {
            if (res.ok) {
                setLoading(false)
                const data = await res.json()
                const result = sortObjectByKeys(data)
                setData(result)
            } else {
                setLoading(false)
                console.log(res)
            }
        })
    }, []);

    const refs = useRef({});

    const handleScroll = (key) => {
        if (refs.current[key]) {
            refs.current[key].scrollIntoView({ behavior: 'smooth' });
        }
    };

    useEffect(() => {
        const observer = new IntersectionObserver(
            ([entry]) => {
                setIsSticky(!entry.isIntersecting);
            },
            { threshold: 0 }
        );

        if (headBlockWrapperRef.current) {
            observer.observe(headBlockWrapperRef.current);
        }

        return () => {
            if (headBlockWrapperRef.current) {
                observer.unobserve(headBlockWrapperRef.current);
            }
        };
    }, []);

    return (
        <div className={styles.glossary}>
            <div className={styles.headBlockWrapper} ref={headBlockWrapperRef}>
                <div className={styles.headBlock}>
                    <div>
                        <button className={styles.backBtn} onClick={backArrowClick}>
                            <img src={arrowLeft} alt="arrow left" width={24}/>
                            Главная
                        </button>
                        <h2 className={styles.title}>Словарь терминов</h2>
                    </div>
                    <div className={styles.glossaryImgWrapper}>
                        <img src={glossary} alt="Глоссарий" width={310} className={styles.glossaryImg}/>
                    </div>
                </div>
            </div>

            {loading ? <div className={styles.preloaderContainer}>
                    <Preloader/>
                </div>
                :
                <div className={styles.contentWrapper}>
                <div className={styles.content}>

                    <div className={styles.itemsContainer}>
                        {data && Object.keys(data).map((key) => (
                            <div
                                key={key}
                                className={styles.itemsContainer}
                                ref={(el) => (refs.current[key] = el)}
                            >
                                {data[key].map((el, index) => (
                                    <GlossaryItem key={index} title={el.name} description={el.description}/>
                                ))}
                            </div>
                        ))}
                    </div>

                    <div className={`${styles.anchor} ${isSticky ? styles.sticky : ''}`} ref={anchorRef}>
                        {data && Object.keys(data).map((key) => (
                            <button
                                type={'button'}
                                key={key}
                                className={styles.anchorItem}
                                onClick={() => handleScroll(key)}
                            >
                                {key}
                            </button>))}
                    </div>

                    {isVisible && (
                        <button className={styles.scrollToTopBtn} onClick={scrollToTop}>
                            <img src={arrowUp} alt="arrow up"/>
                        </button>
                    )}
                </div>
            </div>}
        </div>
    );
};