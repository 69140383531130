import React from "react";

export function BarChartIcon() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="28"
            height="28"
            viewBox="0 0 28 28"
        >
            <path
                fill="currentColor"
                d="M19 6h-1v7h-3v1h3v8h1v-3h3v-1h-3V6zm-8 1h-1v13H7v1h3v2h1V10h3V9h-3V7z"
            ></path>
        </svg>
    );
}

