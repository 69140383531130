import React from 'react';
import {Link, useNavigate} from 'react-router-dom';
import ArrowLeft from '../../../../../images/arrow-left-card.png';
import Elipse from '../../../../../images/Ellipse.svg';
import BgLeft from '../../../../../images/screener-bg-left.png';
import BgRight from '../../../../../images/screener-bg-right.png';
import StarFile from '../../../../../images/StarFile.svg';
import {useSelector} from "react-redux";
import {PolarAngleAxis, PolarGrid, Radar, RadarChart, ResponsiveContainer, Tooltip} from "recharts";

import styles from './skriner-header.module.css';

// const starFile = '../../../../../images/Star.svg';
// const Star = () => import(starFile);

const SkrinerPageHeader = () => {
    const {information} = useSelector(state => state.screener);
    const navigate = useNavigate();

    const localDataInfoForCreateArray = [
        { subject: 'Оценка стоимости', A: 120, fullMark: 150 },
        { subject: 'Рост', A: 120, fullMark: 150 },
        { subject: 'Прошлые\nпоказатели', A: 120, fullMark: 150 },
        { subject: 'Финансовое\nсостояние', A: 120, fullMark: 150 },
        { subject: 'Дивиденты', A: 120, fullMark: 150 },
    ]

    const value = Object.values(information['snowflake'] || {});
    const newArray =  localDataInfoForCreateArray.map((item, index) => ({
        ...item,
        A: value[index],
    }));

    const CustomPolarAngleAxisTick = ({ x, y, payload }) => {
        const lines = payload.value.split('\n');

        const customXorY = () => {
            let newX = x;
            let newY = y;
            if (lines.includes('Дивиденты')) {
                newX -= 40;
            }
            if (lines.includes('Рост')) {
                newX += 15;
            }
            if (lines.includes('Финансовое')) {
                newY += 15;
            }
            if (lines.includes('Прошлые')) {
                newY += 15;
            }
            return { newX, newY };
        };

        const { newX, newY } = customXorY();
        return (
            <text
                // onMouseEnter={() => setActiveLabel(payload.value)}
                // onMouseLeave={() => setActiveLabel(null)}
                style={{ cursor: 'pointer' }}
                x={x}
                y={newY}
                textAnchor="middle"
                fontSize={12}
                fill="#666">
                {lines.map((line, index) => (
                    <tspan x={newX} dy={index > 0 ? 15 : 0} key={index}>
                        {line}
                    </tspan>
                ))}
            </text>
        );
    };

    const getIntroOfPage = (label) => {
        if (label === 'Оценка стоимости') {
            return 'Будущий рост фывфв';
        }
        if (label === 'Рост') {
            return 'Разрабатывает и поддерживает программное обеспечение, сервисы, устройства и решения по всему миру';
        }
        if (label === 'Прошлые\nпоказатели') {
            return "Page C is about women's bag";
        }
        if (label === 'Финансовое\nсостояние') {
            return 'Page D is about household goods';
        }
        if (label === 'Дивиденты') {
            return 'Page E is about food';
        }
        return '';
    };

    const CustomTooltip = ({ active, payload, label }) => {
        if (active && payload && payload.length) {
            return (
                <div className={styles.customTooltip}>
                    <p className={styles.customTooltipLabel}>{label}</p>
                    <p className={styles.customTooltipIntro}>{getIntroOfPage(label)}</p>
                </div>
            );
        }

        return null;
    };


    return (
        <div className={styles.header}>
            <img className={styles.bgLeft} src={BgLeft} alt="bgLeft"/>
            {/*<img className={styles.bgRight} src={BgLeft} alt="bgRight"/>*/}
            <div className={styles.container}>
                <div className={styles.navigation}>
                    <Link className={styles.navLink} to={'/'}>
                        <img src={ArrowLeft} alt="arrow"/>
                        Главная</Link>
                    <div className={styles.navLink} onClick={() => navigate(-1)}>
                        <img src={ArrowLeft} alt="arrow"/>
                        Компании</div>
                </div>
                <div className={styles.info}>
                    <div className={styles.left}>
                        <div className={styles.up}>
                            <div className={styles.imgCompany}>
                                <img src={information?.['main_info']?.logo} alt="photoCompany"/>
                            </div>
                            <div className={styles.descr}>
                                <h1 className={styles.titleCompany}>{information?.['main_info']?.name}
                                    <img src={StarFile} alt="star"/>
                                </h1>
                                <div className={styles.infoCompany}>
                                    <div className={styles.infoCompanySubtitle}>{information?.['main_info']?.ticker}</div>
                                    <img src={Elipse} alt="elipse"/>
                                    <div className={styles.infoCompanySubtitle}>{information?.['main_info']?.sector}</div>
                                </div>
                            </div>
                        </div>
                        <div className={styles.down}>
                            <div className={styles.downInfo}>
                                <div className={styles.downInfoTitle}>Последняя цена</div>
                                <div className={styles.downPrice}>{information?.['main_info']?.['last_price']} ₽</div>
                            </div>
                            <div className={styles.downInfo}>
                                <div className={styles.downInfoTitle}>Капитализация</div>
                                <div className={styles.downPrice}>{information?.['main_info']?.['market_cap']} трлн ₽</div>
                            </div>
                            <div className={styles.downInfo}>
                                <div className={styles.downInfoTitle}>Динамика за год</div>
                                <div
                                    style={{ color: Number(information?.['main_info']?.['year_price_change']) > 0 ? '#37d881' : 'red' }}
                                    className={`${styles.downPrice} ${styles.green}`}>{information?.['main_info']?.['year_price_change']}
                                    %
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={styles.right}>
                        <ResponsiveContainer width="100%" height="100%">
                            <RadarChart cx={'50%'} cy={'50%'} outerRadius={'80%'} data={newArray}>
                                <PolarGrid />
                                <PolarAngleAxis dataKey="subject" tick={<CustomPolarAngleAxisTick />} />
                                <Tooltip content={<CustomTooltip />} />
                                {/*<PolarRadiusAxis />*/}
                                <Radar name="Mike" dataKey="A" stroke="#8884d8" fill="#8884d8" fillOpacity={0.6} />
                            </RadarChart>
                        </ResponsiveContainer>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SkrinerPageHeader;
