import styles from "./sectionCompanyCheckBoxPoints.module.css"
import { setClearCards } from "../../services/reducers/cards-filtred-for-gallery-slice"
import { useDispatch } from "react-redux"
import checkBoxEmpty from "../../images/check-box-empty.svg"
import checkBoxFull from "../../images/check-box-full.svg"
import searchImg from "../../images/search-svg.svg"
import { useMediaQuery } from "react-responsive"
import closeXSign from "../../images/close-x-sign-svg.svg"
import { useCallback, useEffect, useMemo, useState } from "react"
import {
  setSelectCompanyPoint,
  setCheckBoxChoice,
} from "../../services/reducers/search-vision-points-slice"
import { setCompanyPoints } from "../../services/reducers/cards-filtred-slice"
import { useActiveFilters } from "../../services/context/ActiveFiltersContext"

const FilterSection = ({
  companyPoints,
  selectCompanyPoint,
  checkBoxChoice,
  setActivCompanyMenu,
}) => {
  const isMobile = useMediaQuery({ query: "(max-width: 760px)" })
  const dispatch = useDispatch()
  const filters = useActiveFilters()
  const funSetCheckBoxChoice = (type) => {
    let index = null
    const finder = checkBoxChoice.filter((el, i) => {
      if (el.id === type.id) {
        index = i
      }
      return el.id === type.id
    })
    if (finder.length !== 0) {
      const numberToDelete = checkBoxChoice[index]
      const filteredNumbers = checkBoxChoice.filter(
        (number) => number !== numberToDelete
      )
      dispatch(setCheckBoxChoice(filteredNumbers))
    } else {
      const val = [...checkBoxChoice, type]
      dispatch(setCheckBoxChoice(val))
    }
  }

  const funSetSelectCompanyPoint = (type) => {
    let index = null
    const finder = selectCompanyPoint.filter((el, i) => {
      // если finder нашел повторение его длинна увеличится
      if (el.id === type.id) {
        index = i
      }
      return el.id === type.id
    })
    if (finder.length !== 0) {
      const numberToDelete = selectCompanyPoint[index]
      const filteredNumbers = selectCompanyPoint.filter(
        (number) => number !== numberToDelete
      )
      // setSelectCompanyPoint(filteredNumbers)
      dispatch(setSelectCompanyPoint(filteredNumbers)) // для нижнего чек бокса
      dispatch(setCompanyPoints(filteredNumbers)) // для галлереи
    } else {
      const val = [...selectCompanyPoint, type]
      dispatch(setSelectCompanyPoint(val)) // для нижнего чек бокса
      dispatch(setCompanyPoints(val)) // для галлереи
    }
  }

  // const funSetUpData = () => setUpData(prev => !prev)
  // const funPageCount = () => setPageCount(1)
  const funClearCards = () => {
    // dispatch(setClearCards())
  }

  const [query, setQuery] = useState("")
  const [visibleArray, setVisibleArray] = useState([])
  const [space, setSpace] = useState(false)

  const inputChange = useCallback(
    (e) => {
      setQuery(e.target.value)
    },
    [query]
    )


    const newArr = useMemo(() => {
      return companyPoints.filter((el) => {
        const newString = el.name.toLowerCase()
        const newTickerString = el.ticker.toLowerCase()
        const newString2 = query.toLowerCase()
        return (newString.includes(newString2) || newTickerString.includes(newString2))
      })}, [query, companyPoints])







      useEffect(() => {
        setVisibleArray(newArr.slice(0, 20))
      }, [query])

  const handleScroll = useCallback((event) => {

    const { scrollTop, scrollHeight, clientHeight } = event.currentTarget
    // console.log(` scrollHeight: ${scrollHeight}, - scrollTop: ${scrollTop}, <= clientHeight: ${clientHeight}`);
    if (scrollHeight - scrollTop <= clientHeight) {
      handleLoadMore()
    }
  }, [visibleArray])

  const handleLoadMore = useCallback(() => {
    const nextElements = companyPoints.slice(
      visibleArray.length,
      visibleArray.length + 10
    )
    const prevArrays = visibleArray.concat(nextElements)
    setVisibleArray(prevArrays)
    if (companyPoints.length === visibleArray.length) {setSpace(true)}

  }, [visibleArray])


  return (
    <div className={styles.containerWrapper}>
      {isMobile && <div className={styles.mobileFilterTxt}>Компания</div>}

      {companyPoints.length !== 0 ? (
        <div className={styles.container}>
          <div className={styles.searchInputWrapper}>
            <img src={searchImg} className={styles.searchImg} />
            <input
              type="text"
              placeholder="Поиск"
              className={styles.input}
              onChange={inputChange}
            />
          </div>
          <div className={styles.mapButton} onScroll={handleScroll}>
            {visibleArray.map((el, i) => (
              <div
                className={styles.button}
                key={el.id}
                onClick={() => {
                  funClearCards()
                  filters.setPageCount(1)
                  funSetSelectCompanyPoint(el)
                  funSetCheckBoxChoice(el)
                }}
              >
                <div className={styles.checkWrapper}>
                  {!!selectCompanyPoint.find((point) => point.id === el.id) ===
                    true && <img src={checkBoxFull} className={styles.img} />}
                  {!!selectCompanyPoint.find((point) => point.id === el.id) ===
                    false && <img src={checkBoxEmpty} className={styles.img} />}
                </div>
                <div className={styles.txt}>
                  {el.name}
                </div>
              </div>
            ))}
                {isMobile && space && <div className={styles.underSpaceToScroll} />}

          </div>
        </div>
      ) : (
        <div className={styles.container}>
          <div className={styles.warning}>
            <div className={styles.txt}>Измените фильтры</div>
          </div>
        </div>
      )}

      {isMobile && (
        <div className={styles.aceptButtonWrapper}>
          
            <div
              className={styles.acceptButtonActive}
              onClick={() => {
                setActivCompanyMenu(false)
              }}
            >
              Применить
            </div>
          
        </div>
      )}
      {isMobile && (
        <div className={styles.closeImg}>
          <img
            src={closeXSign}
            onClick={() => {
              setActivCompanyMenu(false)
            }}
          />
        </div>
      )}
    </div>
  )
}

export default FilterSection
