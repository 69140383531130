import styles from "./video-section.module.css"
import RightGradient from "../../../../../../images/Ellipse-products-green-right-promocode-section.svg"
import LeftGradient from "../../../../../../images/Ellipse-products-green-left-promocode-section.svg"
import Phone from "../../../../../../images/product-chat-image-ideas.svg"
import { useMediaQuery } from "react-responsive"
const VideoSection = () => {
  const isMobile = useMediaQuery({ query: "(max-width: 760px)" })

  return (
    <section className={styles.section}>
      <div className={styles.containerWrapper}>
        <div className={styles.container}>
          <div className={styles.subTitleWrapper}>
            <div className={styles.subTitle}>Легко начать</div>
            <div className={styles.secondSubTitle}>
              Управляющий лично поможет привести ваш портфель к нашему. Наша
              команда отвечает на вопросы в онлайн режиме
            </div>
          </div>

          <div className={styles.phoneSectionWrapper}>
            <div className={styles.phoneSection}>
              <div className={styles.phoneImg}>
                <img src={Phone} className={styles.phoneImg} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default VideoSection
