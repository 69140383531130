import stylesNews from '../main-section-news/main-section-news.module.css'
import styles from './main-section-analytics.module.css'
import {NavLink} from "react-router-dom";
import {Swiper, SwiperSlide} from "swiper/react";
import 'swiper/swiper-bundle.css';
import {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import graph from "../../images/main-section-graph.svg"
import { useMainPage } from '../../services/context/MainPageContext';
function MainSectionAnalytics() {
    const valueMainPage = useMainPage()

    useEffect(() => {
        valueMainPage.getCardsMainAnalyticsContext()
    }, [])
    const [slideIndex, setSlideIndex] = useState(0);
    const [swiper, setSwiper] = useState(null);

    // const object = useSelector((store) => store.cards.object)
    const filteredObject = useSelector((store) => store.cardsMainAnalytics.mainAnalyticsCards)

    const goToSlide = (sliIndex) => {
        if (swiper) {
            swiper.slideTo(sliIndex);
        }
    };

    // const filteredObject = object.filter(item => item.page_category[0].name === 'Аналитика').slice(0, 4);





    return (
        <section className={styles.container}>
            <div className={styles.boxText}>
                <div className={stylesNews.h3}>Аналитика</div>
                <NavLink to={'/analytics-and-news'}>
                    <button className={stylesNews.newsBtn} >
                        <p className={stylesNews.textBtn}>Смотреть больше</p>
                    </button>
                </NavLink>
            </div>

            <div className={styles.grid}>
                {filteredObject.map((element, index) => (
                    <NavLink key={index} to={`/analytics-and-news/${element.slug}`}
                    // <NavLink to={element.url}
                             className={`${styles.link} ${styles[`card${index + 1}`]}`}>
                        <div className={styles.topBox}>
                            {element.subscription ? <span className={styles.subscription}>{`С подпиской ${element.subscription}`}</span> : null}
                            <span className={styles.data}>{element.pub_date_formated}</span>
                        </div>

                        <h3 className={styles.h3long}>{element.name}</h3>
                        {/*<div dangerouslySetInnerHTML={{__html: element.text}}></div>*/}
                        <span className={styles.text}>{element.description}</span>
                        
                        
                        
                        {index === 3 && <div className={styles.imageWrapper}><img src={graph} className={styles.image}/></div>}
                    </NavLink>
                ))}
            </div>

            <div className={styles.boxPhone}>
                <Swiper onSwiper={setSwiper} slidesPerView={1}
                        spaceBetween={10}
                        pagination={{clickable: true}}
                        onSlideChange={(swiper) => setSlideIndex(swiper.activeIndex)}
                        style={{borderRadius: '15px', boxShadow: '0 3px 8px 0 rgba(0, 0, 0, 0.1)'}}>
                    {filteredObject.map((element, index) => (
                        <SwiperSlide key={index}>
                            <NavLink to={`/analytics-and-news/${element.slug}`}
                                     className={`${styles.link} ${styles[`card${index + 1}`]}`}
                                     >
                                <div className={styles.topBox}>
                                    {element.subscription ? <span className={styles.subscription}>{`С подпиской ${element.subscription}`}</span> : null}
                                    <span className={styles.data}>{element.pub_date_formated}</span>
                                </div>
                                <h3 className={styles.h3long}>{element.name}</h3>
                                {/*<div dangerouslySetInnerHTML={{__html: element.text}}></div>*/}
                                <span className={styles.text}>{element.description}</span>
                            </NavLink>
                        </SwiperSlide>
                    ))}
                </Swiper>
                <div className={styles.dots}>
                    {filteredObject.map((sli, sliIndex) => (
                        <div key={sliIndex} className={sliIndex === slideIndex ? styles.dot : styles.dotDisable}
                             onClick={() => goToSlide(sliIndex)}>
                        </div>
                    ))}
                </div>
            </div>

        </section>
    )
}

export default MainSectionAnalytics