import styles from "../pop-up-styles.module.css"
import closeXSign from "../../../../images/close-x-sign-svg.svg"
import { useCallback } from "react"
import { NavLink } from "react-router-dom"

const ExteriorNotice = ({ setShowExteriorNotice }) => {
  const handleClick = useCallback((event) => {
    if (event.target === event.currentTarget) {
      setShowExteriorNotice(false)
    }
  }, [])
  return (
    <div style={{ "z-index": "150" }} onClick={handleClick} className={styles.containerWrapper}>
      <div className={styles.container}>
        <img
          src={closeXSign}
          className={styles.closeImg}
          onClick={handleClick}
        />
        <div className={styles.topBody}>
          <div className={styles.topTxtBody}>
            Сейчас на сайте можно оплатить только российской картой. Для того,
            чтобы оплатить иначе, пожалуйста, напиши в поддержку
          </div>
        </div>

        <div className={styles.bottomBody}>
          <NavLink to={"https://t.me/ie_contact"} target={'_blank'} className={styles.buttonWrapper}>
            <div className={styles.buttonTxt}>Написать</div>
          </NavLink>
        </div>
      </div>
    </div>
  )
}
export default ExteriorNotice
