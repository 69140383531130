import styles from "./error-500.module.css"
import notFoundImg from "../../../images/500-img-svg.svg"
import {useLocation, useNavigate} from "react-router-dom"
import React, { useCallback } from "react"
import { useActiveButtonCountryTagChoice } from "../../../services/context/CountryFilterContext"
import {Helmet} from "react-helmet-async";

function NotFound() {
  const val = useActiveButtonCountryTagChoice()
  const location = useLocation();
  const navigate = useNavigate()
  const toMainMenu = useCallback(() => {
    navigate("/")
  },[])
  const goBackPage = useCallback(() => {
    // window.history.back(3)
      navigate(-2)

  },[])
  return (
    <>
      <Helmet>
        <meta name="robots" content="noindex, nofollow" />
        <link rel="canonical" href={window.location.href.replace(location.search, '')}/>
      </Helmet>
      <div className={styles.section}>
        <div className={styles.contentWrapper}>
          <img src={notFoundImg} className={styles.notFoundImg}/>
          <div className={styles.title}>
          У нас что-то сломалось, уже чиним
          </div>

          <div className={styles.buttonSection}>
            <button className={styles.returnButton} onClick={goBackPage} >
              Вернуться назад
            </button>
            <button className={styles.mainButton} onClick={toMainMenu}>
              На главную
            </button>
          </div>
        </div>
      </div>
    </>
  )
}

export default NotFound
