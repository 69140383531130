import { NavLink, useNavigate, useParams } from "react-router-dom"
import { useEffect, useMemo, useState } from "react"
import styles from "./page-url-info.module.css"
import { Helmet } from "react-helmet"

import { useDispatch, useSelector } from "react-redux"
import { usePageInfoContext } from "../../../../services/context/PageInfoContext"

const PageInfoUrl = () => {
  const valueContext = usePageInfoContext()
  const { slug } = useParams()
  const navigate = useNavigate()
  console.log(slug)

  useMemo(() => {
    valueContext.pageFromSlugUrl(slug)
  }, [slug])

  const card = valueContext.urlPageData
  return (
    <>
      <section className={styles.section}>

            <Helmet>
              <title>{card.name}</title>
              <meta name="description" content={"информационная карточка"} />
            </Helmet>
      {card &&
              <div className={styles.aboutCard}>
                <div className={styles.navigationBox}>
                  <NavLink to={"/"} className={styles.link}>
                    <div className={styles.arrow}></div>
                    Главная
                  </NavLink>

                  <NavLink to={"/info"} className={styles.link}>
                    <div className={styles.arrow}></div>
                    Cтраницы
                  </NavLink>
                </div>
                <div className={styles.headerTitleWrapper}>
                  <span className={styles.pubDate}>{card.pub_date_formated}</span>
                  <h3 className={styles.title}>{card.name}</h3>
                  <span className={styles.desc}>{card.description}</span>
                  <div
                    className={styles.container}
                    /*style={{maxWidth: '1200px', margin: "auto", wordWrap: "break-word"}}*/
                    dangerouslySetInnerHTML={{ __html: card.text }}
                  >
                  </div>
                </div>
              </div>
          }
      </section>
    </>
  )
}

export default PageInfoUrl
