import Close from '../../../images/close-black-icon.svg';
import Comments from '../../../images/comments.png';

import styles from './popUpAnalytics.module.css';
import { useRef } from 'react';

export const PopUpTimeOutAnalytics = ({ setShowTimeoutPopupFirst }) => {
  const modalRef = useRef(null);
  const handleClickOutside = (e) => {
    if (modalRef.current && !modalRef.current.contains(e.target)) {
      setShowTimeoutPopupFirst(false);
      window.ym(96742836, 'reachGoal', 'popup-our-analysis-close');
    }
  };

  const closePopupForImgCLick = () => {
    setShowTimeoutPopupFirst(false);
    window.ym(96742836, 'reachGoal', 'popup-our-analysis-close');
  };
  const clickForBtn = () => {
    setShowTimeoutPopupFirst(false);
    window.ym(96742836, 'reachGoal', 'popup-our-analysis-lead');
  };
  return (
    <div onClick={handleClickOutside} className={styles.wrapper}>
      <div ref={modalRef} className={styles.popup}>
        <div onClick={closePopupForImgCLick} className={styles.close}>
          <img src={Close} alt="close" />
        </div>
        <div className={styles.container}>
          <h2 className={styles.title}>Наша аналитика позволяет обгонять индекс вдвое</h2>
          <h3 className={styles.subTitle}>
            Подписывайтесь на наш телеграм канал, чтобы не упустить важную информацию
          </h3>
          <div className={styles.comments}>
            <img className={styles.commentsImg} src={Comments} alt="coomentsImg" />
          </div>
          <a onClick={clickForBtn} href={'https://t.me/+Avs2bsQ5mQk4N2My'} className={styles.btn}>
            Подписаться
          </a>
        </div>
      </div>
    </div>
  );
};
