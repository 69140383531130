import styles from './can-might-be-interesting.module.css';
import {NavLink, useLocation} from 'react-router-dom';
import {useSelector} from 'react-redux';
import {useCallback} from 'react';

function CanMightBeInteresting({posts, numberOfHiddenPost}) {
    const subscriptionUser = JSON.parse(localStorage.getItem('subscription'));
    console.log(numberOfHiddenPost)

    const location = useLocation();

    // const breakMap = () => {
    //   let newPosts = [...posts]
    //   const heightCard = 140
    //   const distanceToFooter = window.innerHeight - newPosts.length * heightCard
    //   if (distanceToFooter > 120 && distanceToFooter < 240) {
    //     newPosts.length = 5
    //   } else if (distanceToFooter < 120 && distanceToFooter > 0) {
    //     newPosts.length = 4
    //   } else if (distanceToFooter < 120 ) {
    //     newPosts.length = 3
    //   }
    //   return newPosts
    // }
    const anRecom = useCallback(() => {
        // console.log(window.ym(96742836, "reachGoal", "an-recom"));

        if (!!window.ym) {
            window.ym(96742836, 'reachGoal', 'an-recom');
        }
    }, []);

    const checkSubscribe = (cardSubscribe) => {
        if (subscriptionUser) {
            return subscriptionUser.some((el) => {
                return el.subscription === cardSubscribe;
            });
        }
        return false;
    };

    const posts1 = [
        {
            subscription: "Unity Россия",
            name: 'Сколько дивидендов Займер заплатит в 2024 году? Интервью с менеджментом',
            slug: 'slug',
            url: 'url',
            pub_date: "2024-07-25T22:32:26+03:00",
            pub_date_formated: "25 июля 2024 в 22:32"
        }
    ]
    return (
        <div className={styles.canMightBeInteresting}>
            <h3 className={styles.cmbiTitle}>Может быть интересно</h3>

            {posts.filter((_, i) => i < (numberOfHiddenPost ? numberOfHiddenPost : 100)).map((element) => (
                <NavLink
                    onClick={anRecom}
                    to={`/analytics-and-news/${element.slug}`}
                    className={styles.linkBox}
                    key={element.name}>
                    <div className={styles.canMightBeInterestingCard}>
                        <div style={element.subscription ? {} : {display: 'none'}} className={styles.cardBox}>
                            {element.subscription === null ? null : (
                                <span className={styles.subscription}>{`С подпиской ${element.subscription}`}</span>
                            )}
                        </div>
                        <span className={styles.titleBox}>{element.name}</span>
                        <span className={styles.pubDateBox}>
              {element.url ? element.pub_date_formated : element.pub_date_formated}
            </span>
                    </div>
                </NavLink>
            ))}
        </div>
    );
}

export default CanMightBeInteresting;
