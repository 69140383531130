import React, { useState } from 'react';
import { Calendar, ConfigProvider } from 'antd';
import ruRU from 'antd/es/locale/ru_RU';
import dayjs from 'dayjs';
import 'dayjs/locale/ru';
import updateLocale from 'dayjs/plugin/updateLocale';
import weekday from 'dayjs/plugin/weekday';

import NextArrow from '../../../../../images/next-arrow-right-dark.svg';
import PrevArrow from '../../../../../images/prev-arrow-left-dark.svg';
import CalendarEvent from '../../../../../images/calendarEvent.svg';

import styles from './skriner-calendar.module.css';
import './calendar.css';

const SkrinerTabCalendar = () => {
  dayjs.extend(updateLocale);
  dayjs.extend(weekday);
  dayjs.locale('ru');
  dayjs.updateLocale('ru', {
    weekStart: 1, // Устанавливаем понедельник в качестве первого дня недели
  });

  const [currentDate, setCurrentDate] = useState(dayjs());
  const [calendarEventShow, setCalendarEventShow] = useState(false);

  const getListData = (value) => {
    const day = value.date();
    const month = value.month(); // January is 0, February is 1, and so on.
    const year = value.year();

    let listData;
    switch (`${day}-${month + 1}-${year}`) {
      case '8-7-2024':
        listData = [
          { type: 'Название', content: 'Описание' },
          { type: 'Название', content: 'Описание' },
        ];
        break;
      case '18-7-2024':
        listData = [
          { type: 'Название', content: 'Описание' },
          { type: 'Название', content: 'Описание' },
          { type: 'Название', content: 'Описание' },
        ];
        break;
      case '8-1-2025':
        listData = [
          { type: 'Название', content: 'Описание' },
          { type: 'Название', content: 'Описание' },
          { type: 'Название', content: 'Описание' },
          { type: 'Название', content: 'Описание' },
          { type: 'Название', content: 'Описание' },
          { type: 'Название', content: 'Описание' },
        ];
        break;
      default:
    }
    return listData || [];
  };

  const getMonthData = (value) => {
    if (value.month() === 8) {
      return 1394;
    }
  };

  const monthCellRender = (value) => {
    const num = getMonthData(value);
    return num ? (
      <div className="notes-month">
        <section>{num}</section>
        <span>Backlog number</span>
      </div>
    ) : null;
  };

  const dateCellRender = (value) => {
    const listData = getListData(value);
    return (
      <div className="events">
        {listData.map((item, i) => (
          <div key={i} className="eventWrapperCalendar">
            <div className="titleCalendarCell"> {item.type} </div>
            <div className="descCalendarCell"> {item.content} </div>
          </div>
        ))}
      </div>
    );
  };

  const cellRender = (current, info) => {
    if (info.type === 'date') return dateCellRender(current);
    if (info.type === 'month') return monthCellRender(current);
    return info.originNode;
  };

  const handlePrevMonth = () => {
    setCurrentDate(currentDate.subtract(1, 'month'));
  };

  const handleNextMonth = () => {
    setCurrentDate(currentDate.add(1, 'month'));
  };

  return (
    <>
      <div className={styles.wrapper}>
        <div className={styles.calendarHeader}>
          <button className={styles.nextPrevMonth} onClick={handlePrevMonth}>
            <img src={PrevArrow} alt="prevArrow" />
          </button>
          <span className={styles.calendarDate}>{`${currentDate
            .format('MMMM, YYYY')
            .split(', ')
            .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
            .join(', ')}`}</span>
          <button className={styles.nextPrevMonth} onClick={handleNextMonth}>
            <img src={NextArrow} alt="nextArrow" />
          </button>
        </div>
        <div className={styles.calendarEvent}>
          <img src={CalendarEvent} alt="event" />
        </div>
      </div>
      <ConfigProvider locale={ruRU}>
        <Calendar
          className="calendar-wrapper"
          value={currentDate}
          onPanelChange={setCurrentDate}
          cellRender={cellRender}
        />
      </ConfigProvider>
    </>
  );
};

export default SkrinerTabCalendar;
