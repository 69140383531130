import { NavLink, useLocation, useNavigate, useParams } from "react-router-dom"
import { useCallback, useEffect, useMemo, useRef, useState } from "react"
import styles from "./about-card-mail.module.css"
import { useDispatch, useSelector } from "react-redux"
import { setIsLoading } from "../../../services/reducers/preloader-slice"
import Preloader from "../../preloader/preloader"
import { useInput } from "../../../services/hooks/useInput"
import { useActiveFilters } from "../../../services/context/ActiveFiltersContext"
import SuccessImg from "../../../images/subscription-succes-img.svg"
import LeftArrow from "../../../images/prev-arrow-left.svg"
import classNames from "classnames"
function AboutCardMailSection({
  section,
  classTitle,
  classSubTitle,
  classSpan,
}) {
  const { bind, val } = useInput("")
  const value = useActiveFilters()
  const navigate = useNavigate()
  const location = useLocation()
  const [buttonActive, setButtonActive] = useState(false)
  useEffect(() => {
    if (val.length !== 0) {
      value.setMailSubscription(val)
    }
  }, [buttonActive])

  useEffect(() => {
    value.setCheckMAil(false)
  }, [val])
  const goBackHandle = useCallback(() => {
    if (location.pathname === "/about-us") {
      return
    }
    navigate(-1)
  }, [])
  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      setButtonActive((prev) => !prev)
    }
  }
  return (
    <>
      <section className={classNames(styles.sectionWrapper, section)}>
        {value.mailСompleted ? (
          <div className={styles.completedContainer}>
            <img src={SuccessImg} className={styles.successImg} />
            <div className={classNames(styles.title, classTitle)}>
              Спасибо за подписку
            </div>
            <div className={classNames(styles.subTitle, classSubTitle)}>
              Отправим подборку на {val}
            </div>
            <div className={styles.goBackWrapper} onClick={goBackHandle}>
              <img src={LeftArrow} className={styles.leftArrow} />
              <div className={styles.buttonBackTitle}>Вернуться назад</div>
            </div>
          </div>
        ) : (
          <div className={styles.containerWrapper}>
            <div className={styles.container}>
              <div className={classNames(styles.title, classTitle)}>
                Лучшие статьи пришлем на почту
              </div>
              <div className={classNames(styles.subTitle, classSubTitle)}>
                Подпишитесь на еженедельную рассылку и получайте подборку
                актуальной аналитики и новостей
              </div>
              <div className={styles.inputSectionWrapper}>
                <input
                  onKeyPress={handleKeyPress}
                  {...bind}
                  className={value.checkMAil ? styles.inputError : styles.input}
                  placeholder="Адрес электронной почты"
                />
                <button
                  className={styles.button}
                  onClick={() => {
                    setButtonActive((prev) => !prev)
                  }}
                >
                  Подписаться
                </button>
              </div>
              {value.checkMAil && (
                <div className={styles.warning}>
                  Неправильно введен адрес электронной почты
                </div>
              )}
              <div className={classNames(styles.subButton, classSpan)}>
                Нажимая "Подписаться", я соглашаюсь с условиями{" "}
                <a href="https://invest-era.ru/info/politika-konfidentsialnosti">
                  Политики Конфиденциальности
                </a>
              </div>
            </div>
          </div>
        )}
      </section>
    </>
  )
}

export default AboutCardMailSection
