import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  user: null,
  accessToken: '',
  refreshToken: '',
  isAuthChecked: false,
  email: '',
  subscription: '',
};

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setAuthChecked: (state, action) => {
      state.isAuthChecked = action.payload;
    },
    setUser: (state, action) => {
      state.user = action.payload;
    },
    setEmail: (state, action) => {
      state.email = action.payload;
    },
    setAccessToken: (state, action) => {
      state.accessToken = action.payload;
    },
    setRefreshToken: (state, action) => {
      state.refreshToken = action.payload;
    },
    setSubscription: (state, action) => {
      state.subscription = action.payload;
    },
  },
});

export const {
  setAuthChecked,
  setUser,
  setEmail,
  setAccessToken,
  setRefreshToken,
  setSubscription,
} = userSlice.actions;
export default userSlice.reducer;
