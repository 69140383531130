import {createSlice} from "@reduxjs/toolkit";

const initialState = {
    valueFilter: '',
}

const valueDataFilterSlice = createSlice({
    name: 'valueFilter',
    initialState,
    reducers: {
        setValueFilter: (state, action) => {
            state.valueFilter = action.payload
        }
    }
})

export const {
    setValueFilter
} = valueDataFilterSlice.actions

export default valueDataFilterSlice.reducer;