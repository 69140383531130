function BreadcrumbList() {
  return (
    <script type="application/ld+json">
{`{
    "@context": "https://schema.org",
    "@type": "BreadcrumbList",
    "itemListElement": [{"@type":"ListItem","position":1,"name":"Обучение","item":"https://${window.location.hostname}/education/"}]
}`}
</script>
  )
}

export default BreadcrumbList
