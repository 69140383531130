import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  countryInfo: [],
  tablesConfig: [],
  tableForUser: [],
  userSubs: [],
  status: false,
};

export const countryInfoSlice = createSlice({
  name: 'countryInfo',
  initialState,
  reducers: {
    setCountryInfo(state, action) {
      state.countryInfo = action.payload;
    },
    setStatusLoad(state, action) {
      state.status = action.payload;
    },
    setConfigNewTable(state, action) {
      state.tablesConfig = action.payload;
    },
    setTableForUser(state, action) {
      state.tableForUser = action.payload;
    },
    setUserSubs(state, action) {
      state.userSubs = action.payload;
    },
  },
});

export const { setCountryInfo, setConfigNewTable, setTableForUser, setStatusLoad, setUserSubs } =
  countryInfoSlice.actions;
export default countryInfoSlice.reducer;
