import styles from "./top-main-title.module.css"
import WhiteLeftGradient from "../../../../../../../images/Ellipse-products-white-left-unity-world.svg"
import WhiteGradient from "../../../../../../../images/Ellipse-products-white.svg"
import UnityImg from "../../../../../../../images/main-page-woman-rest-img.svg"
import TicketImg from "../../../../../../../images/ticket-promocode-img.svg"
import CopyTxtIconGreen from "../../../../../../../images/copy-button-icon-green.svg"
import { useMediaQuery } from "react-responsive"
import { NavLink } from "react-router-dom"

const TopMainTitle = () => {
  const isMobile = useMediaQuery({ query: "(max-width: 760px)" })

  return (
    <section className={styles.section}>
      <div className={styles.backgroundWrapper}>
        <img src={WhiteLeftGradient} className={styles.backgroundRed} />
        <img src={WhiteGradient} className={styles.backgroundWhite} />
      </div>

      <div className={styles.containerWrapper}>
        <div className={styles.container}>
          <div className={styles.headTitle}>Ideas All</div>
          <div className={styles.subTitle}>
            Следуйте нашим торговым <br /> сигналам и обгоняйте индекс
          </div>
          {isMobile && <img src={UnityImg} className={styles.img} />}
          {/*
          <div className={styles.promocodSectionWrapper}>
            <div className={styles.textPromocodeWrapper}>
              <img src={TicketImg} className={styles.TicketImg} />
              <div className={styles.textPromocode}>
                Первый месяц бесплатно с промокодом:
              </div>
            </div>
            <div className={styles.promocodeBoxWrapper}>
              <div className={styles.promocodeTxt}>FFF AAA FFF</div>
              <img src={CopyTxtIconGreen} className={styles.CopyTxtIconGreen} />
            </div>
            </div>
          */}
          <NavLink to={"/profile"} className={styles.buttonSubscribe}>
            Оформить подписку
          </NavLink>
        </div>
        {!isMobile && <img src={UnityImg} className={styles.img} />}
      </div>
    </section>
  )
}

export default TopMainTitle
