import styles from "./no-access.module.css"
import Gradient from "../../images/background-green-ellipse.svg"
import { useDispatch, useSelector } from "react-redux"
import { NavLink, useNavigate, useParams } from "react-router-dom"
import { useEffect, useMemo } from "react"

function NoAccess() {
  const navigate = useNavigate()

  window.addEventListener(
    "popstate",
    function (e) {
        navigate(-2)
    },
    false
  )


  return (
    <>
      <section className={styles.section}>
        <div className={styles.backgroundWrapper}>
          <img src={Gradient} className={styles.background} />
        </div>
        <div className={styles.containerWrapper}>
          <div className={styles.container}>
            <div className={styles.title}>
              У вас нет доступа к этому контенту 😢
            </div>
            <button
              className={styles.button}
              onClick={() => {
                navigate("/")
              }}
            >
              Вернуться на главную
            </button>
          </div>
        </div>
      </section>
    </>
  )
}

export default NoAccess
