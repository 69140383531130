import React, {useState, useEffect} from 'react';
import {Link, useParams} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {blogActions, getNavigateBlogPage} from "../../../../../services/reducers/blog-slice";

import styles from './blog-navigate.module.css'


const BlogNavigate = ({setMarks, marks}) => {
    const {sub} = useParams();
    const {blogDataForNavigate} = useSelector(state => state.blog);
    const dispatch = useDispatch();

    const getActiveTab = (active) => {
        setMarks({
            name: active?.name,
            slug: active?.slug
        });
        dispatch(blogActions.clearState());
    }

    const pathAll = {
        name: 'Все',
        slug: 'all',
    }


    useEffect(() => {
        dispatch(getNavigateBlogPage());
    }, [dispatch]);

    //при перезагрузке страницы устанавливаем активную ссылку
    useEffect(() => {
        if (blogDataForNavigate.length === 0) return;
        if (sub === 'all') {
            setMarks(pathAll);
        } else if (blogDataForNavigate?.['special_marks']) {
            const navigatePath = blogDataForNavigate['special_marks']?.reduce((acc, item) => {
                acc[item?.slug] = item?.name;
                return acc;
            }, {})[sub];
            if (navigatePath) {
                setMarks({
                    name: navigatePath,
                    slug: sub,
                });
            }
        }
    }, [blogDataForNavigate]);

    return (
        <div className={styles.blogLis}>
            {blogDataForNavigate?.['special_marks']?.length === 0 ? null : (
                <>
                    <Link
                        to={`/polezno-investoru/all`}
                        onClick={() => getActiveTab(pathAll)}
                        className={`${styles.blogLink} ${marks?.slug === 'all' ? styles.activeItem : ''}`}
                    >
                        Все
                    </Link>
                    {blogDataForNavigate?.['special_marks']?.map((item) => {
                        const activeClass = item?.slug === marks?.slug ? styles.activeItem : '';
                        return (
                            <Link
                                key={item?.id}
                                to={`/polezno-investoru/${item?.slug}`}
                                className={`${styles.blogLink} ${activeClass}`}
                                onClick={() => getActiveTab(item)}
                            >
                                {item?.name}
                            </Link>
                        )
                    })}
                </>
            )
            }
        </div>
    );
};

export default BlogNavigate;
