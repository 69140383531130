import styles from "../pop-up-styles.module.css"
import closeXSign from "../../../../images/close-x-sign-svg.svg"
import { useProfile } from "../../../../services/context/ProfileContext"
import { useCallback } from "react"

const SubscriptionCancel = ({ setShowWarningCancelModal, showWarningCancelModal}) => {
    // console.log(showWarningCancelModal)
  const contextValue = useProfile()
  const handleTargetClick = useCallback((event) => {
    if (event.target === event.currentTarget) {
          setShowWarningCancelModal(prev => !prev)
    }
  })
  const handleClick = useCallback(() => {
          setShowWarningCancelModal(prev => !prev)
  })
  return (
    <div className={styles.containerWrapper} onClick={handleTargetClick}>
      <div className={styles.container}>
        <img src={closeXSign} className={styles.closeImg} onClick={handleTargetClick}/>
        <div className={styles.topBody}>
          <div className={styles.topTxtBody}>
            Вы уверены, что хотите отменить подписку на {showWarningCancelModal.name}? 
          </div>
          <div className={styles.buttonTxtBody}>
            После отмены подписки сервис будет доступен до {showWarningCancelModal.next_payment_date}
          </div>
        </div>
        
      <div className={styles.bottomBody}>
      <div className={styles.buttonWrapper} onClick={handleClick}>
        <div className={styles.buttonTxt}>
          Продолжить пользоваться
        </div>
      </div>
      <div className={styles.buttonWrapper} onClick={() => {
        contextValue.subscriptionCanceled(showWarningCancelModal)
      }}>
        <div className={styles.buttonTxt}>
          Отменить подписку
        </div>
      </div>
    </div>
      </div>
    </div>
  )
}
export default SubscriptionCancel
