import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import axios from 'axios';

export const getInformationScreenerPage = createAsyncThunk(
    'information/getInformationScreenerPage',
    async (company) => {
        const res = await axios(`/api/companies/get-card/?ticker_name=${company.toUpperCase()}`)
        return res.data.info
    },
)

const initialState = {
    information: [],
    loading: false,
    error: false,
}

const screenerSlice = createSlice({
    name: 'screener',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(getInformationScreenerPage.pending, (state) => {
            state.loading = true;
        })
        builder.addCase(getInformationScreenerPage.fulfilled, (state, action) => {
            state.information = action.payload;
            state.loading = false;
        })
        builder.addCase(getInformationScreenerPage.rejected, (state) => {
            state.error = true;
            state.loading = false;
        })
    },
})

export const {actions: screenerActions} = screenerSlice;
export const {reducer: screenerReducer} = screenerSlice;
