export const processData = (data) => {
    return data.map((item) => {
        const processedItem = { ...item };

        // Compute revenue
        if (item.revenue > item.revenueCompare) {
            processedItem.revenue -= item.revenueCompare;
        } else {
            processedItem.revenueCompare -= item.revenue;
        }

        // Compute clearProfit
        if (item.clearProfit > item.clearProfitCompare) {
            processedItem.clearProfit -= item.clearProfitCompare;
        } else {
            processedItem.clearProfitCompare -= item.clearProfit;
        }

        // Compute cost
        if (item.cost > item.costCompare) {
            processedItem.cost -= item.costCompare;
        } else {
            processedItem.costCompare -= item.cost;
        }

        // Compute gross
        if (item.gross > item.grossCompare) {
            processedItem.gross -= item.grossCompare;
        } else {
            processedItem.grossCompare -= item.gross;
        }

        // Compute operatingProfit
        if (item.operatingProfit > item.operatingProfitCompare) {
            processedItem.operatingProfit -= item.operatingProfitCompare;
        } else {
            processedItem.operatingProfitCompare -= item.operatingProfit;
        }

        // Compute operatingExp
        if (item.operatingExp > item.operatingExpCompare) {
            processedItem.operatingExp -= item.operatingExpCompare;
        } else {
            processedItem.operatingExpCompare -= item.operatingExp;
        }

        // Compute researchDev
        if (item.researchDev > item.researchDevCompare) {
            processedItem.researchDev -= item.researchDevCompare;
        } else {
            processedItem.researchDevCompare -= item.researchDev;
        }

        // Compute adminExp
        if (item.adminExp > item.adminExpCompare) {
            processedItem.adminExp -= item.adminExpCompare;
        } else {
            processedItem.adminExpCompare -= item.adminExp;
        }

        return processedItem;
    });
};

export const indicatorValue = (value) => {
    if(value === 'revenue' || value === 'revenueCompare') return 'Итоговая выручка'
    if(value === 'cost' || value === 'costCompare') return 'Себестоимость'
    if(value === 'clearProfit' || value === 'clearProfitCompare') return 'Чистая прибыль'
    if(value === 'gross' || value === 'grossCompare') return 'Валовая выручка'
    if(value === 'operatingProfit' || value === 'operatingProfitCompare') return 'Операционная прибыль'
    if(value === 'operatingExp' || value === 'operatingExpCompare') return 'Операционные расходы'
    if(value === 'researchDev' || value === 'researchDevCompare') return 'Расходы на исследования и развитие'
    if(value === 'adminExp' || value === 'adminExpCompare') return 'Административные расходы'
}