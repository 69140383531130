import {Link} from "react-router-dom";
import styles from "./company-item.module.css";

export const CompanyItem = ({img, title, ticker, sector}) => {

    return (
        <Link to={`/screener/${ticker}`} className={styles.container} >
            <img src={`https://invest-era.ru${img}`} alt="logo"
                 width={48} height={48}/>
            <div className={styles.info}>
                <h4 className={styles.title}>{title}</h4>
                <span className={styles.descContainer}>
                    <span>{ticker}</span> • <span>{sector}</span>
                </span>
            </div>
        </Link>
    );
};