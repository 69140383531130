import {createSlice} from "@reduxjs/toolkit";

const initialState = {
    page: 2,
}

const pagesSlice = createSlice({
    name: 'page',
    initialState,
    reducers: {
        setPage: (state, action) => {
            state.page = action.payload
        }
    }
})

export const {
    setPage
} = pagesSlice.actions

export default pagesSlice.reducer;