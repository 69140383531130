import React, {useState} from 'react';
import {ConfigProvider, Space, Table, Tag} from 'antd';
import checkmarkIcon from '../../../../../../images/Checkmark.svg'
import styles from './finance-table.module.css'
import {indicatorValue} from "../utilsForFinanceTab";

export const FinanceTable = ({
                                 tableRowTitle,
                                 selectedRows,
                                 setSelectedRows,
                                 selectedCompany
                             }) => {

    const checkmarkImg = <img src={checkmarkIcon} alt="checkmark" width={24}/>

    const data = [
        {
            key: '1',
            name:
                <span
                    className={`${styles.rowTitle} ${selectedRows && !selectedRows.includes('1') && styles.rowTitlePadding}`}>
                    {selectedRows && selectedRows.includes('1') && checkmarkImg}
                    {indicatorValue(tableRowTitle[0].title)}
                </span>,
            2018: <div className={styles.col}>
                <span>2 394 376</span>
                {selectedCompany.key !== '0' && <span>1 283 265</span>}
            </div>,
            2019: <div className={styles.col}>
                <span>2 394 707</span>
                {selectedCompany.key !== '0' && <span>1 383 696</span>}
            </div>,
            2020: <div className={styles.col}>
                <span>1 959 015</span>
                {selectedCompany.key !== '0' && <span>2 060 126</span>}
            </div>,
            2021: <div className={styles.col}>
                <span>2 986 178</span>
                {selectedCompany.key !== '0' && <span>1 875 067</span>}
            </div>,
            2022: <div className={styles.col}>
                <span>3 412 129</span>
                {selectedCompany.key !== '0' && <span>4 523 230</span>}
            </div>,
            2023: <div className={styles.col}>
                <span>3 519 959</span>
                {selectedCompany.key !== '0' && <span>2 409 848</span>}
            </div>,
            TTM: <div className={styles.col}>
                <span>3 557 158,5</span>
                {selectedCompany.key !== '0' && <span>4 668 269,6</span>}
            </div>
        },
        {
            key: '2',
            name:
                <span
                    className={`${styles.rowTitle} ${selectedRows && !selectedRows.includes('2') && styles.rowTitlePadding}`}>
                    {selectedRows && selectedRows.includes('2') && checkmarkImg}
                    {indicatorValue(tableRowTitle[1].title)}
                </span>,
            2018: <div className={styles.col}>
                <span>2 394 376</span>
                {selectedCompany.key !== '0' && <span>1 283 265</span>}
            </div>,
            2019: <div className={styles.col}>
                <span>2 394 707</span>
                {selectedCompany.key !== '0' && <span>1 383 696</span>}
            </div>,
            2020: <div className={styles.col}>
                <span>1 959 015</span>
                {selectedCompany.key !== '0' && <span>2 060 126</span>}
            </div>,
            2021: <div className={styles.col}>
                <span>2 986 178</span>
                {selectedCompany.key !== '0' && <span>1 875 067</span>}
            </div>,
            2022: <div className={styles.col}>
                <span>3 412 129</span>
                {selectedCompany.key !== '0' && <span>4 523 230</span>}
            </div>,
            2023: <div className={styles.col}>
                <span>3 519 959</span>
                {selectedCompany.key !== '0' && <span>2 409 848</span>}
            </div>,
            TTM: <div className={styles.col}>
                <span>3 557 158,5</span>
                {selectedCompany.key !== '0' && <span>4 668 269,6</span>}
            </div>
        },
        {
            key: '3',
            name:
                <span
                    className={`${styles.rowTitle} ${selectedRows && !selectedRows.includes('3') && styles.rowTitlePadding}`}>
                    {selectedRows && selectedRows.includes('3') && checkmarkImg}
                    {indicatorValue(tableRowTitle[2].title)}
                </span>,
            2018: <div className={styles.col}>
                <span>2 394 376</span>
                {selectedCompany.key !== '0' && <span>1 283 265</span>}
            </div>,
            2019: <div className={styles.col}>
                <span>2 394 707</span>
                {selectedCompany.key !== '0' && <span>1 383 696</span>}
            </div>,
            2020: <div className={styles.col}>
                <span>1 959 015</span>
                {selectedCompany.key !== '0' && <span>2 060 126</span>}
            </div>,
            2021: <div className={styles.col}>
                <span>2 986 178</span>
                {selectedCompany.key !== '0' && <span>1 875 067</span>}
            </div>,
            2022: <div className={styles.col}>
                <span>3 412 129</span>
                {selectedCompany.key !== '0' && <span>4 523 230</span>}
            </div>,
            2023: <div className={styles.col}>
                <span>3 519 959</span>
                {selectedCompany.key !== '0' && <span>2 409 848</span>}
            </div>,
            TTM: <div className={styles.col}>
                <span>3 557 158,5</span>
                {selectedCompany.key !== '0' && <span>4 668 269,6</span>}
            </div>
        },
        {
            key: '4',
            name:
                <span
                    className={`${styles.rowTitle} ${selectedRows && !selectedRows.includes('4') && styles.rowTitlePadding}`}>
                    {selectedRows && selectedRows.includes('4') && checkmarkImg}
                    {indicatorValue(tableRowTitle[3].title)}
                </span>,
            2018: <div className={styles.col}>
                <span>2 394 376</span>
                {selectedCompany.key !== '0' && <span>1 283 265</span>}
            </div>,
            2019: <div className={styles.col}>
                <span>2 394 707</span>
                {selectedCompany.key !== '0' && <span>1 383 696</span>}
            </div>,
            2020: <div className={styles.col}>
                <span>1 959 015</span>
                {selectedCompany.key !== '0' && <span>2 060 126</span>}
            </div>,
            2021: <div className={styles.col}>
                <span>2 986 178</span>
                {selectedCompany.key !== '0' && <span>1 875 067</span>}
            </div>,
            2022: <div className={styles.col}>
                <span>3 519 959</span>
                {selectedCompany.key !== '0' && <span>4 523 230</span>}
            </div>,
            2023: <div className={styles.col}>
                <span>3 519 959</span>
                {selectedCompany.key !== '0' && <span>2 409 848</span>}
            </div>,
            TTM: <div className={styles.col}>
                <span>3 557 158,5</span>
                {selectedCompany.key !== '0' && <span>4 668 269,6</span>}
            </div>
        },
    ];


    const handleRowClick = (record) => {
        setSelectedRows((prevSelectedRows) => {
            if (prevSelectedRows.includes(record.key)) {
                return prevSelectedRows.filter(key => key !== record.key);
            } else {
                return [...prevSelectedRows, record.key];
            }
        });
    };

    const rowClassName = (record) => {
        let className = styles.tableRow;
        if (selectedRows && selectedRows.includes(record.key)) {
            className += ` ${styles.selectedRow}`;
        }
        return className;
    };

    const columns = [
        {
            title: '',
            width: 300,
            dataIndex: 'name',
            key: 'name',
            fixed: 'left',
        },
        {
            title: <div className={styles.titleCell}>2018</div>,
            width: 100,
            dataIndex: '2018',
            key: '2018',
            align: 'center'
        },
        {
            title: <div className={styles.titleCell}>2019</div>,
            width: 100,
            dataIndex: '2019',
            key: '2019',
            align: 'center'
        },
        {
            title: <div className={styles.titleCell}>2020</div>,
            width: 100,
            dataIndex: '2020',
            key: '2020',
            align: 'center'
        },
        {
            title: <div className={styles.titleCell}>2021</div>,
            width: 100,
            dataIndex: '2021',
            key: '2021',
            align: 'center'
        },
        {
            title: <div className={styles.titleCell}>2022</div>,
            width: 100,
            dataIndex: '2022',
            key: '2022',
            align: 'center'
        },
        {
            title: <div className={styles.titleCell}>2023</div>,
            width: 100,
            dataIndex: '2023',
            key: '2023',
            align: 'center'
        },
        {title: <div className={styles.titleCell}>TTM</div>, width: 100, dataIndex: 'TTM', key: 'TTM', align: 'center'},
    ];

    return (
        <div className={styles.tableContainer}>
            <ConfigProvider
                theme={{
                    components: {
                        Table: {
                            borderColor: '#DBDBDB',
                            fontFamily: 'Montserrat',
                        }
                    }
                }}
            >
                <Table dataSource={data} pagination={false} className={styles.table}
                       rowClassName={rowClassName}
                       onRow={(record) => ({
                           onClick: () => handleRowClick(record),
                       })}
                       scroll={{x: 940}}
                       columns={columns}
                >
                </Table>
            </ConfigProvider>
        </div>)
}