import {useSearchParams} from "react-router-dom";
import {formatWordValue} from "../../utilsForMarketMap";
import styles from './filter-section-market-map.module.css';

export const FilterSectionMarketMap = ({categoryPoints, setActiveTypeMenu, setSelectedRange, type}) => {
    const [searchParams, setSearchParams] = useSearchParams();

    // Функция, добавляющая квери параметры в урл
    const addQueryParams = (value) => {
        const formattedValue = formatWordValue(value)
        searchParams.set(type, formattedValue);
        setSearchParams(searchParams);
    };

    const onClickHandler = (el) => {
        setActiveTypeMenu(prevState => !prevState)
        setSelectedRange(el)
        addQueryParams(el.id)
    }

    return (
        <div>
            <div className={styles.container}>
                <div>
                    <div className={styles.mapButton}>
                        {categoryPoints.map((el) => (
                            <div
                                className={styles.button}
                                key={el.id}
                                onClick={() => onClickHandler(el)}>
                                <div className={styles.txt}>{el.name}</div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
};
