import styles from "../pop-up-styles.module.css"
import closeXSign from "../../../../images/close-x-sign-svg.svg"
import { useProfile } from "../../../../services/context/ProfileContext"
import { useCallback } from "react"

const SubscriptionCancel = ({ setShowWarningCancelModal}) => {
  const contextValue = useProfile()
  const handleTargetClick = useCallback((event) => {
    if (event.target === event.currentTarget) {
          setShowWarningCancelModal(false)
          contextValue.setCancelSubErrorMessage(false)
    }
  })
  const handleClick = useCallback(() => {
          setShowWarningCancelModal(false)
          contextValue.setCancelSubErrorMessage(false)
  })
  return (
    <div className={styles.containerWrapper} onClick={handleTargetClick}>
      <div className={styles.container}>
        <img src={closeXSign} className={styles.closeImg} onClick={handleTargetClick}/>
        <div className={styles.topBody}>
          <div className={styles.topTxtBody}>
            {contextValue.cancelSubErrorMessage} 
          </div>
          
        </div>
        
      <div className={styles.bottomBody}>
      <div className={styles.buttonWrapper} onClick={handleClick}>
        <div className={styles.buttonTxt} >
          Вернуться
        </div>
      </div>
    </div>
      </div>
    </div>
  )
}
export default SubscriptionCancel
