import styles from './video-section.module.css';
import RightGradient from '../../../../../../images/Ellipse-products-green-right-promocode-section.svg';
import LeftGradient from '../../../../../../images/Ellipse-products-green-left-promocode-section.svg';
import Phone from '../../../../../../images/product-chat-unity-img.svg';
import { useMediaQuery } from 'react-responsive';
const VideoSection = () => {
  const isMobile = useMediaQuery({ query: '(max-width: 760px)' });

  return (
    <section className={styles.section}>
      <div className={styles.backgroundWrapper}>
        <img src={RightGradient} className={styles.backgroundRight} />
        <img src={LeftGradient} className={styles.backgroundLeft} />
      </div>

      <div className={styles.containerWrapper}>
        <div className={styles.container}>
          {!isMobile && <div className={styles.title}>Помощь экспертов и сообщества</div>}
          {isMobile && <div className={styles.title}>Наша эффективность</div>}

          <div className={styles.subTitleWrapper}>
            <div className={styles.subTitle}>Еженедельные вебинары</div>
            <div className={styles.secondSubTitle}>
              Обозреваем рынки, валюты, коммодитиз, макроэкономику
            </div>
          </div>

          <div className={styles.subTitleWrapper}>
            <div className={styles.subTitle}>Чат с экспертами и инвесторами</div>
            <div className={styles.secondSubTitle}>
              Наша команда отвечает на вопросы в онлайн режиме <br /> Общайтесь с другими клиентами
              сервиса, обмениваетесь опытом
            </div>
          </div>

          <div className={styles.phoneSectionWrapper}>
            <div className={styles.phoneSection}>
              <div className={styles.phoneImg}>
                <img src={Phone} className={styles.phoneImg} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default VideoSection;
