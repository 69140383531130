import React, {useState} from 'react';
import arrowDown from '../../../../../images/arrow-down-sign-to-navigate-svg.svg'
import {Dropdown, Menu, Space} from 'antd';
import {FinanceBarChart} from "./finance-bar-chart/finance-bar-chart";
import {FinanceTable} from "./finance-table/finance-table";
import crossIcon from '../../../../../images/cross.svg'
import styles from './skriner-tab-finance.module.css'

const SkrinerTabFinance = () => {
    const [selectedRevenueRows, setSelectedRevenueRows] = useState(['1', '4']);
    const [selectedOperatingRows, setSelectedOperatingRows] = useState(['1', '4']);

    const items = [
        {
            label: 'Добавьте компанию для сравнения',
            key: '0',
        },
        {
            label: 'НК Роснефть – ROSN',
            ticker: 'ROSN',
            key: '1',
        },
        {
            label: 'Газпром нефть - SIBN',
            ticker: 'SIBN',
            key: '2',
        },
        {
            label: 'Лукойл - LKOH',
            ticker: 'LKOH',
            key: '3',
        },
    ];

    const [selectedRevenueCompany, setSelectedRevenueCompany] = useState(items[0])
    const [selectedOperatingCompany, setSelectedOperatingCompany] = useState(items[0])


    const tableRowTitleForRevenue = [
        {id: 213, title: 'revenue', fill: '#BC79FF', stackId: 'a'},
        {id: 234, title: 'clearProfit', fill: '#69CDFF', stackId: 'd'},
        {id: 534, title: 'cost', fill: '#80FFEA', stackId: 'b'},
        {id: 867, title: 'gross', fill: '#75F77D', stackId: 'c'}
    ]

    const tableRowTitleForRevenueSecond = [
        {id: 7986, title: 'revenueCompare', fill: '#9366C8', stackId: 'a'},
        {id: 2346, title: 'clearProfitCompare', fill: '#59A1C8', stackId: 'd'},
        {id: 9587, title: 'costCompare', fill: '#69C4B9', stackId: 'b'},
        {id: 5234, title: 'grossCompare', fill: '#539662', stackId: 'c'}
    ]

    const tableRowTitleForOperating = [
        {id: 768, title: 'operatingProfit', fill: '#BC79FF', stackId: 'e'},
        {id: 604, title: 'operatingExp', fill: '#80FFEA', stackId: 'f'},
        {id: 486, title: 'researchDev', fill: '#75F77D', stackId: 'g'},
        {id: 195, title: 'adminExp', fill: '#69CDFF', stackId: 'h'},
    ]

    const tableRowTitleForOperatingSecond = [
        {id: 32424, title: 'operatingProfitCompare', fill: '#9366C8', stackId: 'e'},
        {id: 45676, title: 'operatingExpCompare', fill: '#59A1C8', stackId: 'f'},
        {id: 43523, title: 'researchDevCompare', fill: '#69C4B9', stackId: 'g'},
        {id: 53464, title: 'adminExpCompare', fill: '#539662', stackId: 'h'},
    ]

    const onRevenueClick = (e) => {
        if (e.key === '0') setSelectedRevenueCompany(items[0])
        if (e.key !== '0') setSelectedRevenueCompany(items[e.key])
    }

    const onOperatingClick = (e) => {
        if (e.key === '0') setSelectedOperatingCompany(items[0])
        if (e.key !== '0') setSelectedOperatingCompany(items[e.key])
    }

    const menuRevenue = (
        <Menu onClick={onRevenueClick}>
            {items.map(item => (
                <Menu.Item
                    key={item.key}
                    className={`${item.key === selectedRevenueCompany.key ? styles.selectedDropdownItem : ''} ${item.key === '0' && styles.notSelectItem}`}
                >
                    {item.label}
                </Menu.Item>
            ))}
        </Menu>
    );

    const menuOperating = (
        <Menu onClick={onOperatingClick}>
            {items.map(item => (
                <Menu.Item
                    key={item.key}
                    className={`${item.key === selectedOperatingCompany.key ? styles.selectedDropdownItem : ''} ${item.key === '0' && styles.notSelectItem}`}
                >
                    {item.label}
                </Menu.Item>
            ))}
        </Menu>
    );

    const removeSelection = (e, type) => {
        e.preventDefault()
        if (type === 'revenue') setSelectedRevenueCompany(items[0])
        if (type === 'operating') setSelectedOperatingCompany(items[0])
    }

    const blocks = [
        {
            id: 2134234,
            title: 'Доходы',
            blockName: 'revenue',
            selectedCompany: selectedRevenueCompany,
            menu: menuRevenue,
            isRevenue: true,
            tableRowTitle: tableRowTitleForRevenue,
            selectedRows: selectedRevenueRows,
            tableRowTitleSecond: tableRowTitleForRevenueSecond,
            setSelectedRows: setSelectedRevenueRows
        },
        {
            id: 562435643,
            title: 'Мультипликаторы',
            blockName: 'operating',
            selectedCompany: selectedOperatingCompany,
            menu: menuOperating,
            isRevenue: false,
            tableRowTitle: tableRowTitleForOperating,
            selectedRows: selectedOperatingRows,
            tableRowTitleSecond: tableRowTitleForOperatingSecond,
            setSelectedRows: setSelectedOperatingRows
        },
        {
            id: 234524532345,
            title: 'Бухгалтерский баланс',
            blockName: 'revenue',
            selectedCompany: selectedRevenueCompany,
            menu: menuRevenue,
            isRevenue: false,
            tableRowTitle: tableRowTitleForRevenue,
            selectedRows: selectedRevenueRows,
            tableRowTitleSecond: tableRowTitleForRevenueSecond,
            setSelectedRows: setSelectedRevenueRows
        },
        {
            id: 562435643,
            title: 'Движение денежных средств',
            blockName: 'operating',
            selectedCompany: selectedOperatingCompany,
            menu: menuOperating,
            isRevenue: false,
            tableRowTitle: tableRowTitleForOperating,
            selectedRows: selectedOperatingRows,
            tableRowTitleSecond: tableRowTitleForOperatingSecond,
            setSelectedRows: setSelectedOperatingRows
        }
    ]

    return (
        <div className={styles.financeTab}>

            {blocks.map(el => {
                return <div key={el.id} className={styles.chartBlock}>
                    <h3 className={styles.blockTitle}>{el.title}</h3>

                    <Dropdown
                        className={`${styles.dropdown} ${el.selectedCompany.key === '0' && styles.notValue}`}
                        overlay={el.menu}
                        trigger={['click']}
                    >
                        <a onClick={(e) => e.preventDefault()}>
                            <Space
                                className={styles.space}
                            >
                                <div className={styles.dropdownSpaceContainer}>
                                    {el.selectedCompany.key !== '0' && <button className={styles.removeSelection}
                                                                               onClick={(e) => removeSelection(e, el.blockName)}>
                                        <img src={crossIcon} alt="крестик" width={10}/>
                                    </button>}
                                    {el.selectedCompany.label}
                                </div>
                                <img src={arrowDown} alt="arrow down" className={styles.arrowIcon}/>
                            </Space>
                        </a>
                    </Dropdown>
                    <FinanceBarChart isRevenue={el.isRevenue} tableRowTitle={el.tableRowTitle}
                                     selectedRows={el.selectedRows} tableRowTitleSecond={el.tableRowTitleSecond}
                                     selectedCompany={el.selectedCompany}
                    />
                    <FinanceTable tableRowTitle={el.tableRowTitle} selectedRows={el.selectedRows}
                                  setSelectedRows={el.setSelectedRows} selectedCompany={el.selectedCompany}/>
                </div>
            })}

        </div>
    );
};

export default SkrinerTabFinance;
