import React, {useEffect, useState} from 'react';
import {useParams} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {getInformationScreenerPage} from "../../../services/reducers/screener-slice";
import SkrinerPageHeader from "./ui/skriner-page-header/skriner-page-header";
import SkrinerNavigate from "./ui/skriner-navigate/skriner-navigate";
import SkrinerTabInformation from "./ui/skriner-tab-information/skriner-tab-information";
import SkrinerTabFinance from "./ui/skriner-tab-finance/skriner-tab-finance";
import SkrinerTabAnalysis from "./ui/skriner-tab-analysis/skriner-tab-analysis";
import SkrinerTabCalendar from "./ui/skriner-tab-calendar/skriner-tab-calendar";
import SkrinerTabDocs from "./ui/skriner-tab-docs/skriner-tab-docs";
import Preloader from "../../preloader/preloader";
import styles from './skriner.module.css'

const SkrinerPage = () => {
    const [activeTab, setActiveTab] = useState('Информация');
    const {loading, information} = useSelector((store) => store.screener);
    const dispatch = useDispatch();
    const params = useParams();

    const renderTabContent = () => {
        switch (activeTab) {
            case 'Информация':
                return <SkrinerTabInformation />;
            case 'Финансовые показатели':
                return <SkrinerTabFinance />;
            case 'Анализ':
                return <SkrinerTabAnalysis information={information}/>;
            case 'Календарь':
                return <SkrinerTabCalendar />;
            case 'Документы':
                return <SkrinerTabDocs />;
            default:
                return <SkrinerTabInformation />;
        }
    };

    useEffect(() => {
        dispatch(getInformationScreenerPage(params?.slug));
    }, [dispatch, params.slug]);

    return (
        loading ? (
            <>
                <section className={styles.skriner}>
                    <div className={styles.preloader}>
                        <Preloader/>
                    </div>
                </section>
            </>
        ) : (
            <>
                    <SkrinerPageHeader/>
                    <section className={styles.skriner}>
                        <div className={styles.container}>
                            <SkrinerNavigate setActiveTab={setActiveTab}/>
                            {renderTabContent()}
                        </div>
                    </section>
                </>
            )

    );
};

export default SkrinerPage;
