import styles from './table-new.module.css';
import './style.css';

import {NavLink, useLocation, useParams} from 'react-router-dom';
import {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import Preloader from '../../preloader/preloader';
import {sendDataConfigNewTableToServer} from '../../../utils/api-tables';
import {useActiveButton} from '../../../services/context/ActiveButtonContext';
import closeXSign from '../../../images/close-x-sign-svg.svg';
import {ConfigProvider, Form, Radio, Switch, Table} from 'antd';
import ru_RU from 'antd/locale/ru_RU';
import {useMediaQuery} from 'react-responsive';
import SwitchIcon from '../../../images/table-switch-icon.svg';
import {TableModalPanel} from '../tables/tablesSubPanel';
import DiagramTableColumn from './Diagrams/tableColumDiagram/table-colum-diagram';
import {useTheme} from "../../../services/hooks/useTheme";
import {setClearTable} from "../../../services/reducers/tables-slice";

function TableNew() {
  const isMobile = useMediaQuery({ query: '(max-width: 760px)' });
  const params = useParams();
  const location = useLocation();
  const valueContext = useActiveButton();
  const dispatch = useDispatch();
  const table = useSelector((store) => store.tables.tables);
  const isLoading = useSelector((store) => store.preloader.isLoading);
  const { tablesConfig, status } = useSelector((state) => state.countryInfo);
  const isAuthChecked = useSelector((store) => store.user.isAuthChecked);

  const [isShowColomnViewOption, setIsShowColomnViewOption] = useState(false);
  const [isShowColomnWidthOption, setIsShowColomnWidthOption] = useState(false);
  const [tableLayout] = useState('fixed');
  const [checkedList, setCheckedList] = useState([]);
  const [size, setSize] = useState('large');
  const [fontSize, setFontSize] = useState(14);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [hasShownNoAuthModal, setHasShownNoAuthModal] = useState(false);
  const [hasShownAccessDeniedModal, setHasShownAccessDeniedModal] = useState(false);
  const [localLoadingForWaitIf, setLocalLoadingForWaitIf] = useState(true);
  const [tableForThisPath, setTableForThisPath] = useState([]);
  const diagramRef = useRef(null);
  const {theme} = useTheme();
  const [localLoading, setLocalLoading] = useState(true);



  // вспомогательные функции
  function handleCheckList  (el, checkedSwitch)  {
    if (checkedSwitch) {
      setCheckedList((prev) => {
        return prev.filter((val) => val !== el.value);
      });
    } else {
      setCheckedList([...checkedList, el.value]);
    }
  }

  function parseDate(dateStr) {
    if (dateStr === '-' || dateStr === '') {
      return new Date(0);
    }
    const [day, month, year] = dateStr.split('.').map(Number);
    const fullYear = year < 100 ? (year < 50 ? year + 2000 : year + 1900) : year; // если год меньше 50, то прибавляем 2000, иначе 1900
    return new Date(fullYear, month - 1, day);
  }

  const sortAlphabetically = useCallback((a, b) =>  {
    // Приводим строки к нижнему регистру для регистронезависимой сортировки
    const strA = !!a ? a.toLowerCase() : '-';
    const strB = !!b ? b.toLowerCase() : '-';

    // Сравниваем строки для определения порядка
    if (strA < strB) {
      return -1; // Если strA идет раньше strB, возвращаем отрицательное число
    } else if (strA > strB) {
      return 1; // Если strA идет после strB, возвращаем положительное число
    } else {
      return 0; // Если строки равны, возвращаем 0
    }
  }, []);

  const colorStyleTxt = (value, record) =>  {
    const color = {};
    if (record?.['Доходность, %'] === value && record?.['Доходность, %'] < 0) {
      color.color = theme === 'app_dark_theme' ? '#ff7668' : 'red';
    } else if (record?.['Доходность, %'] === value && record?.['Доходность, %'] > 0) {
      color.color = theme === 'app_dark_theme' ? '#00FF00' : 'green';
    }
    return color;
  }


  function scrollToDiagram  ()  {
    diagramRef.current.scrollIntoView({ behavior: 'smooth' });
  }

//функции преобразования table
  const clearTable = useMemo(() => {
    return table.filter((el) => {
      return !('subscription' in el) && el['Название'] !== 'Cash';
    });
  }, [table]);

  const pureTitleList = useMemo(() => {
    // [{…}, {…}, { => {label: 'Название', key: 0}, {label: 'Тикер',
     // Чистим массив от объекта подписки
    return clearTable.length !== 0 ? Object.keys(clearTable[0]) : [];
  }, [clearTable]);

  //  Формируется массив для Шапки таблицы
  const dataTitleKeys = useMemo(() => {
    // [{…}, {…}, { => {label: 'Название', key: 0}, {label: 'Тикер',
    return pureTitleList.map((el, i) => {
      //// [{…}, {…}, { => {label: 'Название', key: 0}, {label: 'Тикер',

      el = !!el ? el : '-';
      return { label: el, key: i };
    });
  }, [pureTitleList]);

  const dataRowName = useCallback(
    (label) => {
      return clearTable

        .filter((_, i) => i > 0)
        .map((el) => {
          return {
            text: el[label],
            value: el[label],
          };
        })
        .reduce((accumulator, currentValue) => {
          const existingObject = accumulator.find(
            (obj) => obj.value === currentValue.value || currentValue.value === '',
          );
          if (!existingObject) {
            accumulator.push(currentValue);
          }

          return accumulator;
        }, []);
    },
    [clearTable],
  );

  const dataTitleColumns = useMemo(() => {
    return dataTitleKeys.map(({ label, key }) => {
      return {
        key,
        title: label,
        dataIndex: label,
        ellipsis: false,
        sorter: (a, b) => {
          const numberRegex = /^-?\d+(\.\d+)?$/;
          const dateRegexLong = /^\d{2}\.\d{2}\.\d{4}$/;
          const dateRegexShort = /^\d{2}\.\d{2}\.\d{2}$/;

          let valueA = a[label];
          let valueB = b[label];

          if (valueA === 'N/A' || valueA.includes('%')) valueA = '-';
          if (valueB === 'N/A' || valueB.includes('%')) valueB = '-';

          if (dateRegexLong.test(valueA) && dateRegexLong.test(valueB)) {
            const dateA = parseDate(valueA);
            const dateB = parseDate(valueB);
            return dateA - dateB;
          } else if (dateRegexShort.test(valueA) && dateRegexShort.test(valueB)) {
            const dateA = parseDate(valueA);
            const dateB = parseDate(valueB);
            return dateA - dateB;
          } else if (numberRegex.test(valueA) && numberRegex.test(valueB)) {
            return parseFloat(valueA) - parseFloat(valueB);
          } else {
            return sortAlphabetically(valueA, valueB);
          }
        },
        filters: dataRowName(label),
        onFilter: (value, record) => record[label].startsWith(value),
        filterSearch: true,
        render: (value, record) => {
          if (!!value) {
            return (
              <div className={styles.tableTitleWrapper}>
                <span style={colorStyleTxt(value, record)} className={styles.tableTitle}>
                  {value}
                </span>
              </div>
            );
          } else {
            return <span>-</span>;
          }
        },
      };
    });
  }, [dataTitleKeys, dataRowName, sortAlphabetically, theme]);

  const fullOrNotTableAll = valueContext.availableTables.flatMap((item) =>
    item.tables.flatMap((table) =>
      table.table.flatMap((subTable) => subTable.links.map((link) => link.url.replace(/\/$/, ''))),
    ),
  );
  const fullOrNotTable = useMemo(() => [...new Set(fullOrNotTableAll)], [fullOrNotTableAll]);
  const authOrNot = Boolean(fullOrNotTable?.includes(`${params.sub}/${params.slug}`));

  //данные для диаграммы с полем Доля
  const transformDataForDiagramm = table.filter((item) => {
    return (
      // item['Название'] !== 'Cash' &&
      // item['Компания'] !== 'Cash' &&
      (item['Доля, %'] || item['Доля'])
    );
  });

  //данные для диаграммы с полем Сектор или Отрасль
  const transformDataForDiagramaSector = table.map((item) => {
    if (item['Название'] === 'Cash') {
      return {
        ...item,
        'Сектор': item['Сектор'] === '' ? 'Cash' : item['Сектор'],
        'Отрасль': item['Отрасль'] === '' ? 'Cash' : item['Отрасль'],
      };
    }
    return item;
  }).filter((item) =>
      (item['Доля, %'] || item['Доля']) &&
      (item['Сектор'] || item['Отрасль'])
  );




  const dataRowsTable = useMemo(() => {
    return clearTable.map((el, i) => {
      let currentPrice;
      let entryPoint;

      if (!isAuthChecked && i > 1) {
        return {
          ...el,
          key: i,
          blur: true,
        };
      }

      if (isAuthChecked && !fullOrNotTable.includes(`${params.sub}/${params.slug}`) && i > 1) {
        return {
          ...el,
          key: i,
          blur: true,
        };
      }

      if (el['Текущая цена'] && el['Точка входа']) {
        currentPrice = Number(el['Текущая цена'].replace(/,/g, ''));
        entryPoint = Number(el['Точка входа'].replace(/,/g, ''));

        if (!isNaN(currentPrice) && !isNaN(entryPoint)) {
          if (isAuthChecked && currentPrice > entryPoint) {
            return {
              ...el,
              key: i,
              flag: true,
            };
          } else {
            return {
              ...el,
              key: i,
              flag: false,
            };
          }
        }
      }

      return {
        ...el,
        key: i,
        flag: false,
        blur: false,
      };
    });
  }, [clearTable, isAuthChecked, fullOrNotTable]);

  const getRowClassName = (record) => {
    let className = '';

    if (record.flag === false && record['Точка входа'] && record['Текущая цена']) {
      className = 'redRow';
    }

    if (record.blur === true) {
      className = 'blur';
    }

    return className;
  };

  const options = useMemo(() => {
    return dataTitleKeys.map(({ label, key }) => ({
      label,
      value: key,
    }));
  }, [dataTitleKeys]);

  const handleSizeChange = useCallback((e) => {
    setSize(e.target.value);
  }, []);
  const handleFontSizeChange = useCallback((e) => {
    setFontSize(e.target.value);
  }, []);

  //Отключение скролла в мобильной версии на открытии фильтров
  useEffect(() => {
    if (isMobile) {
      if (isShowColomnViewOption || isShowColomnWidthOption) {
        document.body.style.overflow = 'hidden';
      }
      if (!isShowColomnViewOption && !isShowColomnWidthOption) {
        // || !isShowColomnWidthOption
        document.body.style.overflow = '';
      }
    }
    // console.log(isShowColomnViewOption || isShowColomnWidthOption);
  }, [isShowColomnViewOption, isShowColomnWidthOption, isMobile]);



  // Способ добавить для колонокок свойство
  const tableColumns = useMemo(() => {
    let obj = dataTitleColumns.map((item, i) => ({
      ...item,
      className: 'titleColumn',
      width: i === 0 ? 150 : fontSize === 18 ? 150 : 138,
      hidden: !checkedList.some((el) => el === item.key),
    }));

    if (isMobile) {
      obj = obj.map((item, i) => ({
        ...item,
        width: i === 0 ? 150 : fontSize === 18 ? 150 : 138,
      }));
    }

    obj = obj.map((item, i) => {
      if (i === 0) {
        return {
          ...item,
          fixed: 'left',
          width: fontSize === 18 ? 160 : 150,
        };
      } else if (item.title === 'Дата') {
        return {
          ...item,
          sorter: (a, b) => {
            const dateA = parseDate(a[item.title]);
            const dateB = parseDate(b[item.title]);
            return dateA - dateB;
          },
          defaultSortOrder: 'descend',
        };
      } else if (item.title === 'Ссылка') {
        return {
          ...item,
          render: (text, record) => {
            return {
              children: (
                <a className="linkTelegram" href={text}>
                  <div className="linkText">{text}</div>
                </a>
              ),
            };
          },
        };
      } else if (
        /Дисконт к средн/i.test(item.title) &&
        location.pathname === '/tables/unity-russia/unity/forecast-multipliers-rus'
      ) {
        return {
          ...item,
          render(text, record) {
            const currentPrice = text === 'N/A' ? 0 : Number(text.replace(/,/g, ''));
            const colorFunc = () => {
              if (!isNaN(currentPrice)) {
                if (currentPrice >= 20) {
                  return '#ef3434';
                } else if (currentPrice >= 10 && currentPrice < 20) {
                  return 'rgb(207, 84, 84)';
                } else if (currentPrice >= -20 && currentPrice < -10) {
                  return 'rgb(77, 153, 77)';
                } else if (currentPrice < -20) {
                  return '#32b332';
                }
              }
            };

            return {
              props: {
                style: { background: colorFunc() },
              },
              children: <div>{text}</div>,
            };
          },
        };
      } else {
        return item;
      }
    });

    if (!isAuthChecked || !fullOrNotTable.includes(`${params.sub}/${params.slug}`)) {
      obj = obj.map((item, i) => {
        if (i === 0) {
          return {
            ...item,
            filters: false,
            filterSearch: false,
            onFilter: false,
            sorter: false,
            width: i === 0 ? 150 : fontSize === 18 ? 150 : 138,
            defaultSortOrder: false,
          };
        } else {
          return {
            ...item,
            filters: false,
            filterSearch: false,
            onFilter: false,
            sorter: false,
            width: i === 0 ? 150 : fontSize === 18 ? 150 : 138,
            defaultSortOrder: false,
          };
        }
      });
    }

    return obj;
  }, [
    dataTitleColumns,
    checkedList,
    isMobile,
    fullOrNotTable,
    isAuthChecked,
    params.sub,
    params.slug,
  ]);

  const tableProps = {
    bordered: true,
    loading: false,
    size,
    showHeader: true,
    scroll: { x: '100vw', y: '100%' },
    tableLayout,
  };


  //конфиг таблиц для авторизованых
  useEffect(()=> {
    if (isAuthChecked) {
      valueContext.getConfigurationNewTableApi();
    }
  }, [isAuthChecked])

  //получение таблиц
  useEffect(() => {
    valueContext.getTableFromApi(params.sub, params.slug);
    valueContext.getAvailableTablesContext();
  }, [params.sub, params.slug]);

  //проверка есть ли конфиг для конкретной таблицы
  useEffect(() => {
    if(tablesConfig.length !== 0) {
      setTableForThisPath(tablesConfig.filter((item) => item.table_name === params.slug));
    }
  }, [tablesConfig, params.slug]);

  //отправка конфига на сервер
  useEffect(() => {
    const newObjConfig = {
      table_name: params.slug,
      settings: {
        size: size,
        'font-size': fontSize,
      },
      columns: checkedList,
    };
    if(isAuthChecked && (isShowColomnViewOption || isShowColomnWidthOption) ) {
      if (newObjConfig.length !== 0) {
        // console.log('отправил на сервер')
        sendDataConfigNewTableToServer(newObjConfig);
      }

    }
  }, [checkedList, size, fontSize]);


  useEffect(() => {
     function fetchData() {
       if(!isAuthChecked && !isShowColomnViewOption && !isShowColomnWidthOption &&  params.slug !== 'dividends-rus') {
         setCheckedList(pureTitleList.map((_, i) => i));
         setFontSize(14);
         setSize('large')
         setLocalLoading(false);
         // console.log('конфиг не авторизован')
       } else if (!isAuthChecked && !isShowColomnViewOption && !isShowColomnWidthOption &&  params.slug === 'dividends-rus') {
         const indices = pureTitleList.reduce((acc, item, index) => {
           if (!item.includes("2023")) {
             acc.push(index);
           }
           return acc;
         }, []);
         setCheckedList(indices);
         setFontSize(14);
         setSize('large')
         setLocalLoading(false);
       }

       if(isAuthChecked && !isShowColomnViewOption && !isShowColomnWidthOption ) {
         if (tableForThisPath.length !== 0  && isAuthChecked  && params.slug !== 'dividends-rus') {
           const sortedColumns = tableForThisPath[0]?.columns?.map(item => item).sort((a, b) => a - b)
           setCheckedList(sortedColumns);
           setFontSize(tableForThisPath[0]?.settings?.['font-size']);
           setSize(tableForThisPath[0]?.settings?.size);
           setLocalLoading(false);
           // console.log('конфиг с сервера')
         } else if(tableForThisPath.length !== 0  && isAuthChecked &&  params.slug === 'dividends-rus' && !localStorage.getItem('dividends')) {
           const indices = pureTitleList.reduce((acc, item, index) => {
             if (!item.includes("2023")) {
               acc.push(index);
             }
             return acc;
           }, []);
           setCheckedList(indices);
           setFontSize(tableForThisPath[0]?.settings?.['font-size']);
           setSize(tableForThisPath[0]?.settings?.size);
           localStorage.setItem('dividends', 'true'); //для одного раза загрузка будет с указанием столблцов
           setLocalLoading(false);
         } else if (tableForThisPath.length !== 0  && isAuthChecked &&  params.slug === 'dividends-rus' && localStorage.getItem('dividends')) {
           const sortedColumns = tableForThisPath[0]?.columns?.map(item => item).sort((a, b) => a - b)
           setCheckedList(sortedColumns);
           setFontSize(tableForThisPath[0]?.settings?.['font-size']);
           setSize(tableForThisPath[0]?.settings?.size);
           setLocalLoading(false);
         }


         if(isAuthChecked && tableForThisPath.length === 0 &&  params.slug !== 'dividends-rus') {
           setCheckedList(pureTitleList.map((_, i) => i));
           setFontSize(14);
           setSize('large');
           setLocalLoading(false);
           // console.log('конфиг локально')
         } else if (isAuthChecked && tableForThisPath.length === 0  && params.slug === 'dividends-rus' && !localStorage.getItem('dividends')) {
           const indices = pureTitleList.reduce((acc, item, index) => {
             if (!item.includes("2023")) {
               acc.push(index);
             }
             return acc;
           }, []);
           setCheckedList(indices);
           setFontSize(14);
           setSize('large');
           localStorage.setItem('dividends', 'true'); //для одного раза загрузка будет с указанием столблцов
           setLocalLoading(false);
         } else if (isAuthChecked && tableForThisPath.length === 0  && params.slug === 'dividends-rus' && localStorage.getItem('dividends')) {
           setCheckedList(pureTitleList.map((_, i) => i));
           setFontSize(14);
           setSize('large');
           setLocalLoading(false);
         }
       }

    }
    if( table.length !== 0) {
      fetchData();
    }
  }, [
    size,
    fontSize,
    pureTitleList,
    isAuthChecked,
    tableForThisPath
  ]);


  //модалка
  useEffect(() => {
    let timer;

    setLocalLoadingForWaitIf(true); //крутим Preloader пока true
     if (
        isAuthChecked &&
        params.sub &&
        params.slug &&
        fullOrNotTable &&
        !fullOrNotTable.includes(`${params.sub}/${params.slug}`) &&
        !hasShownAccessDeniedModal && table.length !== 0
    ) {
      // console.log('зареган но без доступа');
      timer = setTimeout(() => {
        setIsModalVisible(true);
        setHasShownAccessDeniedModal(true); // Устанавливаем флаг, чтобы избежать повторного показа
        setLocalLoadingForWaitIf(false);
      }, 2000);
    }  else if (!isAuthChecked && !hasShownNoAuthModal && table.length !== 0) {
      // console.log('Не зареган ');
      timer = setTimeout(() => {
        setIsModalVisible(true);
        setHasShownNoAuthModal(true); // Устанавливаем флаг, чтобы избежать повторного показа
        setLocalLoadingForWaitIf(false);
      }, 2000);
    } else {
      setLocalLoadingForWaitIf(false);
    }

    return () => {
      if (timer) clearTimeout(timer);
    };
  }, [
    fullOrNotTable,
    params.sub,
    params.slug,
    isAuthChecked,
    hasShownNoAuthModal,
    hasShownAccessDeniedModal,
  ]);


  useEffect(() => {
    return() => {
        dispatch(setClearTable())
    }
  }, []);



  const darkTheme = {
    components: {
      Table: {
        // headerFilterHoverBg: "rgb(82,196,26)",
        headerSortActiveBg: "rgb(82,196,26)",
        bodySortBg: "rgb(99,99,99)",
        headerSortHoverBg: "rgb(82,196,26)",

        headerBg: "rgba(60,60,60,1)", //цвет шапки таблицы bg
        headerColor: "rgba(255,255,255,0.88)", // цвет шапки таблицы текст
        colorBgContainer: "rgb(99,99,99)", // цвет фона таблицы
        colorText: "rgba(255,255,255,0.85)",

        borderColor: "rgb(156,152,152)", // цвет границ таблицы
        // expandIconBg: "rgb(82,196,26)",

        //цвета фильтрации
        filterDropdownBg: "rgba(60,60,60,1)",
        filterDropdownMenuBg: "rgba(60,60,60,1)",
        fixedHeaderSortActiveBg: "rgb(82,196,26)", //ховер первой колонки

        algorithm: "dark",
      }
    }
  };

  const lightTheme = {
    components: {
      Table: {
        headerSortActiveBg: "rgb(82,196,26)",
        bodySortBg: "rgb(255,255,255)",
        headerSortHoverBg: "rgb(82,196,26)",

        headerBg: "rgba(240,240,240,1)", // Цвет шапки таблицы bg
        headerColor: "rgba(0,0,0,0.88)", // Цвет шапки таблицы текст
        colorBgContainer: "rgb(255,255,255)", // Цвет фона таблицы
        colorText: "rgba(0,0,0,0.85)",

        borderColor: "rgb(200,200,200)", // Цвет границ таблицы

        filterDropdownBg: "rgba(240,240,240,1)", // Цвет фильтрации
        filterDropdownMenuBg: "rgb(255,255,255)", // Цвет меню фильтрации
        fixedHeaderSortActiveBg: "rgb(82,196,26)", // Ховер первой колонки

        algorithm: "light",
      }
    }
  };



  const onlineTheme = theme === 'app_light_theme' ? lightTheme : darkTheme;

  const svgImgSwitchIconColor = () => {
    const color = theme === 'app_light_theme' ? '#047337' : '#37D881';
    return (
        <svg className={styles.switchIcon} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M2 8L22 8" stroke={color} stroke-width="2"/>
          <path d="M2 16H22" stroke={color} stroke-width="2"/>
          <circle cx="16" cy="8" r="2" fill="white" stroke={color} stroke-width="2"/>
          <circle cx="8" cy="16" r="2" fill="white" stroke={color} stroke-width="2"/>
        </svg>
    )
  }


  const mounted = useRef(null);

  return (
      <>
        {/* {userSub && <TableModalPanel close={() => setIsModalVisible(false)} data={table} />} */}
        {isModalVisible && table.length !== 0 && !localLoadingForWaitIf && !isLoading && !status && (
            <TableModalPanel close={() => setIsModalVisible(false)} data={table}/>
        )}
        <section className={styles.section}>
        <div className={styles.navigationBox}>
          <NavLink to={'/'} className={styles.link}>
            <div className={styles.arrow}></div>
            Главная
          </NavLink>

          <NavLink to={`/tables/${params.subs}`} className={styles.link}>
            <div className={styles.arrow}></div>
            Портфели
          </NavLink>
        </div>
      </section>
      {isLoading || status || table.length === 0 || localLoading ? (
        <div className={styles.preloader}>
          <Preloader />
        </div>
      ) : (
        <div className={styles.main}>
          <h3 className={styles.mainTitle}>{table[0]?.name}</h3>

          <ConfigProvider
            locale={ru_RU}
            theme={onlineTheme}
          >
            <div className={styles.optionsBoxWrapper}>
              {/*

              <div className={styles.layoutPointsWrapper}>
              <div className={styles.switchTitle}>Ширина колонки</div>

                <Radio.Group
                  value={tableLayout}
                  onChange={handleTableLayoutChange}
                >
                  <Radio.Button value={"auto"}>По контенту</Radio.Button>
                  <Radio.Button value="fixed">Фиксированная</Radio.Button>
                </Radio.Group>
              </div>
              */}

              <div className={styles.switchBoxButtonSectionWrapper}>
                <div
                  className={styles.switchBoxButtonSection}
                  onClick={() => {
                    setIsShowColomnViewOption((prev) => !prev);
                  }}>
                  {svgImgSwitchIconColor()}
                  <div className={styles.switchTitle}>
                    {isMobile ? 'Столбцы' : 'Отображение столбцов'}
                  </div>
                </div>

                {isShowColomnViewOption && (
                  <div className={styles.switchBoxPointsSectionWrapper}>
                    <div className={styles.switchBoxPointsSection}>
                      <div className={styles.closeImg}>
                        <img
                          src={closeXSign}
                          alt="close"
                          onClick={() => {
                            setIsShowColomnViewOption(false);
                          }}
                        />
                      </div>
                      {isMobile && (
                        <div className={styles.mobileOptionTitle}>Отображение столбцов</div>
                      )}
                      <div className={styles.formScrollWrapper}>
                        <Form
                          layout="inline"
                          className="components-table-demo-control-bar"
                          style={{
                            marginBottom: isMobile ? 150 : 0,
                            gap: '2px',
                            flexDirection: 'column',
                          }}>
                          {checkedList.length !== 0 &&
                            options.map((el) => {
                              const checkedSwitch = checkedList.some((val) => val === el.value);
                              if (el.label === 'Название') {
                                return null;
                              }
                              return (
                                <Form.Item key={el.key}>
                                  <div className={styles.checkedListWrapper}>
                                    <Switch
                                      checked={checkedSwitch}
                                      onChange={() => {
                                        handleCheckList(el, checkedSwitch);
                                      }}
                                    />
                                    <div className={styles.switchPointTitle}>{el.label}</div>
                                  </div>
                                </Form.Item>
                              );
                            })}
                        </Form>
                      </div>
                      {isShowColomnViewOption && isMobile && (
                        <div className={styles.aceptButtonWrapper}>
                          <div
                            className={styles.acceptButtonActive}
                            onClick={() => {
                              setIsShowColomnViewOption(false);
                            }}>
                            Закрыть
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                )}
              </div>

              <div className={styles.switchBoxButtonSectionWrapper}>
                <div
                  className={styles.switchBoxButtonSection}
                  onClick={() => {
                    setIsShowColomnWidthOption((prev) => !prev);
                  }}>
                  {svgImgSwitchIconColor()}
                  <div className={styles.switchTitle}>Настройки</div>
                </div>

                {isShowColomnWidthOption && (
                  <div className={styles.switchBoxPointsSectionWrapper}>
                    <div className={styles.switchBoxPointsSection}>
                      <div className={styles.closeImg}>
                        <img
                          src={closeXSign}
                          alt="close"
                          onClick={() => {
                            setIsShowColomnWidthOption(false);
                          }}
                        />
                      </div>
                      {isMobile && (
                        <div className={styles.mobileOptionTitle}>Настройки таблицы</div>
                      )}
                      <Form
                        layout="inline"
                        className="components-table-demo-control-bar"
                        style={{
                          marginBottom: 0,
                          gap: '2px',
                          flexDirection: 'column',
                        }}>
                        <div className={styles.optionColumnWrapper}>
                          <div className={styles.optionColumn}>
                            <div className={styles.optionColumnPointTitle}>Размер ячеек</div>
                            <Form.Item>
                              <Radio.Group value={size} onChange={handleSizeChange}>
                                <Radio.Button value="large">Большие</Radio.Button>
                                <Radio.Button value="middle">Средние</Radio.Button>
                                <Radio.Button value="small">Маленькие</Radio.Button>
                              </Radio.Group>
                            </Form.Item>
                          </div>
                          <div className={styles.optionColumn}>
                            <div className={styles.optionColumnPointTitle}>Размер шрифта</div>
                            <Form.Item>
                              <Radio.Group value={fontSize} onChange={handleFontSizeChange}>
                                <Radio.Button value={18}>Большие</Radio.Button>
                                <Radio.Button value={14}>Средние</Radio.Button>
                                <Radio.Button value={12}>Маленькие</Radio.Button>
                              </Radio.Group>
                            </Form.Item>
                            {isShowColomnWidthOption && isMobile && (
                              <div className={styles.aceptButtonWrapper}>
                                <div
                                  className={styles.acceptButtonActive}
                                  onClick={() => {
                                    setIsShowColomnWidthOption(false);
                                  }}>
                                  Закрыть
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                      </Form>
                    </div>
                  </div>
                )}
              </div>

              {authOrNot &&
                  (transformDataForDiagramm.length !== 0 || transformDataForDiagramaSector.length !== 0)
                  && (
                <button className={styles.saveConfigBtn} onClick={scrollToDiagram}>
                  Перейти к диаграмме
                </button>
              )}
            </div>

            <Table
              {...tableProps}
              pagination={{
                position: ['none', 'none'],
                defaultPageSize: 9250,
                // showSizeChanger: true,
                // showExpandColumn: true
              }}
              ref={mounted}
              columns={tableColumns}
              // columns={newColumns}
              dataSource={dataRowsTable}
              // scroll={scroll}
              sticky={{ offsetHeader: 0 }}
              rowClassName={getRowClassName}
              className={styles.tableWrapper}
            />
          </ConfigProvider>
          {authOrNot && (transformDataForDiagramm.length !== 0 || transformDataForDiagramaSector.length !== 0
          ) && (
            <div ref={diagramRef}>
              <DiagramTableColumn
                  table={transformDataForDiagramm}
                  tableSector={transformDataForDiagramaSector}
              />
            </div>
          )}
        </div>
      )}
    </>
  );
}

export default TableNew;
