import styles from "./top-main-title.module.css"
import GreenGradient from "../../../../../../../images/Ellipse-products-green-left-unity-world.svg"
import WhiteGradient from "../../../../../../../images/Ellipse-products-white.svg"
import UnityImg from "../../../../../../../images/main-page-man-mind-product-img.svg"
import TicketImg from "../../../../../../../images/ticket-promocode-img.svg"
import CopyTxtIconGreen from "../../../../../../../images/copy-button-icon-green.svg"
import { useMediaQuery } from "react-responsive"
import { NavLink } from "react-router-dom"

const TopMainTitle = () => {
  const isMobile = useMediaQuery({ query: "(max-width: 760px)" })

  return (
    <section className={styles.section}>
      <div className={styles.backgroundWrapper}>
        <img src={GreenGradient} className={styles.backgroundRed} />
        <img src={WhiteGradient} className={styles.backgroundWhite} />
      </div>

      <div className={styles.containerWrapper}>
        <div className={styles.container}>
          <div className={styles.headTitle}>Unity World</div>
          <div className={styles.subTitle}>
            <span style={{ "border-bottom": "1px solid #fff" }}>
              Индивидуально
            </span>{" "}
            помогаем каждому {!isMobile && <br />}подписчику увеличить
            доходность
          </div>
          {isMobile && <img src={UnityImg} className={styles.img} />}
          {/*
          <div className={styles.promocodSectionWrapper}>
            <div className={styles.textPromocodeWrapper}>
              <img src={TicketImg} className={styles.TicketImg} />
              <div className={styles.textPromocode}>
                Первый месяц бесплатно с промокодом:
              </div>
            </div>
            <div className={styles.promocodeBoxWrapper}>
              <div className={styles.promocodeTxt}>FFF AAA FFF</div>
              <img src={CopyTxtIconGreen} className={styles.CopyTxtIconGreen} />
            </div>
            </div>
          */}
          <NavLink to={"/profile"} className={styles.buttonSubscribe}>
            Оформить подписку
          </NavLink>
        </div>
        {!isMobile && <img src={UnityImg} className={styles.img} />}
      </div>
    </section>
  )
}

export default TopMainTitle
