import styles from './card.module.css';
import { NavLink } from 'react-router-dom';
import { useSelector } from 'react-redux';

function Card({ item, oneEl }) {
  return (
    <div className={oneEl.length === 1 ? styles.justOneCard : styles.card}>
      <div className={styles.wrapper}>
        <ul className={styles.ul}>
          <li className={styles.li}>
            {' '}
            {item.analysis && (
              <span className={styles.sectorTextParse} key={item.name}>
                Разбор
              </span>
            )}{' '}
          </li>
          {item.subscription ? (
            <span className={styles.subscription}>{`С подпиской ${item.subscription}`}</span>
          ) : null}
          <li className={styles.li}>
            {item.country_tag.length === 1
              ? item.country_tag.map((item) => (
                  <span className={styles.countryText} key={item.name}>
                    {item.name}
                  </span>
                ))
              : null}
          </li>
          <li className={styles.li}>
            {item.sub_sector.map(
              (item) =>
                item.name.length > 0 && (
                  <span className={styles.sectorText} key={item.name}>
                    {item.name}
                  </span>
                ),
            )}
          </li>
          {item.bonds && (
            <li className={styles.li}>
              <span className={styles.sectorText} key={item.name}>
                Облигации
              </span>
            </li>
          )}
        </ul>
        <NavLink to={`/analytics-and-news/${item.slug}`} className={styles.link}>
          <div className={styles.container}>
            <div className={styles.box}>
              <img alt="banner" src={item.banner} className={styles.image} />
              {/*
            <div className={styles.overlay} />
            */}
              {/*<div className={styles.image}></div>*/}
              <div className={styles.textBox}>
                <span className={styles.data}>{item.pub_date_formated}</span>

                <h3 className={styles.h3}>{item.name}</h3>
                {/*<div dangerouslySetInnerHTML={{__html: element.text}}></div> textThreeLine*/}
                <span
                  className={
                    item.name.length <= 30
                      ? styles.textFourLine
                      : item.name.length <= 60
                      ? styles.textThreeLine
                      : styles.text
                  }>
                  {item.description}
                </span>
              </div>
            </div>
          </div>
        </NavLink>
      </div>
    </div>
  );
}

export default Card;
