import React from 'react';
import { useLocation } from 'react-router-dom';
import {Helmet} from "react-helmet-async";

export const MetaTagsForPagesWithGetParams = () => {
    const location = useLocation();

    return (
        location.search && (
            <Helmet>
                <meta name="robots" content="noindex, nofollow" />
                <link rel="canonical" href={window.location.href.replace(location.search, '')}/>
            </Helmet>
        )
    );
};
