import React from "react"
import { Helmet } from "react-helmet-async"
import favicon from "../images/favicon.ico"

export default function SEO({ title, description, ogTitle, ogDescription, script, breadTitle, slug}) {
  return (
    <Helmet>
      <title>{title}</title>
      <meta name="description" content={description} />
      <meta property="og:title" content={ogTitle} />
      <meta property="og:url" content={window.location.href} />
      <meta property="og:image" content={favicon} />
      <meta
        property="og:description"
        content={ogDescription}
      />
      {!!script ? script(breadTitle, slug) : null}
    </Helmet>
  )
}
