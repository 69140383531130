// import {useEffect, useState} from "react";
import styles from "./webinars.module.css"
import { NavLink } from "react-router-dom"
import { useWebinar } from "../../../services/context/WebinarContext"
import { useCallback, useEffect, useMemo, useState } from "react"
import WebinarBox from "./WebinarBox/WebinarBox.jsx/webinarbox"
import WebinarsSubPanel from "./subPanel/webinarsSubPanel"

function Webinars() {
  const val = useWebinar()

  useEffect(() => {
    val.getWebinarDataApi()
  }, [])
  const webinarsNames = () => Object.keys(val.webinarsData.webinars)
// console.log(val.webinarsData);

  return (
    <>
      <section className={styles.section}>
        <div className={styles.linkWrapper}>
          <NavLink to={"/profile"} className={styles.link}>
            <div className={styles.arrow}></div>
            Профиль
          </NavLink>
        </div>

        <div className={styles.containerWrapper}>
          <div className={styles.container}>
            <div className={styles.mainTitle}>Вебинары</div>
            <div className={styles.scrollWrapper}>
            <div className={styles.tabsWrapper}>
              {val.webinarsData &&
                webinarsNames().map((el, i) => {
                  return (
                      <div
                        className={ val.tabNumber === i ? styles.tabWrapActive : styles.tabWrap }
                        onClick={() => {
                          val.setTabNumber(i)
                          val.setTabName(el)
                        }}
                      >
                        <div className={styles.tabTitle}>{el}</div>
                      </div>
                  )
                })}
            </div>
            </div>

            {val.tabName ? <div className={styles.webinarsWrapper}>
                {val.webinarsData.webinars[val.tabName].map((el) => {
                return <WebinarBox data={el} />})}
            </div> : <WebinarsSubPanel/>}
            </div>


        </div>
      </section>
    </>
  )
}

export default Webinars
