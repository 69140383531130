import styles from './custom-tooltip.module.css'
import {indicatorValue} from "../../utilsForFinanceTab";

export const CustomTooltip = ({active, payload, label, ticker}) => {

    if (active && payload && payload.length) {
        return (
            <div className={styles.tooltip}>
                <p className={styles.label}>{label}</p>
                {payload.map((data, index) => {
                    return <div key={index} className={styles.indicatorContainer}>
                        <span className={styles.indicator}>
                        <span className={styles.circle} style={{backgroundColor: data.color}}></span>
                            {data.name.includes('Compare') && ticker ? `${ticker}:` : 'SBER:'} {indicatorValue(data.name)}
                         </span>
                        <span>{data.value}</span>
                    </div>
                }

                )}
            </div>
        );
    }

    return null;
};