import styles from './sub-box-profile.module.css';

const SubscriptionBoxOwn = ({
  subInfo,
  setShowWarningCancelModal,
  setShowModal,
  setSubSelect,
  setSubNameSelect,
}) => {
  console.log(subInfo.tags);
  return (
    <>
      <div className={styles.containerWrapper}>
          <div className={styles.title}>{subInfo.name}</div>
          {!subInfo.canceled && (
            <div style={{ 'margin-bottom': '4px' }} className={styles.subTitle16px}>
              {subInfo.next_payment_date} спишется {subInfo.next_payment_amount} ₽
            </div>
          )}
          {subInfo.canceled && (
            <div style={{ 'margin-bottom': '4px' }} className={styles.subTitle16px}>
              {subInfo.next_payment_date} подписка закончится
            </div>
          )}
          <div className={styles.dataArrayWrapper}>
            {subInfo.tags.map((el) => {
              return (
                <div className={styles.dataPoint} key={el}>
                  {el}
                </div>
              );
            })}
          </div>
        <div className={styles.bottomContainer}>
          {/*
        <div className={styles.buttonWrapper} onClick={() => {}}>
          <div className={styles.buttonTxt}>
            Подробнее о подписке
          </div>
        </div>
        */}
          {!subInfo.canceled && (
            <div
              className={styles.buttonWrapper}
              onClick={() => {
                setShowWarningCancelModal(subInfo);
              }}>
              <div className={styles.buttonTxt}>Отменить продление</div>
            </div>
          )}
          <div
            className={styles.buttonWrapper}
            onClick={() => {
              setShowModal(true);
              setSubSelect(subInfo.slug);
              setSubNameSelect(subInfo.name);
            }}>
            <div className={styles.buttonTxt}>Докупить от {subInfo.payment_amount} ₽</div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SubscriptionBoxOwn;
