import React, {useEffect, useRef, useState} from "react";
import {ResponsiveContainer, Tooltip, Treemap} from "recharts";
import {zoom as d3Zoom} from 'd3-zoom';
import {select} from 'd3';
import {getMapMarket} from "../../utils/api";
import {formatNumberValue, heightForMobileTreemap, heightForTreemap, transformData} from "./utilsForMarketMap";
import {FiltersForMarketMap} from "./filters/filters-for-market-map";
import {CustomTooltip} from "./custom-tooltip/custom-tooltip";
import Preloader from "../preloader/preloader";
import {useResize} from "../../services/hooks/useResize";
import {CustomizedContentTest} from "./customized-content/customized-content-test";
import MainSectionTopFifteen from "../main-section-top-fifteen/main-section-topFifteen";
import {useTheme} from "../../services/hooks/useTheme";
import styles from './market-map.module.css';

export const MarketMap = () => {
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState([]);
    const [isFullscreen, setIsFullscreen] = useState(false);
    const [isInitialized, setIsInitialized] = useState(false);
    const svgRef = useRef(null);
    const zoomRef = useRef(null);
    const screenWidth = useResize()
    const {theme} = useTheme();

    const filtersRange = [
        {id: '1d', name: 'день'},
        {id: '1w', name: 'неделю'},
        {id: '1m', name: 'месяц'},
        {id: '3m', name: '3 месяца'},
        {id: '6m', name: '6 месяцев'},
        {id: '1y', name: '1 год'}
    ];
    const filtersIndex = [
        {id: 'iMOEX', name: 'iMOEX'},
        {id: 'iMOEX+IE', name: 'iMOEX + выбор Invest Era'},
        {id: '1%2C2', name: '1-2 эшелоны'},
        {id: '3', name: '3 эшелон'},
        {id: 'all', name: 'все'},
    ];
    const filtersSector = [
        {id: 'all', name: 'все'},
        {id: 'Энергетический', name: 'энергетический'},
        {id: 'Финансовый', name: 'финансовый'},
        {id: 'Добывающий', name: 'добывающий'},
        {id: 'IT', name: 'it'},
        {id: 'Коммуникации и развлечения', name: 'коммуникации и развлечения'},
        {id: 'REIT', name: 'reit'},
        {id: 'Потребительский защитный', name: 'потребительский защитный'},
        {id: 'Коммунальный', name: 'коммунальный'},
        {id: 'Здравоохранение', name: 'здравоохранение'},
        {id: 'Промышленный', name: 'промышленный'},
        {id: 'Потребительский цикличный', name: 'потребительский цикличный'},
    ];
    const [selectedRange, setSelectedRange] = useState(filtersRange[5]);
    const [selectedIndex, setSelectedIndex] = useState(filtersIndex[0]);
    const [selectedSector, setSelectedSector] = useState(filtersSector[0]);

    const svgImgColorForTheme = () => {
        const color = theme === 'app_light_theme' ? '#047337' : '#37D881';
        if(isFullscreen) {

            return (
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M3.4 22L2 20.6L8.6 14H4V12H12V20H10V15.4L3.4 22ZM12 12V4H14V8.6L20.6 2L22 3.4L15.4 10H20V12H12Z"
                        fill={color}/>
                </svg>
            )
        } else {
            return (
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M3 21V16H5V19H8V21H3ZM16 21V19H19V16H21V21H16ZM3 8V3H8V5H5V8H3ZM19 8V5H16V3H21V8H19Z"
                          fill={color}/>
                </svg>
            )
        }
    }

    // Стягивает гет (квери) параметры из урла и обновляет стейты выбранных фильтров
    useEffect(() => {
        const searchParams = new URLSearchParams(window.location.search);
        const paramsObject = {};
        for (let [key, value] of searchParams.entries()) {
            if (key === 'sector') {
                paramsObject[key] = formatNumberValue(value);
            } else {
                paramsObject[key] = value;
            }
        }

        if (paramsObject.range) {
            setSelectedRange(filtersRange.find(item => item.id === paramsObject.range));
        }
        if (paramsObject.index) {
            setSelectedIndex(filtersIndex.find(item => item.id === paramsObject.index));
        }
        if (paramsObject.sector) {
            setSelectedSector(filtersSector.find(item => item.id === paramsObject.sector));
        }
        setIsInitialized(true);
    }, []);

    // Запрос данных
    useEffect(() => {
        if (isInitialized) {
            setLoading(true)
            const index = selectedIndex.id === 'iMOEX' || selectedIndex.id === 'iMOEX+IE' ? 'all' : selectedIndex.id
            const iMOEX = selectedIndex.id === 'iMOEX' || selectedIndex.id === 'iMOEX+IE'
            const iMOEXplusIE = selectedIndex.id === 'iMOEX+IE'
            getMapMarket(selectedRange.id, iMOEXplusIE, iMOEX, index, selectedSector.id).then(async (res) => {
                if (res.ok) {
                    setLoading(false)
                    const data = await res.json()
                    setData(transformData(data.sectors))
                } else {
                    setLoading(false)
                    console.log(res)
                }
            })
        }

    }, [selectedRange, selectedSector, selectedIndex, isInitialized]);

    // zoom-эффект
    useEffect(() => {
        if (!loading && data.length > 0 && screenWidth > 1024) {
            const svg = select(svgRef.current);
            const container = svg.node().parentNode;
            const {height} = container.getBoundingClientRect();

            const zoomBehavior = d3Zoom()
                .scaleExtent([1, 2.5])
                .translateExtent([[0, 0], [svgRef.current.clientWidth, height]])
                .on('zoom', (event) => {
                    const {transform} = event;

                    const tWidth = svgRef.current.clientWidth * transform.k;
                    const tHeight = height * transform.k;

                    const minX = Math.min(0, svgRef.current.clientWidth - tWidth);
                    const maxX = 0;
                    const x = Math.min(Math.max(transform.x, minX), maxX);

                    const minY = Math.min(0, height - tHeight);
                    const maxY = 0;
                    const y = Math.min(Math.max(transform.y, minY), maxY);

                    svg.select('g')
                        .attr('transform', `translate(${x},${y}) scale(${transform.k})`);

                });

            zoomRef.current = zoomBehavior;
            svg.call(zoomBehavior);
        }

    }, [loading, data, screenWidth]);

    // смещение скролла к блокам по нажатию на кнопки
    const marketMapRef = useRef(null);
    const topFifteenRef = useRef(null);
    const [activeButton, setActiveButton] = useState('marketMap');

    const scrollToMarketMap = () => {
        marketMapRef.current.scrollIntoView({behavior: 'smooth'});
    }

    const scrollToTopFifteen = () => {
        topFifteenRef.current.scrollIntoView({behavior: 'smooth'});
    }

    // Автоматическое переключение активной кнопки скролла (карта рынка или топ роста / падения) при достижении 30% видимости блока
    useEffect(() => {
        const observer = new IntersectionObserver(
            (entries) => {
                entries.forEach((entry) => {
                    if (entry.isIntersecting) {
                        if (entry.target === marketMapRef.current) {
                            setActiveButton('marketMap');
                        } else if (entry.target === topFifteenRef.current) {
                            setActiveButton('topFifteen');
                        }
                    }
                });
            },
            {threshold: 0.3}
        );

        if (marketMapRef.current) {
            observer.observe(marketMapRef.current);
        }
        if (topFifteenRef.current) {
            observer.observe(topFifteenRef.current);
        }

        return () => {
            if (marketMapRef.current) {
                observer.unobserve(marketMapRef.current);
            }
            if (topFifteenRef.current) {
                observer.unobserve(topFifteenRef.current);
            }
        };
    }, [data]);

    return (
        <div className={styles.marketMapContainer}>
            <div className={`${styles.marketMapHeader} ${isFullscreen && styles.collapseFullscreen}`}>
                <h1>Карта рынка</h1>
            </div>

            <div className={styles.scrollBtnsContainer}
                 style={{
                     position: 'sticky',
                     top: 0,
                     zIndex: 2,
                 }}
            >
                <button onClick={scrollToMarketMap}
                        className={`${styles.scrollBtn} ${activeButton === 'marketMap' ? styles.activeScrollBtn : ''}`}
                >Карта рынка
                </button>
                <button onClick={scrollToTopFifteen}
                        className={`${styles.scrollBtn} ${activeButton === 'topFifteen' ? styles.activeScrollBtn : ''}`}
                >Топ роста / падения
                </button>
            </div>

            <div className={styles.marketMapWrapper} ref={marketMapRef}>
                <div className={styles.filters}>
                    <FiltersForMarketMap filtersRange={filtersRange} selectedRange={selectedRange}
                                         setSelectedRange={setSelectedRange} filtersIndex={filtersIndex}
                                         selectedIndex={selectedIndex} setSelectedIndex={setSelectedIndex}
                                         filtersSector={filtersSector} selectedSector={selectedSector}
                                         setSelectedSector={setSelectedSector} loading={loading}
                    />
                    {screenWidth > 1024 &&
                        <div className={styles.screenMap} onClick={() => setIsFullscreen(!isFullscreen)}>
                            {svgImgColorForTheme()}
                            {isFullscreen ? 'Свернуть полный экран' : 'Открыть во весь экран'}
                        </div>}
                </div>

                <div style={{width: '100%'}} className={styles.treemapContainer}>
                    {data.length > 0 && !loading ? (
                        <div style={{
                            position: 'relative',
                            width: '100%',
                            maxWidth: `${screenWidth}px`,
                            height: '100%',
                            display: 'flex',
                            flexDirection: 'column',
                            gap: '50px',
                            marginBottom: `${screenWidth <= 940 && '50px'}`
                        }}
                        >
                            {screenWidth > 1440 ? <ResponsiveContainer
                                    className={isFullscreen ? styles.fullscreen : styles.collapseFullscreen}
                                    height={heightForTreemap(data)} ref={svgRef}>

                                    <Treemap
                                        data={data}
                                        dataKey="size"
                                        stroke="#FFFFFF"
                                        content={<CustomizedContentTest/>}
                                        aspectRatio={0.9}
                                        isAnimationActive={false}
                                    >
                                        <Tooltip
                                            content={<CustomTooltip/>}
                                            allowEscapeViewBox={{x: false, y: false}}
                                            animationDuration={300}
                                        />
                                    </Treemap>
                                </ResponsiveContainer>
                                : (
                                    <>
                                        {data.map((sector, index) => {
                                            return <ResponsiveContainer key={index}
                                                                        className={isFullscreen ? styles.fullscreen : styles.collapseFullscreen}
                                                                        height={heightForMobileTreemap(sector)}
                                                                        ref={svgRef}
                                            >
                                                <div className={styles.sectorHeader}>{sector.name.toLowerCase()}</div>
                                                <Treemap
                                                    data={[sector]}
                                                    dataKey="size"
                                                    stroke="#FFFFFF"
                                                    content={<CustomizedContentTest/>}
                                                    aspectRatio={1}
                                                    isAnimationActive={false}
                                                >
                                                    <Tooltip
                                                        content={<CustomTooltip/>}
                                                        allowEscapeViewBox={{x: false, y: true}}
                                                        animationDuration={0}
                                                        animationEasing={'linear'}
                                                    />
                                                </Treemap>

                                            </ResponsiveContainer>
                                        })}
                                    </>
                                )
                            }
                        </div>
                    ) : <div className={styles.preloaderContainer}>
                        <Preloader/>
                        Скоро все загрузится. Пожалуйста, подождите
                    </div>}
                </div>
            </div>
            <div className={styles.topFifteenContaienr} ref={topFifteenRef}>
                <MainSectionTopFifteen/>
            </div>
        </div>
    );
}
