import styles from "./app-authorization-page.module.css"
import Gradient from "../../images/background-green-ellipse.svg"
import LoginPage from "../pages/login-page/login-page"
import ActivationPage from "../pages/activation-page/activation-page"
import ReauthenticationAlert from "../pages/reauthentication-alert/reauthentication-alert"
import { useDispatch, useSelector } from "react-redux"
import { NavLink, useNavigate, useParams } from "react-router-dom"
import { useEffect} from "react"
import { isClickEnter } from "../../services/reducers/modal-slice"

function AuthorizationPage() {

  // document.body.style.backgroundColor = '#172339';
  const dispatch = useDispatch()
  const navigation = useNavigate()
  const token = localStorage.getItem("token")

  useEffect(() => {
      // console.log('!!!');
      if (!!token) {navigation("/profile")}
  },[token])

  const clickEnter = useSelector((store) => store.modal.clickEnter)
  // const isOpenModal = useSelector((store) => store.modal.isOpen)
  const isUserDelete = useSelector((store) => store.modal.userDelete)
  useEffect(() => {
    dispatch(isClickEnter(false))
    // localStorage.setItem("location", "AuthorizationPage")

  },[])
  // console.log(clickEnter);

  return (
    <>
    <section className={styles.section} >
        <div className={styles.backgroundWrapper} >
          <img src={Gradient} className={styles.background}/>
        </div>
        <div className={styles.containerWrapper}>
            
            
            <div className={styles.container}>
                  <div className={styles.underButton}>
                  <NavLink to={"/"} className={styles.link}>
                    <div className={styles.arrow}/>
                    На главную
                  </NavLink>
                  </div>

                  
              {(isUserDelete && <ReauthenticationAlert />) ||
                (clickEnter && <ActivationPage />) ||
                (<LoginPage />)}
            

            </div>
        </div>
    </section>
    </>
  )
}

export default AuthorizationPage

