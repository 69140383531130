import { NavLink, useNavigate, useParams } from "react-router-dom"
import { useEffect, useMemo, useState } from "react"
import styles from "./subscription-page-info.module.css"
import { useProfile } from "../../../../../services/context/ProfileContext"
import TopMainTitle from "./premium-sections/top-main-title/top-main-title"
import GraphSliderGallery from "./premium-sections/graph-slider-gallery/graph-slider-gallery"
import FirstDescription from "./premium-sections/first-description-product/first-description-product"
import SecondFlagsDescription from "./premium-sections/second-flags-description-product/second-flags-description-product"
import EasyStart from "./premium-sections/easy-start/easy-start-product"
import SubscriptionPrice from "./premium-sections/subscription-price-section/subscription-price"
import PromotionPromocode from "./premium-sections/promotion-promocode/promotion-promocode"
import Review from "./premium-sections/reviews/reviews"
import GalleryTablesProduct from "./premium-sections/gallery-tables/gallery-tables"
import SEO from "../../../../../utils/SEO"
function SeoScript() {
  return (
    <script type="application/ld+json">
{`{
  "@context": "https://schema.org",
  "@type": "Product",
  "description": "Следуйте нашим торговым сигналам и обгоняйте индекс",
  "name": "Premium",
  "image": "_.img",
  "offers": {
    "@type": "Offer",
    "price": "7500.00",
    "priceCurrency": "RUB"
  }
}`}
</script>
  )
}

const Premium = () => {
  const valueContext = useProfile()
  const { slug } = useParams()
  const navigate = useNavigate()

  useMemo(() => {
    // valueContext.pageFromSlugUrl(slug)
  }, [slug])

  // const card = valueContext.urlPageData
  return (
    <>
    <SEO
        title="Premium - подписка от Invest Era"
        description="Премиальный сервис, индивидуальный портфель и личный менеджер"
        ogTitle="Premium - подписка от Invest Era"
        ogDescription="Премиальный сервис, индивидуальный портфель и личный менеджер"
        script={SeoScript}
      />
        <TopMainTitle />
        <GalleryTablesProduct/>
        <FirstDescription/>
        <SecondFlagsDescription/>
        <EasyStart/>
        <SubscriptionPrice />
        {/*
        <PromotionPromocode/>
        */}
        <Review/>
    </>
  )
}

export default Premium
