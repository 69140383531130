import {createSlice} from "@reduxjs/toolkit";

const initialState = {
    object: [],
    themesCards: [],
    lessonsCards: [],
    cardTheme: {},
    lesson: {},
    themesSlug: '',
}

const cardsSlice = createSlice({
    name: 'cards',
    initialState,
    reducers: {
        setObject: (state, action) => {
            state.object = action.payload
        },
        setThemesCards: (state, action) => {
            state.themesCards = action.payload

        },
        setLessonsCards: (state, action) => {
            state.lessonsCards = action.payload

        },
        setCardTheme: (state, action) => {
            state.cardTheme = action.payload
        },
        setLesson: (state, action) => {
            state.lesson = action.payload
        },
        setThemesSlug: (state, action) => {
            state.themesSlug = action.payload
        },
    }
})

export const {
    setObject,
    setThemesCards,
    setLessonsCards,
    setCardTheme,
    setLesson,
    setThemesSlug,
} = cardsSlice.actions

export default cardsSlice.reducer;