import React, { useEffect, useState } from 'react';
import FractionDiagram from "../FractionDiagram/FractionDiagram";
import SectorDiagram from "../SectorDiagram/SectorDiagram";


import styles from './table-colum-diagram.module.css';

const DiagramTableColumn = ({ table, tableSector }) => {

  return (
    <div className={styles.diagramWrapper}>
      {table.length !== 0 && <FractionDiagram table={table} />}
      {tableSector.length !== 0 && <SectorDiagram table={tableSector} />}
    </div>
  );
};

export default DiagramTableColumn;
