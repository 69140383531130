import React from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import styles from './two-level-menu.module.css';

//выпадающая менюшка для "Обучение"
export const TwoLevelMenu = ({ open, data, closeModal }) => {
  return (
    <div className={`${styles.twoLevelMenu} ${open ? styles.open : ''}`}>
      <div className={styles.wrapper}>
        <div className={styles.wrapIetm}>
          <h2 className={styles.title}>{data.begin.title1}</h2>
          <ul className={styles.list}>
            {data.begin.beginnerData.map((element) => (
              <li onClick={closeModal} className={styles.item} key={element.id}>
                <Link className={styles.link} to={element.navigate}>
                  {element.name}
                </Link>
              </li>
            ))}
          </ul>
        </div>
        <div className={styles.wrapIetm}>
          <h2 className={styles.title}>{data.expert.title2}</h2>
          <ul className={styles.list}>
            {data.expert.exprertData.map((element) => (
              <li onClick={closeModal} className={styles.item} key={element.id}>
                <Link className={styles.link} to={element.navigate}>
                  {element.name}
                </Link>
              </li>
            ))}
          </ul>
        </div>
        <div className={styles.wrapIetm}>
          <h2 className={styles.title}>{data.advanced.title3}</h2>
          <ul className={styles.list}>
            {data.advanced.advancedData.map((element) => (
              <li onClick={closeModal} className={styles.item} key={element.id}>
                <Link className={styles.link} to={element.navigate}>
                  {element.name}
                </Link>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};

export const TwoLevelMenuOther = ({ open, data, closeModal, clazz }) => {

  return (
    <div
        style={{background:'#fff'}}
      className={`${styles.twoLevelMenu1} ${open ? styles.open1 : ''} ${
        clazz ? styles.clazz : ''
      }`}>
      <ul className={styles.list1}>
        {data?.map((element) => (
          <li onClick={closeModal} className={styles.title} key={element.id}>
            <Link className={styles.link1} to={`/tables/${element.navigate}`}>
              {element.name}
            </Link>
          </li>
        ))}
      </ul>
    </div>
  );
};

export const TwoLevelMenuOtherProfile = ({ open, data, closeModal, clazz }) => {
  return (
    <div
        style={{background:'#fff'}}
      className={`${styles.twoLevelMenu1} ${open ? styles.open1 : ''} ${
        clazz ? styles.clazz : ''
      }`}>
      <div className={styles.wrapper1}>
        <div className={styles.wrapIetm}>
          <ul className={styles.list1}>
            {data.map((element) => (
              <li onClick={closeModal} className={styles.title} key={element.id}>
                <Link className={styles.link1} to={`/${element.navigate}`}>
                  {element.name}
                </Link>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};
