import styles from "../pop-up-styles.module.css"
import closeXSign from "../../../../images/close-x-sign-svg.svg"
import successImg from "../../../../images/subscription-succes-img.svg"
import { useNavigate } from "react-router-dom"
import { useProfile } from "../../../../services/context/ProfileContext"
import { useCallback, useEffect } from "react"
const SubscriptionSuccess = () => {
  const navigate = useNavigate()
  const contextValue = useProfile()
  const formateSubscription = (sub) => {
    const subVal = sub.map((el) => {

        if (el.subscription === "Unity AllCategories") {
            const newEl = [el, {subscription: "Unity Россия"}, {subscription: "Unity World"}]
            return newEl
        } else if (el.subscription === "Ideas AllCategories") {
            const newEl = [el, {subscription: "Ideas Россия"}, {subscription: "Ideas World"}]
            return newEl
        } else if (el.subscription === "Premium") {
          const newEl = [el, {subscription: "Unity AllCategories"}, {subscription: "Ideas AllCategories"}, {subscription: "Ideas Россия"}, {subscription: "Ideas World"}, {subscription: "Unity Россия"}, {subscription: "Unity World"}]
          return newEl
      }  else { return el}
    })

    return subVal.flat()
}
useEffect(() => {
  const subscriptionsUser = contextValue.userSubInfo.map((el) => {
    if (el.is_active) {return {subscription: el.name}} else {return null}
  }).filter(el => el !== null)
  // console.log(formateSubscription(subscriptionsUser));

  const storageObj = JSON.stringify(formateSubscription(subscriptionsUser))
  localStorage.setItem("subscription", storageObj)
}, [])

  const handleTargetClick = useCallback((event) => {
    if (event.target === event.currentTarget) {
      // Обработчик вызывается только если событие произошло на родительском элементе
      contextValue.setPopUpActive(prev => !prev)
      navigate("/profile")

    }
  })
  const handleClick = useCallback(() => {
      contextValue.setPopUpActive(prev => !prev)
      navigate("/profile")
  })
  return (
    <div className={styles.containerWrapper} onClick={handleTargetClick}>
      <div className={styles.container}>
        <img src={closeXSign} className={styles.closeImg} onClick={handleTargetClick}/>

        <div className={styles.topBody}>

          <div className={styles.topTxtBody}>
          <img src={successImg} className={styles.successImg}/>

            Подписка подключена
          </div>
          {/*
          <div className={styles.buttonTxtBody}>
          13.02.2025 произойдет автоматическое продление подписки. Спишется 12 000 ₽ с карты *2562
          </div>
          */}
          <div className={styles.buttonTxtBody}>
          В течение 5 минут вам на email придет письмо с доступами в Telegram.
          </div>
          <div className={styles.buttonTxtBody}>
          Если письмо не пришло — проверьте папку спам
          </div>
        </div>

      <div className={styles.bottomBody}>

      <div className={styles.bottomBody}>
      <div className={styles.buttonWrapper} onClick={handleClick}>
        <div className={styles.buttonTxt}>
          Хорошо
        </div>
      </div>
      {/*
      <div className={styles.buttonWrapper}>
        <div className={styles.buttonTxt}>
          Открыть чек
        </div>
      </div>
      */}
    </div>
    </div>
      </div>
    </div>
  )
}
export default SubscriptionSuccess
