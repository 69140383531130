import {Link} from "react-router-dom";
import styles from "./search-list-item.module.css";

export const SearchListItem = ({title, ticker, sector}) => {

    return (
        <Link to={`/screener/${ticker}`} className={styles.container} onMouseDown={(e) => e.preventDefault()}>
            <div className={styles.info}>
                <h4 className={styles.title}>{title}</h4>
                <span className={styles.descContainer}>
                    <span>{ticker}</span> • <span>{sector}</span>
                </span>
            </div>
        </Link>
    );
};