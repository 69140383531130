import { NavLink } from "react-router-dom"
import styles from "./webinarsSubPanel.module.css"

function webinarsSubPanel() {
  
  return (
      <div className={styles.notification}>
        <div className={styles.backgroundImage}></div>
        <div className={styles.textBox}>
          <p className={styles.notifTitle}>Вебинары доступны с подпиской</p>
          <p className={styles.notifText}>
            Оформите подписку и получите доступ 
          </p>
        </div>
        <NavLink
          to={"/profile"}
          className={styles.buttonLink}
        >
          Оформить подписку
        </NavLink>
      </div>
  )
}

export default webinarsSubPanel
