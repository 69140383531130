import styles from "./easy-start-product.module.css"
import { useMediaQuery } from "react-responsive"
import RusFlag from "../../../../../../../images/product-circle-flag-russia.svg"
import Laptop from "../../../../../../../images/product-table-image.svg"
const FirstDescription = () => {
  const isMobile = useMediaQuery({ query: "(max-width: 760px)" })
  const isIpad = useMediaQuery({ query: "(max-width: 1280px)" })

  return (
    <div className={styles.section}>
      <div className={styles.containerWrapper}>
        <div className={styles.container}>
          <div className={styles.title}>
            Модельные портфели, обгоняющие индекс
          </div>
          <div className={styles.subTitleWrapper}>
            <div className={styles.subTitle}>Точки входа и целевые цены</div>
            <div className={styles.subTitle}>
              Прогнозы по мультипликаторам и дивидендам
            </div>
          </div>
            {/*
            <div className={styles.flagSectionWrapper}>
              <div className={styles.flagSection}>
                <img src={RusFlag} className={styles.RusFlagImg} />
                <div className={styles.contextWrapper}>
                  <div className={styles.contextTitle}>РФ долгосрочный</div>
                  <div className={styles.contextSubTitle}>
                    Обгоняем рынок за счет грамотного выбора активов
                  </div>
                </div>
              </div>
              <div className={styles.percentSection}>
                <div className={styles.contextSubTitle}>
                  <div>Обгоняет индекс {!isIpad ? <br/> : null} за июнь 2022 — апрель 2024 </div>
                  <div className={styles.percentWrapper}>
                    на <div className={styles.percent}>52,3%</div>
                  </div>
                </div>
              </div>
            </div>
            */}


            <div className={styles.flagSectionWrapper}>
              <div className={styles.flagSection}>
                <img src={RusFlag} className={styles.RusFlagImg} />
                <div className={styles.contextWrapper}>
                  <div className={styles.contextTitle}>РФ долгосрочный</div>
                  <div className={styles.contextSubTitle}>
                  Обгоняем рынок за счет грамотного выбора активов
                  </div>
                </div>
              </div>
              <div className={styles.percentSection}>
                <div className={styles.contextSubTitle}>
                Обгоняет индекс{!isIpad ? <br/> : null}
                за июнь 2022 — апрель 2024
                </div>
                <div className={styles.percentWrapper}>
                  на <div className={styles.percent}>52,3%</div>
                </div>
              </div>
            </div>






          <div className={styles.laptopImgWrapper}>
            <img src={Laptop} className={styles.LaptopImg} />
          </div>
        </div>
      </div>
    </div>
  )
}

export default FirstDescription
