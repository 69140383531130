import styles from './filterSection.module.css';
import { useMediaQuery } from 'react-responsive';
import closeXSign from '../../images/close-x-sign-svg.svg';
import { setClearCards } from '../../services/reducers/cards-filtred-for-gallery-slice';
import { useDispatch } from 'react-redux';
import checkBoxEmpty from '../../images/check-box-empty.svg';
import checkBoxFull from '../../images/check-box-full.svg';
import {
  setCategoryPoints,
  setClearCompanyPoints,
} from '../../services/reducers/cards-filtred-slice';
import {
  setSelectCategoryPoint,
  setCheckBoxChoice,
  setClearSelectCompanyPoint,
} from '../../services/reducers/search-vision-points-slice';
import { useActiveFilters } from '../../services/context/ActiveFiltersContext';

const FilterSection = ({
  categoryPoints,
  selectCategoryPoint,
  setActivTypeMenu,
  checkBoxChoice,
  selectCompanyPoint,
}) => {
  const dispatch = useDispatch();
  const filters = useActiveFilters();
  // console.log(categoryPoints);

  const funSetCheckBoxChoice = (type) => {
    let index = null;
    const finder = checkBoxChoice.filter((el, i) => {
      if (el.id === type.id) {
        index = i;
      }
      return el.id === type.id;
    });
    if (finder.length !== 0) {
      // const numberToDelete = checkBoxChoice[index]
      // const filteredNumbers = checkBoxChoice.filter(
      //   (number) => number !== numberToDelete
      // )
      const reFreshCheckBox = checkBoxChoice.filter((el) => {
        const currentId = el.id;

        return !selectCompanyPoint.find((s) => s.id === currentId) && el.id !== type.id;
      });

      dispatch(setCheckBoxChoice(reFreshCheckBox));
    } else {
      const val = [...checkBoxChoice, type];
      dispatch(setCheckBoxChoice(val));
    }
  };

  const funSetSelectCategoryPoint = (type) => {
    let index = null;
    const finder = selectCategoryPoint.filter((el, i) => {
      //   // если finder нашел повторение его длинна увеличится
      if (el.id === type.id) {
        index = i;
      }
      return el.id === type.id;
    });

    if (finder.length !== 0) {
      const numberToDelete = selectCategoryPoint[index];
      const filteredNumbers = selectCategoryPoint.filter((number) => number !== numberToDelete);

      const reFreshCheckBox = checkBoxChoice.filter((el) => {
        const currentId = el.id;

        return !selectCompanyPoint.find((s) => s.id === currentId) && el.id !== type.id;
      });
      dispatch(setSelectCategoryPoint(filteredNumbers)); // для нижнего чек бокса
      dispatch(setCategoryPoints(filteredNumbers)); // для галлереи
      dispatch(setCheckBoxChoice(reFreshCheckBox));
      dispatch(setClearCompanyPoints());
      dispatch(setClearSelectCompanyPoint());
      //   setSelectCompanyPoint([])
    } else {
      const val = [...selectCategoryPoint, type];
      dispatch(setSelectCategoryPoint(val)); // для нижнего чек бокса
      dispatch(setCategoryPoints(val)); // для галлереи
    }
  };

  // const funClearCards = () => {
  //   // dispatch(setClearCards())
  // }
  // const funPageCount = () => filters.showMoreCardsBtn(1)

  const isMobile = useMediaQuery({ query: '(max-width: 760px)' });

  return (
    <div className={styles.containerWrapper}>
      <div className={styles.container}>
        {isMobile && <div className={styles.mobileFilterTxt}>Тип</div>}
        <div className={styles.mapButtonWrapper}>
          <div className={styles.mapButton}>
            {categoryPoints.map((el) => (
              <div
                className={styles.button}
                key={el.id}
                onClick={() => {
                  // funClearCards()
                  filters.setPageCount(1);
                  funSetSelectCategoryPoint(el);
                  funSetCheckBoxChoice(el);
                  // funSetCheckBoxChoice(el)
                  // funPageCount()
                  // funSetUpData()
                }}>
                <div className={styles.checkWrapper}>
                  {!!selectCategoryPoint.find((point) => point.id === el.id) === true && (
                    <img key={el.id} src={checkBoxFull} className={styles.img} />
                  )}
                  {!!selectCategoryPoint.find((point) => point.id === el.id) === false && (
                    <img key={el.id} src={checkBoxEmpty} className={styles.img} />
                  )}
                </div>
                <div className={styles.txt}>{el.name}</div>
              </div>
            ))}
          </div>
        </div>

        {isMobile && (
          <div className={styles.aceptButtonWrapper}>
            <div
              className={styles.acceptButtonActive}
              onClick={() => {
                setActivTypeMenu(false);
              }}>
              Применить
            </div>
          </div>
        )}
        {isMobile && (
          <div className={styles.closeImg}>
            <img
              src={closeXSign}
              onClick={() => {
                setActivTypeMenu(false);
              }}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default FilterSection;
