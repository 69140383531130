import styles from "./second-flags-description-product.module.css"
import { useMediaQuery } from "react-responsive"
import Laptop from "../../../../../../../images/product-macbook-image-cursore.svg"
import Cursor from "../../../../../../../images/clarity_cursor-hand-line.svg"
import BGElipse from "../../../../../../../images/Ellipse-products-red-flag-section.svg"
import { NavLink } from "react-router-dom"

const SecondDescription = () => {
  const isMobile = useMediaQuery({ query: "(max-width: 760px)" })

  return (
    <div className={styles.section}>
      <div className={styles.containerWrapper}>
        <img src={BGElipse} className={styles.background} />
        <div className={styles.container}>
          <div className={styles.title}>
            Научитесь инвестировать самостоятельно
          </div>
          <div className={styles.subTitle}>
            Курс из 60 тем для разных уровней подготовки. Все, что нужно для
            успешного инвестирования.{" "}
            <NavLink to={"/education"} className={styles.link}>
              {" "}
              Посмотреть пример
            </NavLink>
          </div>
          <div className={styles.laptopWrapper}>
            <div className={styles.laptop}>
              <img src={Laptop} className={styles.LaptopImg} />
              <img src={Cursor} className={styles.CursorImg} />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default SecondDescription
