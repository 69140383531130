import styles from "./preloader-auth.module.css";


function Preloader() {
    return (
        <div className={styles.loaderWrapper}>
            <div className={styles.loader}/>
        </div>
    )
}

export default Preloader