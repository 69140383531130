import React, {useEffect, useState} from 'react';
import {Outlet as OutletMain, useLocation} from 'react-router-dom';
import AppHeaderMain from '../app-header-main/app-header-main';
import FooterMain from '../footer-main/footer-main';
import ScrollToTop from '../sroll-to-top/sroll-to-top';
import {useActiveButtonCountryTagChoice} from '../../services/context/CountryFilterContext';
import PopUpSection from '../pop-up-for-filtres/PopUpSection';
import PopUpForExitSection from '../pop-up-for-exit/PopUpForExitSection';
import PopUpCookie from '../pop-up-cookie/PopUpCookie';
import {MetaTagsForPagesWithGetParams} from "../../utils/metaTagsForPagesWithGetParams";
import {PopUpForSafari} from "./pop-up-for-safari/pop-up-for-safari";
import StocksAllPage from '../stocks/stock-all-pages/StocksAllPage';
import styles from './layout-main.module.css';
import {useTheme} from "../../services/hooks/useTheme";
// import {PopUpTimeOutTables} from '../pop-up-timeout/pop-up-timeout-tables/PopUpTimeOutTables';

function LayoutMain() {
    const location = useLocation();
    const value = useActiveButtonCountryTagChoice();
    const [isActivePopUp, setIsActivePopUp] = useState(false);
    const {theme} = useTheme();
    // const [showTimeoutPopup, setShowTimeoutPopup] = useState(false);

    const PopUpSectionToggle = () => {
        if (
            (localStorage.getItem('country_tag') === null ||
                localStorage.getItem('country_tag') === 'undefined') &&
            (location.pathname === '/analytics-and-news/' || location.pathname === '/analytics-and-news')
        ) {
            value.toggleVisible(true);
        }
    };

/*    useEffect(() => {
      const timer = setTimeout(() => {
        setShowTimeoutPopup(true);
      }, 1000);
      return () => clearTimeout(timer);
    }, []);*/

    const handleClosePopUp = () => {
        setIsActivePopUp(false);
        localStorage.setItem('isSafariUserPopUpNotified', 'true');
    };

    // Вычисляет используется ли сафари, если да, то проверяет был ли уведомлён пользователь
    useEffect(() => {
        const userAgent = window.navigator.userAgent.toLowerCase();
        const isSafariBrowser = /safari/.test(userAgent) && !/chrome/.test(userAgent);
        const isPopUpNotified = localStorage.getItem('isSafariUserPopUpNotified');

        if (isSafariBrowser && (!isPopUpNotified || isPopUpNotified === 'false')) {
            setIsActivePopUp(true);
        }

    }, []);

    return (
        <>
            {/*Компонент, добавляющий метатеги страницам с гет-параметрами*/}
            <MetaTagsForPagesWithGetParams/>

            {/*{PopUpSectionToggle()}*/}
            {value.visible && <PopUpSection/>}
            {value.popUpExitVision && <PopUpForExitSection/>}
            {value.popUpCookieVision && localStorage.getItem('cookie') === null && <PopUpCookie/>}

            {/* {showTimeoutPopup && <PopUpTimeOutTables />} */}
            <div className={`${styles.page} ${isActivePopUp && styles.pageMarginForPopUp}`}>
                <ScrollToTop>

                    {/*Компонент, добавляющий popUp на страницу, если используется safari*/}
                    {isActivePopUp && <PopUpForSafari handleClosePopUp={handleClosePopUp}/>}

                    <StocksAllPage/>
                    <AppHeaderMain/>

                    <main className={`${styles.outlet} ${theme}`}>
                        <OutletMain/>
                    </main>

                    <FooterMain/>
                </ScrollToTop>
            </div>
        </>
    );
}

export default LayoutMain;
