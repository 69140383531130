import { NavLink, useNavigate, useParams } from "react-router-dom"
import { useEffect, useMemo, useState } from "react"
import styles from "./product-sub-url.module.css"
import { Helmet } from "react-helmet"
import UnityRussiaSubscriptionPage from "./subscription-page-info/unity-russia"
import UnityWorldSubscriptionPage from "./subscription-page-info/unity-world"
import UnityAllSubscriptionPage from "./subscription-page-info/unity-all"
import IdeasRussiaSubscriptionPage from "./subscription-page-info/ideas-russia"
import IdeasWorldSubscriptionPage from "./subscription-page-info/ideas-world"
import IdeasAllSubscriptionPage from "./subscription-page-info/ideas-all"
import Premium from "./subscription-page-info/premium"

const ProductSub = () => {
  const { slug } = useParams()
  const navigate = useNavigate()

  useMemo(() => {
    // valueContext.pageFromSlugUrl(slug)
  }, [slug])

  // const card = valueContext.urlPageData

  const pageTuggle = () => {
    if (slug === "unity-russia") {return <UnityRussiaSubscriptionPage/>}
    if (slug === "unity-world") {return <UnityWorldSubscriptionPage/>}
    if (slug === "unity-all") {return <UnityAllSubscriptionPage/>}
    if (slug === "ideas-russia") {return <IdeasRussiaSubscriptionPage/>}
    if (slug === "ideas-world") {return <IdeasWorldSubscriptionPage/>}
    if (slug === "ideas-all") {return <IdeasAllSubscriptionPage/>}
    if (slug === "premium") {return <Premium/>}

  }
  // console.log(slug);
  return (
    <>
      <section className={styles.section}>
        
            
              {pageTuggle()}
              {/*
              <SubscriptionPage-Subscription-Page/>
              <UnityWorld-Subscription-Page/>
              <UnityAll-Subscription-Page/>
              <IdeasRussia-Subscription-Page/>
              <IdeasWorldSubscriptionPage-Subscription-Page/>
              <IdeasAllSubscriptionPage-Subscription-Page/>
              <Premium-Subscription-Page/>

              */}
      </section>
    </>
  )
}

export default ProductSub
