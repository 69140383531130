import styles from './education.module.css'
import EducationInfoSection from "../../education-info-section/education-info-section";
import EducationCardsSection from "../../education-cards-section/education-cards-section";
import SEO from '../../../utils/SEO';


function Education() {

    return (
        <div>
            <div className={styles.info}>
                <SEO
                    title="Обучение инвестициям | Курсы для начинающих и продвинутых инвесторов от Invest Era"
                    description="Обучение финансам и инвестициям от Invest Era. Онлайн курсы по инвестициям с нуля. Более 60 тем для начинающих, продолжающих и продвинутых инвесторов"
                    ogTitle="Обучение инвестициям | Курсы для начинающих и продвинутых инвесторов от Invest Era"
                    ogDescription="Обучение финансам и инвестициям от Invest Era. Онлайн курсы по инвестициям с нуля. Более 60 тем для начинающих, продолжающих и продвинутых инвесторов"
                />
                <EducationInfoSection/>
            </div>
            <div className={styles.cardsSection}>
                <EducationCardsSection/>
            </div>

        </div>
    )
}

export default Education