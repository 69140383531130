import {NavLink} from "react-router-dom";
import premiumCrown from '../../../../images/premium-crown-dark.svg'
import styles from './our-products-for-about-card.module.css'
import {useResize} from "../../../../services/hooks/useResize";

export const OurProductsForAboutCard = () => {
    const screenWidth = useResize()

    return (
        <div className={styles.productsContainer}>
            <h3 className={styles.productsHeader}>Наши продукты</h3>
            <div className={styles.productsWrapper}>
                <div className={styles.productContainer}>
                    <div className={styles.productInfo}>
                        <h4 className={styles.productHeader}>Unity</h4>
                        {screenWidth > 760 ? <p className={styles.productDesc}>Научим самостоятельно инвестировать и&nbsp;поможем&nbsp;повысить доходность</p>
                            : <p className={styles.productDesc}>Научим самостоятельно инвестировать&nbsp;и&nbsp;поможем повысить&nbsp;доходность</p>}
                    </div>
                    <div className={styles.linksContainer}>
                        <NavLink to={'/products/unity-russia'}>Российский рынок</NavLink>
                        <NavLink to={'/products/unity-world'}>Иностранные рынки</NavLink>
                        <NavLink to={'/products/unity-all'}>Все рынки</NavLink>
                    </div>
                </div>

                <div className={styles.productContainer}>
                    <div className={styles.productInfo}>
                        <h4 className={styles.productHeader}>Ideas</h4>
                        {screenWidth > 760 ? <p className={styles.productDesc}>Следуйте нашим торговым&nbsp;сигналам и&nbsp;обгоняйте индекс</p>
                            : <p className={styles.productDesc}>Следуйте нашим торговым сигналам&nbsp;и&nbsp;обгоняйте индекс</p>}
                    </div>
                    <div className={styles.linksContainer}>
                        <NavLink to={'/products/ideas-russia'}>Российский рынок</NavLink>
                        <NavLink to={'/products/ideas-world'}>Иностранные рынки</NavLink>
                        <NavLink to={'/products/ideas-all'}>Все рынки</NavLink>
                    </div>
                </div>

                <div className={styles.productContainer}>
                    <div className={styles.productInfo}>
                        <h4 className={styles.productHeader}>
                            Premium
                            <img src={premiumCrown} alt="premium icon" width={24} height={22}/>
                        </h4>
                        {screenWidth > 760 ? <p className={styles.productDesc}>Премиальный сервис, индивидуальный портфель, личный менеджер</p>
                            : <p className={styles.productDesc}>Премиальный сервис, индивидуальный портфель, личный&nbsp;менеджер</p> }
                    </div>
                    <div className={styles.linksContainer}>
                        <NavLink to={'/products/premium'}>Подробнее</NavLink>
                    </div>
                </div>
            </div>
        </div>
    );
};