import {createSlice} from "@reduxjs/toolkit";

const initialState = {
    profileData: {},
}

const subscriptionDataSlice = createSlice({
    name: 'userData',
    initialState,
    reducers: {
        setUserProfileData: (state, action) => {
            state.profileData = action.payload
        },

    }
})

export const {
    setUserProfileData,
} = subscriptionDataSlice.actions

export default subscriptionDataSlice.reducer;