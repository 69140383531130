import {createSlice} from "@reduxjs/toolkit";

const initialState = {
//   mainNewCards: []
    filtredCards: [],
    extentionCards: [],
    loadedPages: [],
}

const cardsFiltredSliceForGallery = createSlice({
    name: 'filtredCardsForGallery',
    initialState,
    reducers: {
        setFiltredCards: (state, action) => {
                state.filtredCards = action.payload
                // state.filtredCards = action.payload
        },
        setMoreCards: (state, action) => {        
            if (state.loadedPages[0] === 1 && state.loadedPages.length === 1) { // 1
                
                state.extentionCards = state.filtredCards
            } else {
                state.extentionCards.push(...action.payload)
                
            }
        },
        setLoadedPages: (state, action) => {
            const arr = state.loadedPages // []

            const uniqPages = (pageNum) => {
                arr.push(pageNum)
                return [...new Set(arr)]
            }
            state.loadedPages = uniqPages(action.payload)
        },
        setClearCards: (state) => {
            state.extentionCards = []
            state.loadedPages = [1]
        }
    }

})

export const {
    setFiltredCards,
    setMoreCards,
    setLoadedPages,
    setClearCards
} = cardsFiltredSliceForGallery.actions

export default cardsFiltredSliceForGallery.reducer;