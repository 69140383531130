import {createSlice} from "@reduxjs/toolkit";

const initialState = {
    recommendedPosts: [],
}

const recommendedPostsSlice = createSlice({
    name: 'recommendedPosts',
    initialState,
    reducers: {
        setRecommendedPosts: (state, action) => {
            state.recommendedPosts = action.payload
        }
    }
})

export const {
    setRecommendedPosts
} = recommendedPostsSlice.actions

export default recommendedPostsSlice.reducer;