import { Link } from 'react-router-dom';
import TGgreen from '../../images/tg_green.svg';
import Youtubegreen from '../../images/youtube_green.svg';
import Crown from '../../images/crown.svg';
import Russia from '../../images/flag-icon-russia.svg';
import Earth from '../../images/product-circle-flag-earth.svg';
import BGUP from '../../images/bg-ecosystem-up.png';
import BGDW from '../../images/bg-ecosystem-down.png';
import Left from '../../images/left.svg';
import Right from '../../images/right.svg';
import Middle from '../../images/mobile-middle.svg';

import styles from './main-section-ecosystem.module.css';
const MainSectionEcosystem = () => {
  return (
    <div className={styles.MainSectionEcosystem}>
      <img className={styles.bgUp} src={BGUP} alt="bg" />
      <img className={styles.bgDw} src={BGDW} alt="bg" />
      <div className={styles.wrapperEcoSystem}>
        <div className={styles.line}></div>
        <div className={styles.wrapper}>
          <div className={styles.left}>
            <div className={styles.title}>Россия</div>
            <div className={styles.desc}>
              <a className={styles.link} href="https://t.me/+Avs2bsQ5mQk4N2My">
                <img className={styles.icon} src={TGgreen} alt="telegram" />
                Канал Invest Era
              </a>
            </div>
          </div>
          <div className={styles.right}>
            <div className={`${styles.title} ${styles.title2} `}>Иностранные рынки</div>
            <div className={styles.desc}>
              <a className={styles.link} href="https://t.me/+lz2L1DcJ2a0wMWMy">
                <img className={styles.icon} src={TGgreen} alt="telegram" />
                Канал Era Global
              </a>
            </div>
          </div>
        </div>

        <div className={styles.wrapperGrid}>
          <div className={styles.itemGrid}>
            <img src={Left} alt="leftArrow" />
          </div>
          <div className={styles.itemGrid}>
            Unity <span>Индивидуально помогаем каждому подписчику увеличить доходность</span>
            <img className={styles.middleArrow} src={Middle} alt="middleArrow" />
          </div>
          <div className={styles.itemGrid}>
            <img src={Right} alt="rightArrow" />
          </div>
          <Link
            to="/products/unity-russia?utm_source=ecosystem"
            className={styles.itemGrid}>
            Россия
            <img className={styles.gridImg} src={Russia} alt="russia" />
          </Link>
          <Link
            to="/products/unity-all?utm_source=ecosystem"
            className={styles.itemGrid}>
            All
          </Link>
          <Link
            to="/products/unity-world?utm_source=ecosystem"
            className={styles.itemGrid}>
            World
            <img className={styles.gridImg} src={Earth} alt="earth" />
          </Link>
        </div>

        <div className={styles.wrapperGrid}>
          <div className={styles.itemGrid}>
            <img src={Left} alt="leftArrow" />
          </div>
          <div className={styles.itemGrid}>
            Ideas <span>Следуйте нашим торговым сигналам и обгоняйте индекс</span>
            <img className={styles.middleArrow} src={Middle} alt="middleArrow" />
          </div>
          <div className={styles.itemGrid}>
            <img src={Right} alt="rightArrow" />
          </div>
          <Link
            to="/products/ideas-russia?utm_source=ecosystem"
            className={styles.itemGrid}>
            Россия
            <img className={styles.gridImg} src={Russia} alt="russia" />
          </Link>
          <Link
            to="/products/ideas-all?utm_source=ecosystem"
            className={styles.itemGrid}>
            All
          </Link>
          <Link
            to="/products/ideas-world?utm_source=ecosystem"
            className={styles.itemGrid}>
            World
            <img className={styles.gridImg} src={Earth} alt="earth" />
          </Link>
        </div>

        <Link
          to="/products/premium?utm_source=ecosystem"
          className={styles.premium}>
          <img className={styles.crown} src={Crown} alt="premium" />
          Premium
        </Link>

        <div className={styles.footer}>
          <a className={styles.link} href="https://t.me/+3D5QfZD9A5AyYTUy">
            <img className={styles.icon} src={TGgreen} alt="telegram" />
            Era Media
          </a>
          <a className={styles.link} href="https://www.youtube.com/@investera3816">
            <img className={styles.icon} src={Youtubegreen} alt="youtube" />
            YouTube
          </a>
          <a className={styles.link} href="https://t.me/+7yLBrc0to89hYjky">
            <img className={styles.icon} src={TGgreen} alt="telegram" />
            Invest Era Чат
          </a>
        </div>
      </div>
    </div>
  );
};

export default MainSectionEcosystem;
