import { useCallback, useEffect, useRef, useState } from 'react';
import Close from '../../../images/close-x-sign-svg.svg';
import BG from '../../../images/popUpemailBg.png';
import BGMobile from '../../../images/popUpemailBgMobile.png';
import styles from './pop-up-timeout-email.module.css';
import { useInput } from '../../../services/hooks/useInput';
import { useActiveFilters } from '../../../services/context/ActiveFiltersContext';
import { useLocation, useNavigate } from 'react-router-dom';
import SuccessImg from '../../../images/subscription-succes-img.svg';
import { useMediaQuery } from 'react-responsive';
import classNames from 'classnames';

export const PopUpTimeOutEmail = ({ setShowTimeoutPopupThird }) => {
  const modalRef = useRef(null);
  const isMobile = useMediaQuery({ query: '(max-width: 760px)' });
  const { bind, val } = useInput('');
  const value = useActiveFilters();
  const [bntClick, setBntClick] = useState(false);
  const [buttonActive, setButtonActive] = useState(false);
  useEffect(() => {
    if (val.length !== 0) {
      value.setMailSubscription(val);
    }
  }, [buttonActive]);

  useEffect(() => {
    value.setCheckMAil(false);
  }, [val]);

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      setButtonActive((prev) => !prev);
    }
  };
  const handleClickOutside = (e) => {
    if (modalRef.current && !modalRef.current.contains(e.target)) {
      setShowTimeoutPopupThird(false);
      window.ym(96742836, 'reachGoal', 'popup-email-close');
    }
  };

  const closePopupForImgCLick = () => {
    setShowTimeoutPopupThird(false);
    window.ym(96742836, 'reachGoal', 'popup-email-close');
  };

  const clickForBtn = () => {
    if (val.length === 0) {
      setBntClick(true);
    }
    setButtonActive((prev) => !prev);
    window.ym(96742836, 'reachGoal', 'popup-email-lead');
  };
  return (
    <div onClick={handleClickOutside} className={styles.wrapper}>
      <div className={styles.popup} ref={modalRef}>
        {value.mailСompleted ? (
          <div className={styles.completedContainer}>
            <div onClick={closePopupForImgCLick} className={styles.close}>
              <img src={Close} alt="close" />
            </div>
            <img alt="success" src={SuccessImg} className={styles.successImg} />
            <div className={classNames(styles.title)}>Спасибо за подписку</div>
            <div className={classNames(styles.subTitle)}>
              Отправим подборку на {val}
            </div>
          </div>
        ) : (
          <>
            <div onClick={closePopupForImgCLick} className={styles.close}>
              <img src={Close} alt="close" />
            </div>
            <div className={styles.container}>
              <h2 className={styles.title}>
                Получай лучшую аналитику на почту
              </h2>
              <h3 className={styles.subTitle}>
                Каждую неделю присылаем подборку лучших статей и важнейших
                событий
              </h3>

              <input
                onKeyPress={handleKeyPress}
                {...bind}
                className={`${styles.input} ${value.checkMAil && styles.error}`}
                type="email"
                placeholder="Адрес электронной почты"
              />
              {value.checkMAil || bntClick ? (
                <div className={styles.warning}>
                  Неправильно введен адрес электронной почты
                </div>
              ) : null}
              <button onClick={clickForBtn} className={styles.btn}>
                ПОЛУЧАТЬ РАССЫЛКУ
              </button>
            </div>
            <img
              className={styles.bg}
              src={isMobile ? BGMobile : BG}
              alt="bg"
            />
          </>
        )}
      </div>
    </div>
  );
};
