import {useCallback, useEffect, useRef, useState} from "react";
import {FilterList} from "./filterList/filterList";
import styles from "./filters-for-market-map.module.css";

export const FiltersForMarketMap = ({
                                        filtersRange,
                                        selectedRange,
                                        setSelectedRange,
                                        filtersIndex,
                                        selectedIndex,
                                        setSelectedIndex,
                                        filtersSector,
                                        selectedSector,
                                        setSelectedSector,
                                        loading
                                    }) => {
    const [activeRangeMenu, setActiveRangeMenu] = useState(false)
    const [activeIndexMenu, setActiveIndexMenu] = useState(false)
    const [activeSectorMenu, setActiveSectorMenu] = useState(false)
    const rangeBoxRef = useRef(null);
    const indexBoxRef = useRef(null);
    const sectorBoxRef = useRef(null);

    // Функция для открывания/закрывания выпадающих списков фильтров
    const singleOpener = useCallback((filterName) => {
        switch (filterName) {
            case "setActiveTypeMenu":
                setActiveRangeMenu((prev) => !prev)
                setActiveIndexMenu(false)
                setActiveSectorMenu(false)
                break

            case "setActiveIndexMenu":
                setActiveIndexMenu((prev) => !prev)
                setActiveRangeMenu(false)
                setActiveSectorMenu(false)
                break

            case "setActiveSectorMenu":
                setActiveSectorMenu((prev) => !prev)
                setActiveRangeMenu(false)
                setActiveIndexMenu(false)
                break
        }
    }, [])

    // Функция, закрывающая выпадающий список фильтров по клику вне списка
    const handleClickOutside = (event) => {
        if (rangeBoxRef.current && !rangeBoxRef.current.contains(event.target)) {
            setActiveRangeMenu(false);
        }
        if (indexBoxRef.current && !indexBoxRef.current.contains(event.target)) {
            setActiveIndexMenu(false);
        }
        if (sectorBoxRef.current && !sectorBoxRef.current.contains(event.target)) {
            setActiveSectorMenu(false);
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    return (
        <div className={styles.filters}>
            <FilterList loading={loading} singleOpener={singleOpener} valueForSingleOpener={'setActiveTypeMenu'}
                        selectedValue={selectedRange} activeMenu={activeRangeMenu} setActiveMenu={setActiveRangeMenu}
                        setSelectedValue={setSelectedRange} categoryPoints={filtersRange} typeValue={'range'}
                        buttonName={'Динамика за'} ref={rangeBoxRef}
            />
            <FilterList loading={loading} singleOpener={singleOpener} valueForSingleOpener={'setActiveIndexMenu'}
                        selectedValue={selectedIndex} activeMenu={activeIndexMenu}
                        setActiveMenu={setActiveIndexMenu}
                        setSelectedValue={setSelectedIndex} categoryPoints={filtersIndex} typeValue={'index'}
                        buttonName={'Индекс'} ref={indexBoxRef}
            />
            <FilterList loading={loading} singleOpener={singleOpener} valueForSingleOpener={'setActiveSectorMenu'}
                        selectedValue={selectedSector} activeMenu={activeSectorMenu} setActiveMenu={setActiveSectorMenu}
                        setSelectedValue={setSelectedSector} categoryPoints={filtersSector} typeValue={'sector'}
                        buttonName={'Сектор'} ref={sectorBoxRef}
            />
        </div>
    );
};