import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import axios from 'axios';

export const getNavigateBlogPage = createAsyncThunk(
    'blog/getInformationBlogPage',
    async () => {
        const res = await axios(`/api/blog/?limit=1`);
        return res?.data
    },
)

export const getDataForMarksBlogPage = createAsyncThunk(
    'blog/getDataForMarksBlogPage',
    async ({marks, currentPage, limit}) => {
        const transformMarks = marks === 'Все' ? '' : marks
        const res = await axios(`/api/blog/?limit=4&page=${currentPage}&special_mark=${transformMarks}`)
        return { count: res?.data?.count, results: res?.data?.results };
    },
)

const initialState = {
    blogDataForNavigate: [],
    blogDataForMark: [],
    blogDataForMarkCount: null,
    loading: false,
    isFetching: false,
    error: false,
    currentPage: 1,
}

const blogSlice = createSlice({
    name: 'blog',
    initialState,
    reducers: {
        toggleIsFetching: (state, action) => {
            state.isFetching = action.payload;
        },
        setCurrentPage: (state, action) => {
            state.currentPage = action.payload;
        },
        clearState: (state) => {
            state.blogDataForMark = [];
            state.blogDataForMarkCount = null;
            state.loading = false;
            state.isFetching = false;
            state.error = false;
            state.currentPage = 1;
        },
        clearNavigateData: (state) => {
            state.blogDataForNavigate = [];
        }
    },
    extraReducers: (builder) => {
        builder.addCase(getNavigateBlogPage.pending, (state) => {
            state.loading = true;
        })
        builder.addCase(getNavigateBlogPage.fulfilled, (state, action) => {
            state.blogDataForNavigate = action.payload;
            state.loading = false;
        })
        builder.addCase(getNavigateBlogPage.rejected, (state) => {
            state.error = true;
            state.loading = false;
        })

        builder.addCase(getDataForMarksBlogPage.pending, (state) => {
            state.loading = true;
        })
        builder.addCase(getDataForMarksBlogPage.fulfilled, (state, action) => {
            state.blogDataForMarkCount = action.payload.count;
            state.blogDataForMark.push(...action.payload.results);
            state.loading = false;
        })
        builder.addCase(getDataForMarksBlogPage.rejected, (state) => {
            state.error = true;
            state.loading = false;
        })
    },
})

export const {actions: blogActions} = blogSlice;
export const {reducer: blogReducer} = blogSlice;
