import React, {useRef} from "react";
import {
    conditionFontSizePercents,
    conditionFontSizeTicker,
    conditionLineHeightPercents,
    conditionLineHeightTicker,
    getBackgroundColor,
} from "../utilsForMarketMap";
import {useResize} from "../../../services/hooks/useResize";
import styles from './customized-content-test.module.css'

export const CustomizedContentTest = (props) => {
    const {root, depth, x, y, width, height, name, value, priceChange, ticker, sectorName} = props;
    const divRef = useRef(null);
    const screenWidth = useResize();

    const headerFontSize = () => {
        if(screenWidth > 1440 ) return '14px'
        if(screenWidth <= 1440 && screenWidth > 540) return '12px'
        if(screenWidth <= 540) return '10px'
    }
    const headerBackgroundSize = () => {
        if(screenWidth > 1440 ) return 20
        if(screenWidth <= 1440 && screenWidth > 540) return 18
        if(screenWidth <= 540) return 14
    }
    const headerMargin = () => {
        if(screenWidth > 1440 ) return 14
        if(screenWidth <= 1440 && screenWidth > 540) return 12
        if(screenWidth <= 540) return 10
    }

    const textLengthCondition = () => {
        if(screenWidth > 1440) {
            return root.width < 250 && sectorName.length > 15 ? root.width - 10 : null
        } else {
            return root.width < 150 && sectorName.length > 15 ? root.width - 10 : null
        }
    }

    const lengthAdjustCondition = () => {
        if(screenWidth > 1440) {
            return root.width < 250 && sectorName.length > 15 ? "spacingAndGlyphs" : null
        } else {
            return root.width < 150 && sectorName.length > 15 ? "spacingAndGlyphs" : null
        }
    }

    return (
        <g>
            <rect
                x={x}
                y={y}
                width={width}
                height={height}
                style={{
                    position: 'relative',
                    fill: depth > 1 ? getBackgroundColor(priceChange) : '#ffffff00',
                    strokeWidth: depth === 1 ? (screenWidth > 1024 ? 14 : 8) : 1,
                }}
                className={styles.rect}
            />

            {sectorName && screenWidth > 1440 && <rect
                x={root.x + 1}
                y={root.y}
                width={root.width}
                height={headerBackgroundSize()}
                fill="#172339"
                strokeWidth={0}
            />}

            {sectorName && screenWidth > 1440 &&<text
                x={root.x + 3}
                y={root.y + headerMargin()}
                className={styles.headerBlock}
                style={{fontSize: headerFontSize()}}
                textLength={textLengthCondition()}
                lengthAdjust={lengthAdjustCondition()}
            >
                {sectorName}
            </text>}

            {depth > 1 && (
                <foreignObject
                    x={x}
                    y={y}
                    width={width}
                    height={height}
                >
                    <div
                        ref={divRef}
                        className={styles.blockName}
                        style={{
                            fontSize: conditionFontSizeTicker(value, screenWidth, root.children.length, root.index),
                            lineHeight: conditionLineHeightTicker(value, screenWidth, root.children.length, root.index),
                        }}
                    >
                        {width > 30 && height > 40 && ticker}
                        {width > 30 && height > 40  && (
                            <span
                                style={{
                                    fontSize: conditionFontSizePercents(value, screenWidth, root.children.length, root.index),
                                    lineHeight: conditionLineHeightPercents(value, screenWidth, root.children.length, root.index),
                                    fontWeight: 500,
                                }}
                            >
                                {priceChange && priceChange.toFixed(2)}%
                            </span>
                        )}
                    </div>
                </foreignObject>
            )}
        </g>
    )
};