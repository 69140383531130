import styles from './window-pay.module.css';
import successImg from '../../../images/subscription-succes-img.svg';
import CloseX from '../../../images/close-black-icon.svg';
import emptyCircle from '../../../images/empty-circle.svg';
import fullCircle from '../../../images/full-black-green-circle.svg';
import Stocks from '../../stocks/stock-profile/Stocks';
// import successImg from "../../../images/subscription-succes-img.svg"

import { useCallback, useMemo, useState } from 'react';
import { useProfile } from '../../../services/context/ProfileContext';
// import { useParams, useNavigate, Redirect } from "react-router-dom"
import { useInput } from './hookUseInput';
import Preloader from './window-pay-preloader/preloader-pay';
import { useMediaQuery } from 'react-responsive';

const WindowPaySub = ({ setShowExteriorNotice, setShowModal, subSelect, subNameSelect }) => {
  const isMobile = useMediaQuery({ query: '(max-width: 760px)' });
  console.log(setShowExteriorNotice, setShowModal, subSelect, subNameSelect);

  // const navigation = useNavigate()
  const contextValue = useProfile();
  const { bind, val } = useInput('');
  // console.log(val);

  // const [contextValue.receptInfo, setDataState] = useState(false)

  const [optionMonth, setOptionMonth] = useState(0);
  const [promoCodeTuggle, setPromoCodeTuggle] = useState(false);
  const [optionCard, setOptionCard] = useState(1);
  const [loading, setLoading] = useState(false);
  // const [newPricePromocode, setNewPricePromocode] = useState(false)

  const [forApiPromoCode, setForApiPromoCode] = useState(false);

  useMemo(() => {
    contextValue.receptData(subSelect);
  }, []);
  // useCallback(() => {

  // },[loading])

  const objForApi = (id, rur, usd) => {
    const obj = {
      promo_code: val,
      product_id: id,
      price_rur: rur,
      price_usd: usd,
    };
    return obj;
  };

  const objForPayment = useCallback(() => {
    const objPayment = {
      total_price: contextValue.receptInfo[optionMonth].roubles, //10,
      payment_type_id: 1,
      product_id: contextValue.receptInfo[optionMonth].id,
    };
    if (contextValue.totalRubles !== '') {
      objPayment.total_price = contextValue.totalRubles;
      objPayment.promo_code_id = contextValue.promoCodeData.promo_code_id;
    }

    if (contextValue.receptInfo) {
      // console.log(objPayment);
      return objPayment; // !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
    }
    // console.log((contextValue.bonusMonth));
  }, [forApiPromoCode, contextValue.totalRubles]);

  useMemo(() => {
    {
      forApiPromoCode && contextValue.paymentTogglePost(objForPayment());
    }
  }, [loading]);
  // console.log('!!!', forApiPromoCode);

  useMemo(() => {
    if (contextValue.receptInfo) {
      setForApiPromoCode(
        objForApi(
          contextValue.receptInfo[optionMonth].id,
          contextValue.receptInfo[optionMonth].roubles,
          contextValue.receptInfo[optionMonth].dollars,
        ),
      );
    }
  }, [contextValue.receptInfo, optionMonth, val]);

  useMemo(() => {
    {
      forApiPromoCode && contextValue.promoCodeInitialApi(forApiPromoCode);
    }
    // {forApiPromoCode && objForPayment(contextValue.receptInfo[optionMonth].id)}
  }, [promoCodeTuggle]);

  useMemo(() => {
    setPromoCodeTuggle((prev) => !prev);
    contextValue.setTotalRubles('');
    contextValue.setBonusMonth('');
    // {forApiPromoCode && objForPayment(contextValue.receptInfo[optionMonth].id)}
  }, [optionMonth]);

  useMemo(() => {
    contextValue.setTotalRubles('');
    contextValue.setBonusMonth('');
    contextValue.setPromoCodeData(false);
  }, [val]);

  // document.getElementById('parentsClick').addEventListener('click', function(event) {
  //   if (event.target.id === 'parentsClick') {
  //     // Только если событие произошло на родительском элементе
  //     setShowModal(prev => !prev);
  //   }
  // });
  const handleClick = useCallback((event) => {
    if (event.target === event.currentTarget) {
      // Обработчик вызывается только если событие произошло на родительском элементе
      // console.log("Click on parent")
      setShowModal((prev) => !prev);
    }
  }, []);

  // console.log(contextValue.promoCodeData)
  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      val.length !== 0 && setPromoCodeTuggle((prev) => !prev);
    }
  };
  return (
    <div className={styles.containerWrapper} onClick={handleClick} id={'parentsClick'}>
      {contextValue.receptInfo && (
        <div className={styles.container}>
          <div className={styles.headerContent}>
            <div className={styles.titleHeader}>
              {!isMobile && `Подключение подписки ${subNameSelect}`}

              {isMobile && `Подключение  ${subNameSelect}`}
            </div>
            <img alt="img" src={CloseX} style={{ cursor: 'pointer' }} onClick={handleClick} />
          </div>

          {loading ? (
            <Preloader />
          ) : (
            <div className={styles.loadSection}>
              <div className={styles.mainBody}>
                <div className={styles.mainContentWrapper}>
                  <div className={styles.mainContent}>
                    <Stocks />
                    <div className={styles.titleBox}>Выберите период подключения</div>

                    <div className={styles.monthBoxesWrapper}>
                      {contextValue.receptInfo.map((el, index, arr) => (
                        <div
                          key={index}
                          className={
                            optionMonth === index ? styles.monthBoxActive : styles.monthBox
                          }
                          onClick={() => {
                            setOptionMonth(index);
                            objForApi(el.id, el.roubles, el.dollars);
                          }}>
                          {el.discount && (
                            <div className={styles.discount}>{`${el.discount}`}%</div>
                          )}

                          <div className={styles.topTiteleBox}>
                            <div className={styles.monthTitleWrapper}>
                              {optionMonth === index ? (
                                <img alt="img" src={fullCircle} className={styles.emptyCircleImg} />
                              ) : (
                                <img
                                  alt="img"
                                  src={emptyCircle}
                                  className={styles.emptyCircleImg}
                                />
                              )}

                              <div className={styles.monthTitle}>
                                {`${el.month}`} {!isMobile && (el.month > 1 ? 'месяцев' : 'месяц')}{' '}
                                {isMobile && `мес`}
                              </div>
                            </div>

                            <div className={styles.subTitleWrapper}>
                              <div className={styles.titleHeader}>
                                {`${el.roubles_per_month} ₽`}
                              </div>
                              <div className={isMobile ? styles.titleBoxMob : styles.titleBox}>
                                {!isMobile && `/месяц`}
                                {isMobile && `/мес`}
                              </div>
                            </div>
                          </div>

                          {el.roubles_per_month && index !== 0 && (
                            <div className={styles.subTitle}>
                              При оплате за {el.month} месяцев *Далее{' '}
                              {`${arr[0].roubles_per_month} ₽`} в месяц
                            </div>
                          )}
                        </div>
                      ))}
                    </div>
                    {optionMonth !== 0 && (
                      <div className={styles.subTitle14}>
                        *Сумма будет списываться автоматичекси
                      </div>
                    )}
                  </div>
                </div>
                {!isMobile && (
                  <div
                    className={
                      contextValue.promoCodeData && typeof contextValue.promoCodeData !== 'object'
                        ? styles.promoCodeContainerWarning
                        : styles.promoCodeContainer
                    }>
                    <div className={isMobile ? styles.titleBoxMob : styles.titleBox}>Промокод</div>
                    <div className={styles.inputContainerWrapper}>
                      <div className={styles.inputContainer}>
                        <div className={styles.inputWrapper}>
                          <input
                            {...bind}
                            onKeyDown={handleKeyDown}
                            placeholder="Введите промокод"
                            className={
                              contextValue.promoCodeData &&
                              typeof contextValue.promoCodeData !== 'object'
                                ? styles.inputWarning
                                : styles.input
                            }
                          />
                          {contextValue.totalRubles !== '' && (
                            <img alt="img" src={successImg} className={styles.successImg} />
                          )}
                        </div>

                        <div
                          className={
                            val.length !== 0 ? styles.inputButtonActive : styles.inputButton
                          }
                          onClick={() => {
                            val.length !== 0 && setPromoCodeTuggle((prev) => !prev);
                          }}>
                          Применить
                        </div>
                      </div>

                      {contextValue.promoCodeData &&
                        typeof contextValue.promoCodeData !== 'object' && (
                          <div className={styles.subTitle}>{contextValue.promoCodeData}</div>
                        )}
                      {contextValue.totalRubles !== '' && (
                        <div className={styles.subTitle}>Промокод применен</div>
                      )}
                    </div>
                  </div>
                )}

                {isMobile && (
                  <div
                    className={
                      contextValue.promoCodeData && typeof contextValue.promoCodeData !== 'object'
                        ? styles.promoCodeContainerWarning
                        : styles.promoCodeContainer
                    }>
                    <div className={isMobile ? styles.titleBoxMob : styles.titleBox}>Промокод</div>
                    <div className={styles.inputContainerWrapper}>
                      <div className={styles.inputContainer}>
                        <div className={styles.inputWrapper}>
                          <input
                            {...bind}
                            placeholder="Введите промокод"
                            className={
                              contextValue.promoCodeData &&
                              typeof contextValue.promoCodeData !== 'object'
                                ? styles.inputWarning
                                : styles.input
                            }
                          />
                          {contextValue.totalRubles !== '' && (
                            <img alt="img" src={successImg} className={styles.successImg} />
                          )}

                          {contextValue.totalRubles === '' && val.length !== 0 && (
                            <div
                              className={styles.inputButtonMob}
                              onClick={() => {
                                val.length !== 0 && setPromoCodeTuggle((prev) => !prev);
                              }}>
                              Применить
                            </div>
                          )}
                        </div>
                      </div>

                      {contextValue.promoCodeData &&
                        typeof contextValue.promoCodeData !== 'object' && (
                          <div className={styles.subTitle}>{contextValue.promoCodeData}</div>
                        )}
                      {contextValue.totalRubles !== '' && (
                        <div className={styles.subTitle}>Промокод применен</div>
                      )}
                    </div>
                  </div>
                )}

                {/*
          <div className={styles.priceInfoWrapper}>
          */}
                <div
                  className={
                    (!isMobile && styles.priceInfoWrapper) ||
                    (isMobile &&
                      (contextValue.promoCodeData && typeof contextValue.promoCodeData !== 'object'
                        ? styles.priceInfoWrapperMobWarning
                        : styles.priceInfoWrapperMob))
                  }>
                  <div className={styles.titleBox}>Стоимость</div>
                  <div className={styles.priceInfo}>
                    <div style={{ 'margin-bottom': '4px' }} className={styles.priceInfoTxtWrapper}>
                      <div className={styles.monthTitle}>Подписка {subNameSelect}</div>
                      <div className={styles.monthTitle}>
                        {`${contextValue.receptInfo[optionMonth].roubles} ₽`}
                      </div>
                    </div>
                    <div className={styles.priceInfoTxtWrapper}>
                      <div className={styles.graySubTitle}>
                        {optionMonth === 0 && `${contextValue.receptInfo[optionMonth].month} месяц`}
                        {optionMonth > 0 && `${contextValue.receptInfo[optionMonth].month} месяцев`}
                      </div>

                      <div className={styles.graySubTitle}>
                        {`${contextValue.receptInfo[optionMonth].roubles_per_month} ₽`} / месяц
                      </div>
                    </div>

                    {contextValue.bonusMonth === '' && contextValue.totalRubles !== '' && (
                      <div
                        style={{ 'padding-bottom': '13px' }}
                        className={styles.priceInfoTxtWrapper}>
                        <div className={styles.monthTitle}>Скидка по промокоду</div>
                        <div className={styles.monthTitle}>
                          {contextValue.totalRubles !== '' &&
                            contextValue.receptInfo[optionMonth].roubles -
                              contextValue.totalRubles}{' '}
                          ₽
                        </div>
                      </div>
                    )}

                    {contextValue.bonusMonth !== '' && (
                      <div
                        style={{ 'padding-bottom': '13px' }}
                        className={styles.priceInfoTxtWrapper}>
                        <div className={styles.monthTitle}>
                          {contextValue.bonusMonth === 1 &&
                            `+ ${contextValue.bonusMonth} месяц по промокоду`}
                          {contextValue.bonusMonth > 1 &&
                            `+ ${contextValue.bonusMonth} месяца по промокоду`}
                        </div>
                        <div className={styles.monthTitle}>0 ₽</div>
                      </div>
                    )}
                  </div>
                  <div className={styles.priceInfoTxtWrapper}>
                    {contextValue?.promoCodeData?.promo_code_id === 12 ? (
                      <div className={styles.titleHeader}>Итого за 10 дней</div>
                    ) : contextValue.bonusMonth === '' ? (
                      <div className={styles.titleHeader}>
                        {optionMonth === 0 &&
                          `Итого за ${contextValue.receptInfo[optionMonth].month} месяц`}

                        {optionMonth > 0 &&
                          `Итого за ${contextValue.receptInfo[optionMonth].month} месяцев`}
                      </div>
                    ) : (
                      <div className={styles.titleHeader}>
                        {contextValue.receptInfo[optionMonth].month + contextValue.bonusMonth < 5
                          ? `Итого за ${
                              contextValue.receptInfo[optionMonth].month + contextValue.bonusMonth
                            } месяцa`
                          : `Итого за ${
                              contextValue.receptInfo[optionMonth].month + contextValue.bonusMonth
                            } месяцев`}
                      </div>
                    )}

                    {contextValue.totalRubles === contextValue.receptInfo[optionMonth].roubles ||
                    contextValue.totalRubles === '' ? (
                      <div className={styles.titleHeader}>
                        {`${contextValue.receptInfo[optionMonth].roubles} ₽`}
                      </div>
                    ) : (
                      <div
                        className={
                          (!isMobile && styles.titleHeader) ||
                          (isMobile && styles.titleHeaderColumnMob)
                        }>
                        {contextValue.totalRubles} ₽
                        <div className={styles.titleHeaderCross}>
                          {`${contextValue.receptInfo[optionMonth].roubles} ₽`}
                        </div>
                      </div>
                    )}
                  </div>
                </div>

                <div className={styles.methodPayWrapper}>
                  <div className={styles.titleBox}>Способ оплаты</div>
                  <div className={styles.cardSelectWrapper}>
                    <div
                      onClick={() => {
                        setOptionCard(1);
                      }}
                      className={
                        optionCard === 1 ? styles.cardSelectBoxActive : styles.cardSelectBox
                      }>
                      <div className={styles.cardTxtWrapper}>
                        {optionCard === 1 ? (
                          <img
                            alt="fullCircle"
                            src={fullCircle}
                            className={styles.emptyCircleImg}
                          />
                        ) : (
                          <img
                            alt="emptyCircle"
                            src={emptyCircle}
                            className={styles.emptyCircleImg}
                          />
                        )}
                        <div className={styles.monthTitle}>Российской картой</div>
                      </div>
                    </div>

                    <div
                      onClick={() => {
                        setShowExteriorNotice(true);
                      }}
                      className={
                        optionCard === 2 ? styles.cardSelectBoxActive : styles.cardSelectBox
                      }>
                      <div className={styles.cardTxtWrapper}>
                        {optionCard === 2 ? (
                          <img
                            alt="fullCircle"
                            src={fullCircle}
                            className={styles.emptyCircleImg}
                          />
                        ) : (
                          <img
                            alt="emptyCircle"
                            src={emptyCircle}
                            className={styles.emptyCircleImg}
                          />
                        )}
                        <div className={styles.monthTitle}>Зарубежной картой</div>
                      </div>
                    </div>
                  </div>
                  <div className={styles.supportWrapper}>
                    <div className={styles.monthTitle}>
                      Есть вопросы? Обратитесь в{' '}
                      <a href={'https://t.me/ie_contact'} target="_blank">
                        техподдержку
                      </a>
                    </div>
                  </div>
                </div>
              </div>

              <div className={styles.footerContent}>
                {!isMobile ? (
                  contextValue?.promoCodeData?.promo_code_id === 12 ? (
                    <div className={styles.footerTitleWrapper}>
                      <div style={{ color: '#047337' }} className={styles.titleHeader}>
                        К оплате: {`${contextValue.receptInfo[optionMonth].roubles} ₽`}
                      </div>
                      <div className={styles.titleBox}>10 ₽ / 10 дней</div>
                    </div>
                  ) : contextValue.totalRubles === contextValue.receptInfo[optionMonth].roubles ||
                    contextValue.totalRubles === '' ? (
                    <div className={styles.footerTitleWrapper}>
                      <div style={{ color: '#047337' }} className={styles.titleHeader}>
                        К оплате: {`${contextValue.receptInfo[optionMonth].roubles} ₽`}
                      </div>
                      <div className={styles.titleBox}>
                        {`${
                          contextValue.bonusMonth === ''
                            ? Math.ceil(
                                contextValue.receptInfo[optionMonth].roubles /
                                  contextValue.receptInfo[optionMonth].month,
                              )
                            : Math.ceil(
                                contextValue.totalRubles /
                                  (contextValue.receptInfo[optionMonth].month +
                                    contextValue.bonusMonth),
                              )
                        } ₽`}
                        / месяц
                      </div>
                    </div>
                  ) : (
                    <div className={styles.footerTitleWrapper}>
                      <div style={{ color: '#047337' }} className={styles.titleHeader}>
                        {`К оплате: ${contextValue.totalRubles} ₽`}
                        <div style={{ 'padding-left': '8px' }} className={styles.titleHeaderCross}>
                          {`${contextValue.receptInfo[optionMonth].roubles} ₽`}
                        </div>
                      </div>
                      <div className={styles.footerSobPriceWrapper}>
                        <div className={styles.titleBox}>
                          {`${
                            contextValue.totalRubles / contextValue.receptInfo[optionMonth].month
                          } ₽`}
                          / месяц
                        </div>
                        <div className={styles.titleBoxCross}>
                          {`${
                            contextValue.receptInfo[optionMonth].roubles /
                            contextValue.receptInfo[optionMonth].month
                          } ₽`}
                          / месяц
                        </div>
                      </div>
                    </div>
                  )
                ) : null}

                {isMobile ? (
                  contextValue?.promoCodeData?.promo_code_id === 12 ? (
                    <div
                      className={styles.selectPayButton}
                      onClick={() => {
                        setLoading((prev) => !prev);
                      }}>
                      <div className={styles.buttonMonthPayTitleMob}>10 ₽ / 10 дней</div>
                      <div className={styles.buttonPayTitleMob}>
                        К оплате: {contextValue.receptInfo[optionMonth].roubles}₽
                      </div>
                    </div>
                  ) : contextValue.totalRubles === contextValue.receptInfo[optionMonth].roubles ||
                    contextValue.totalRubles === '' ? (
                    <div
                      className={styles.selectPayButton}
                      onClick={() => {
                        setLoading((prev) => !prev);
                      }}>
                      <div className={styles.buttonMonthPayTitleMob}>
                        {contextValue.bonusMonth === ''
                          ? Math.ceil(
                              contextValue.receptInfo[optionMonth].roubles /
                                contextValue.receptInfo[optionMonth].month,
                            )
                          : Math.ceil(
                              contextValue.totalRubles /
                                (contextValue.receptInfo[optionMonth].month +
                                  contextValue.bonusMonth),
                            )}{' '}
                        ₽ / мес
                      </div>
                      <div className={styles.buttonPayTitleMob}>
                        К оплате: {contextValue.receptInfo[optionMonth].roubles}₽
                      </div>
                    </div>
                  ) : (
                    <div
                      className={styles.selectPayButton}
                      onClick={() => {
                        setLoading((prev) => !prev);
                      }}>
                      <div className={styles.buttonMonthPayTitleMob}>
                        {contextValue.totalRubles / contextValue.receptInfo[optionMonth].month} ₽ /
                        мес
                      </div>
                      <div className={styles.buttonPayTitleMob}>
                        К оплате: {contextValue.totalRubles}₽
                      </div>
                    </div>
                  )
                ) : (
                  <div
                    className={styles.selectPayButton}
                    onClick={() => {
                      setLoading((prev) => !prev);
                    }}>
                    Оплатить
                  </div>
                )}
              </div>
              <div className={styles.politicWrapper}>
                <div className={styles.politicTxt}>
                  Нажимая на «Оплатить», я соглашаюсь с условиями{' '}
                  <a href={'https://invest-era.ru/info/politika-konfidentsialnosti'}>
                    Политики конфиденциальности
                  </a>{' '}
                  и{' '}
                  <a
                    href={
                      'https://invest-era.ru/info/dogovor-publichnoj-oferti-na-okazanie-informatsionno-konsultatsionnikh-uslug'
                    }>
                    Публичной оферты
                  </a>
                </div>
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
};
export default WindowPaySub;
