import styles from './sectionCheckBoxPoints.module.css';
import { setClearCards } from '../../services/reducers/cards-filtred-for-gallery-slice';
import { useDispatch } from 'react-redux';
import searchImg from '../../images/search-svg.svg';
import { useCallback, useState } from 'react';
import checkBoxEmpty from '../../images/check-box-empty.svg';
import checkBoxFull from '../../images/check-box-full.svg';
import { useMediaQuery } from 'react-responsive';
import closeXSign from '../../images/close-x-sign-svg.svg';
import {
  setSelectCountryPoint,
  setCheckBoxChoice,
  setClearSelectCompanyPoint,
} from '../../services/reducers/search-vision-points-slice';
import {
  setCountryPoints,
  setClearCompanyPoints,
} from '../../services/reducers/cards-filtred-slice';
import { useActiveFilters } from '../../services/context/ActiveFiltersContext';

const FilterSection = ({
  // setPageCount,
  // setUpData,
  // setSelectCountryPoint,
  selectCountryPoint,
  countryPoints,
  // setCheckBoxChoice,
  checkBoxChoice,
  selectCompanyPoint,
  // setSelectCompanyPoint,
  setActivCountryMenu,
}) => {
  const dispatch = useDispatch();
  const filters = useActiveFilters();

  const isMobile = useMediaQuery({ query: '(max-width: 760px)' });

  const funSetCheckBoxChoice = (oldType) => {
    const newType = (type) => {
      return { id: type.id, name: type.fullname };
    };
    const type = newType(oldType);

    let index = null;
    const finder = checkBoxChoice.filter((el, i) => {
      if (el.id === type.id) {
        index = i;
      }
      return el.id === type.id;
    });
    if (finder.length !== 0) {
      // const numberToDelete = checkBoxChoice[index]
      // const filteredNumbers = checkBoxChoice.filter(
      //   (number) => number !== numberToDelete
      // )
      const reFreshCheckBox = checkBoxChoice.filter((el) => {
        const currentId = el.id;

        return !selectCompanyPoint.find((s) => s.id === currentId) && el.id !== type.id;
      });

      dispatch(setCheckBoxChoice(reFreshCheckBox));
    } else {
      const val = [...checkBoxChoice, type];
      dispatch(setCheckBoxChoice(val));
    }
  };
  const funSetSelectCountryPoint = (type) => {
    let index = null;
    const finder = selectCountryPoint.filter((el, i) => {
      // если finder нашел повторение его длинна увеличится
      if (el.id === type.id) {
        index = i;
      }
      return el.id === type.id;
    });
    if (finder.length !== 0) {
      const numberToDelete = selectCountryPoint[index];
      const filteredNumbers = selectCountryPoint.filter((number) => number !== numberToDelete);

      const reFreshCheckBox = checkBoxChoice.filter((el) => {
        const currentId = el.id;

        return !selectCompanyPoint.find((s) => s.id === currentId) && el.id !== type.id;
      });

      dispatch(setSelectCountryPoint(filteredNumbers)); // для нижнего чек бокса
      dispatch(setCountryPoints(filteredNumbers)); // для галлереи
      dispatch(setCheckBoxChoice(reFreshCheckBox));
      dispatch(setClearSelectCompanyPoint());
      dispatch(setClearCompanyPoints());
      // setSelectCompanyPoint([])
    } else {
      const val = [...selectCountryPoint, type];
      dispatch(setSelectCountryPoint(val)); // для нижнего чек бокса
      dispatch(setCountryPoints(val)); // для галлереи
    }
  };

  // const funSetUpData = () => {
  //   setUpData((prev) => !prev)
  // }
  // const funPageCount = () => setPageCount(1)
  // const funPageCount = () => filters.showMoreCardsBtn(1)

  const funClearCards = () => {
    // dispatch(setClearCards())
  };

  const [query, setQuery] = useState('');

  function inputChange(e) {
    setQuery(e.target.value);
  }

  const onInputChange = useCallback(
    (e) => {
      setQuery(e.target.value);
    },
    [setQuery],
  );

  const newArr = countryPoints.filter((el) => {
    const newString = el.fullname.toLowerCase();
    const newString2 = query.toLowerCase();
    return newString.includes(newString2);
  });

  return (
    <div className={styles.containerWrapper}>
      {isMobile && <div className={styles.mobileFilterTxt}>Страна</div>}
      <div className={styles.container}>
        <div className={styles.searchInputWrapper}>
          <img src={searchImg} className={styles.searchImg} />
          <input
            type="text"
            placeholder="Поиск"
            className={styles.input}
            onChange={onInputChange}
          />
        </div>

        <div className={styles.mapButton}>
          {newArr.map((el) => (
            <div
              className={styles.button}
              key={el.id}
              onClick={() => {
                funClearCards();
                filters.setPageCount(1);

                funSetSelectCountryPoint(el);
                funSetCheckBoxChoice(el);
                // funPageCount()
                // funClearCards()
                // funSetUpData()
              }}>
              <div className={styles.checkWrapper}>
                {!!selectCountryPoint.find((point) => point.id === el.id) === true && (
                  <img src={checkBoxFull} className={styles.img} />
                )}
                {!!selectCountryPoint.find((point) => point.id === el.id) === false && (
                  <img src={checkBoxEmpty} className={styles.img} />
                )}
              </div>
              <div className={styles.txt}>{el.fullname}</div>
            </div>
          ))}
          {isMobile && <div className={styles.underSpaceToScroll} />}
        </div>
      </div>

      {isMobile && (
        <div className={styles.aceptButtonWrapper}>
          <div
            className={styles.acceptButtonActive}
            onClick={() => {
              setActivCountryMenu(false);
            }}>
            Применить
          </div>
        </div>
      )}
      {isMobile && (
        <div className={styles.closeImg}>
          <img
            src={closeXSign}
            onClick={() => {
              setActivCountryMenu(false);
            }}
          />
        </div>
      )}
    </div>
  );
};

export default FilterSection;
