import {createSlice} from "@reduxjs/toolkit";

const initialState = {
//   mainNewCards: []
    // filtredCompanyPoints: [],
    page_category: [],
    country_tag: [],
    sector: [],
    company_tag: [],
    
}

const cardsFiltredForCheckboxPointsSlice = createSlice({
    name: 'checkBoxPoints',
    initialState,
    reducers: {
        setAllFilterPoints: (state, action) => {
            state.page_category = action.payload.page_category
            state.country_tag = action.payload.country_tag
            state.sector = action.payload.sector
            state.company_tag = action.payload.company_tag

        },
        setCompanyPointsToCheckbox: (state, action) => {
            state.company_tag = action.payload.company_tag
        }
    }

})

export const {
    setAllFilterPoints,
    setCompanyPointsToCheckbox
} = cardsFiltredForCheckboxPointsSlice.actions

export default cardsFiltredForCheckboxPointsSlice.reducer;