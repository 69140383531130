import styles from "./education-info-section.module.css";


function EducationInfoSection() {
    return (
        <section className={styles.section}>

            <div className={styles.container}>
                <div className={styles.textBox}>
                    <h1 className={styles.title}>Обучение инвестициям</h1>
                    <p className={styles.text}>Более 60 тем для начинающих,<br /> продолжающих и продвинутых инвесторов</p>
                </div>

            </div>
        </section>
    )
}

export default EducationInfoSection