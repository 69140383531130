import styles from "./main-section-product.module.css"
import unity from "../../images/main-page-man-mind-img.svg"
import ideas from "../../images/main-page-woman-rest-img.svg"
import premium from "../../images/main-page-people-working-img.svg"
import { useMediaQuery } from "react-responsive"
import { NavLink } from "react-router-dom"

function MainSectionProduct() {
  const isMobile = useMediaQuery({ query: "(max-width: 760px)" })

  return (
    <div>
      <section className={styles.section} id="product">
        <div className={styles.containerWrapper}>
          <div className={styles.titleHead}>Наши продукты</div>
          <div className={styles.container}>
            <div className={styles.boxWrapper}>
              <div className={styles.contentBox}>
                <div className={styles.linkWrapper}>
                  <div className={styles.titleSection}>
                    <div className={styles.title}>Unity</div>
                    <div className={styles.subTitle}>
                      Научим самостоятельно инвестировать {!isMobile && <br />}и
                      поможем повысить доходность
                    </div>
                  </div>
                  <div className={styles.linksContainer}>
                    <NavLink
                      to={"/products/unity-russia"}
                      className={styles.link}
                      style={!isMobile ? { marginTop: "42px" } : null}
                    >
                      Российский рынок
                    </NavLink>
                    <NavLink
                      to={"/products/unity-world"}
                      className={styles.link}
                    >
                      Иностранные рынки
                    </NavLink>
                    <NavLink to={"/products/unity-all"} className={styles.link}>
                      Все рынки
                    </NavLink>
                  </div>
                </div>

                <img src={unity} className={styles.img} />
              </div>
            </div>

            <div className={styles.boxWrapper}>
              <div className={styles.contentBox}>
                <div className={styles.linkWrapper}>
                  <div className={styles.titleSection}>
                    <div className={styles.title}>Ideas</div>
                    <div className={styles.subTitle}>
                      Следуйте нашим торговым сигналам {!isMobile && <br />}и
                      обгоняйте индекс
                    </div>
                  </div>
                  <div className={styles.linksContainer}>
                    <NavLink
                      to={"/products/ideas-russia"}
                      className={styles.link}
                      style={!isMobile ? { marginTop: "42px" } : null}
                    >
                      Российский рынок
                    </NavLink>
                    <NavLink
                      to={"/products/ideas-world"}
                      className={styles.link}
                    >
                      Иностранные рынки
                    </NavLink>
                    <NavLink to={"/products/ideas-all"} className={styles.link}>
                      Все рынки
                    </NavLink>
                  </div>
                </div>

                <img src={ideas} className={styles.img} />
              </div>
            </div>

            <div className={styles.boxWrapper}>
              <div className={styles.contentBox}>
                <div className={styles.linkWrapper}>
                  <div className={styles.titleSection}>
                    <div className={styles.title}>Premium</div>
                    <div className={styles.subTitle}>
                      Премиальный сервис, индивидуальный портфель, личный
                      менеджер
                    </div>
                  </div>
                  <div className={styles.linksContainer}>
                    <NavLink
                      to={"/products/premium"}
                      className={styles.link}
                      style={!isMobile ? { marginTop: "42px" } : null}
                    >
                      Подробнее
                    </NavLink>
                  </div>
                </div>

                <img src={premium} className={styles.img} />
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}

export default MainSectionProduct
