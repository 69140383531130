import { NavLink, useNavigate, useParams } from "react-router-dom"
import { useEffect, useMemo, useState } from "react"
import styles from "./subscription-page-info.module.css"
import { useProfile } from "../../../../../services/context/ProfileContext"
import TopMainTitle from "./ideas-all-sections/top-main-title/top-main-title"
import GraphSliderGallery from "./ideas-all-sections/gallery-tables/gallery-tables"
import FirstDescription from "./ideas-all-sections/first-description-product/first-description-product"
import SecondFlags from "./ideas-all-sections/easy-start/easy-start-product"
import SubscriptionPrice from "./ideas-all-sections/subscription-price-section/subscription-price"
import PromotionPromocode from "./ideas-all-sections/promotion-promocode/promotion-promocode"
import Review from "./ideas-all-sections/reviews/reviews"
import EasyStartSection from "./ideas-all-sections/video-section/video-section"
import SEO from "../../../../../utils/SEO"
function SeoScript() {
  return (
    <script type="application/ld+json">
{`{
  "@context": "https://schema.org",
  "@type": "Product",
  "description": "Торговые сигналы с обоснованием приходят в Telegram. Структура портфеля и потенциал роста доступны в онлайн режиме",
  "name": "Ideas All",
  "image": "_.img",
  "offers": {
    "@type": "Offer",
    "price": "2167.00",
    "priceCurrency": "RUB"
  }
}`}
</script>
  )
}

const IdeasAll = () => {
  const valueContext = useProfile()
  const { slug } = useParams()
  const navigate = useNavigate()

  useMemo(() => {
    // valueContext.pageFromSlugUrl(slug)
  }, [slug])

  // const card = valueContext.urlPageData
  return (
    <>
    <SEO
        title="Ideas All - подписка от Invest Era"
        description="Посвящайте инвестициям 10 минут в день и обгоняйте индекс. • Долгосрочный и спекулятивные портфели, • портфель облигаций, • Оптимальный, дивидендный и спекулятивный портфели США, • Долгосрочный портфель Китай"
        ogTitle="Ideas All - подписка от Invest Era"
        ogDescription="Посвящайте инвестициям 10 минут в день и обгоняйте индекс. • Долгосрочный и спекулятивные портфели, • портфель облигаций,• Оптимальный, дивидендный и спекулятивный портфели США, • Долгосрочный портфель Китай"
        script={SeoScript}
        />
        <TopMainTitle />
        <GraphSliderGallery/>
        <FirstDescription/>
        <SecondFlags/>
        <EasyStartSection/>
        <SubscriptionPrice />
       {/*
        <PromotionPromocode/>
        */}
        <Review/>
    </>
  )
}

export default IdeasAll
