import React, {useEffect, useState} from 'react';
import {PriceChart} from "../../../../price-chart/price-chart";
import styles from './skriner-tab-analysis.module.css'
import {LineChartIcon} from "../../../../../images/components/line-chart";
import {CandleChartIcon} from "../../../../../images/components/candle-chart";
import {BarChartIcon} from "../../../../../images/components/bar-chart";
import Preloader from "../../../../preloader/preloader";

const SkrinerTabAnalysis = ({information}) => {
    const [activeButton, setActiveButton] = useState('candle')
    const [period, setPeriod] = useState('1w');
    const [isLoading, setIsLoading] = useState(false)

    const changeChartStyle = (style) => {
        setActiveButton(style)
    }

    const changeChartPeriod = (period) => {
        setPeriod(period)
    }

    const styleButtons = [
        {id: 11, title: 'candle', img: <CandleChartIcon/>},
        {id: 22, title: 'line', img: <LineChartIcon/>},
        {id: 33, title: 'bar', img: <BarChartIcon/>}
    ]

    const periodButtons = [
        {id: 1, title: '1d', name: 'Мин'},
        {id: 2, title: '1w', name: 'Ч'},
        {id: 3, title: '1m', name: 'Д/мес'},
        {id: 4, title: '3m', name: 'Д/3 мес'},
        {id: 5, title: '6m', name: 'Д/6 мес'},
        {id: 6, title: '1y', name: 'Д/год'},
        {id: 6, title: 'all', name: 'Мес'},
    ]

    return (
        <div>
            <div className={styles.chartContainer}>
                <h2 className={styles.header}>График</h2>

                <div className={styles.buttonsContainer}>
                    <div className={styles.typeButtonsContainer}>
                        {styleButtons.map(el => {
                            return <button key={el.id} className={`${styles.button} ${activeButton === el.title && styles.activeButton}`}
                                           onClick={() => changeChartStyle(el.title)}>
                                {el.img}
                            </button>
                        })}
                    </div>

                    <div className={styles.typeButtonsContainer}>
                        {periodButtons.map(el => {
                            return <button key={el.id} className={`${styles.button} ${period === el.title && styles.activeButton}`}
                                           onClick={() => changeChartPeriod(el.title)}>
                                {el.name}
                            </button>
                        })}
                    </div>
                </div>

                {isLoading && <Preloader/>}
                <PriceChart information={information} activeButton={activeButton} period={period}
                            setIsLoading={setIsLoading}/>

            </div>
        </div>
    );
};

export default SkrinerTabAnalysis;