import styles from './main-section-social-media-new.module.css'
import {NavLink} from "react-router-dom";
import {Swiper, SwiperSlide} from "swiper/react";

function MainSectionSocialMediaNew() {


    return (
        <section className={styles.section}>
            <h3 className={styles.title}>Мы в Telegram</h3>
            <div className={styles.container}>

                <NavLink to={'https://t.me/usamarke1'} className={styles.link} target={'_blank'}>
                    <div className={styles.card}>
                        <div className={styles.cardTop}>
                            <div className={styles.logoFirst}></div>
                            <div className={styles.arrow}></div>
                        </div>
                        <h4 className={styles.titleCard}>Invest Era</h4>
                        <p className={styles.text}>Фундаментальные разборы компаний <br/> и экономической ситуации</p>
                    </div>
                </NavLink>

                <NavLink to={'https://t.me/AnalyticsUSARussiaMarkets'} className={styles.link} target={'_blank'}>
                    <div className={styles.card}>
                        <div className={styles.cardTop}>
                            <div className={styles.logoSecond}></div>
                            <div className={styles.arrow}></div>
                        </div>
                        <h4 className={styles.titleCard}>Era Media</h4>
                        <p className={styles.text}>Новости, которые повлияют <br/> на котировки компаний и рынок</p>
                    </div>
                </NavLink>

                <NavLink to={'https://t.me/infousamarket'} className={styles.link} target={'_blank'}>
                    <div className={styles.card}>
                        <div className={styles.cardTop}>
                            <div className={styles.logoThird}></div>
                            <div className={styles.arrow}></div>
                        </div>
                        <h4 className={styles.titleCard}>Era Global</h4>
                        <p className={styles.text}>Качественная аналитика <br/> по иностранным фондовым рынкам</p>
                    </div>
                </NavLink>

            </div>

            <div className={styles.phone}>

                <NavLink to={'https://t.me/usamarke1'} className={styles.link} target={'_blank'}>
                    <div className={styles.cardPhone}>
                        <div className={styles.cardTop}>
                            <div className={styles.logoFirst}></div>
                            <div className={styles.arrow}></div>
                        </div>
                        <h4 className={styles.titleCard}>Invest Era</h4>
                        <p className={styles.text}>Фундаментальные разборы компаний <br/> и экономической ситуации</p>
                    </div>
                </NavLink>

                <NavLink to={'https://t.me/AnalyticsUSARussiaMarkets'} className={styles.link} target={'_blank'}>
                    <div className={styles.cardPhone}>
                        <div className={styles.cardTop}>
                            <div className={styles.logoSecond}></div>
                            <div className={styles.arrow}></div>
                        </div>
                        <h4 className={styles.titleCard}>Era Media</h4>
                        <p className={styles.text}>Новости, которые повлияют <br/> на котировки компаний и рынок</p>
                    </div>
                </NavLink>

                <NavLink to={'https://t.me/infousamarket'} className={styles.link} target={'_blank'}>
                    <div className={styles.cardPhone}>
                        <div className={styles.cardTop}>
                            <div className={styles.logoThird}></div>
                            <div className={styles.arrow}></div>
                        </div>
                        <h4 className={styles.titleCard}>Era Global</h4>
                        <p className={styles.text}>Качественная аналитика <br/> по иностранным фондовым рынкам</p>
                    </div>
                </NavLink>

            </div>
        </section>
    )
}

export default MainSectionSocialMediaNew