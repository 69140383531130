import {Bar, BarChart, CartesianGrid, Legend, ResponsiveContainer, Tooltip, XAxis, YAxis} from 'recharts';
import styles from './finance-bar-chart.module.css'
import {processData} from "../utilsForFinanceTab";
import React from "react";
import {CustomLegend} from "./custom-legend/custom-legend";
import {CustomTooltip} from "./custom-tooltip/custom-tooltip";

export const FinanceBarChart = ({
                                    isRevenue = false,
                                    tableRowTitle,
                                    selectedRows,
                                    tableRowTitleSecond,
                                    selectedCompany
                                }) => {

    const dataRevenue = [
        {
            name: '2018',
            'revenue': 1394376,
            'revenueCompare': 394376,
            'clearProfit': 1021375,
            'clearProfitCompare': 9021375,
            'cost': 4356467,
            'costCompare': 2356467,
            'gross': 243533,
            'grossCompare': 1243533,
        },
        {
            name: '2019',
            'revenue': 2394707,
            'revenueCompare': 394376,
            'clearProfit': 1105128,
            'clearProfitCompare': 9021375,
            'cost': 123456,
            'costCompare': 2356467,
            'gross': 2342343,
            'grossCompare': 1243533,

        },
        {
            name: '2020',
            'revenue': 1959015,
            'revenueCompare': 394376,
            'clearProfit': 977857,
            'clearProfitCompare': 9021375,
            'cost': 234523,
            'costCompare': 2356467,
            'gross': 2131245,
            'grossCompare': 1243533,

        },
        {
            name: '2021',
            'revenue': 2986178,
            'revenueCompare': 394376,
            'clearProfit': 1405486,
            'clearProfitCompare': 9021375,
            'cost': 1543435,
            'costCompare': 2356467,
            'gross': 2231245,
            'grossCompare': 1243533,

        },
        {
            name: '2022',
            'revenue': 3412129,
            'revenueCompare': 394376,
            'clearProfit': 315290,
            'clearProfitCompare': 9021375,
            'cost': 412435,
            'costCompare': 2356467,
            'gross': 457645,
            'grossCompare': 1243533,

        },
        {
            name: '2023',
            'revenue': 3519959,
            'revenueCompare': 394376,
            'clearProfit': 427049,
            'clearProfitCompare': 9021375,
            'cost': 4123412,
            'costCompare': 2356467,
            'gross': 1124312,
            'grossCompare': 1243533,

        },
        {
            name: 'TTM',
            'revenue': 3557158.5,
            'revenueCompare': 394376,
            'clearProfit': 779953,
            'clearProfitCompare': 9021375,
            'cost': 231214,
            'costCompare': 2356467,
            'gross': 1123143,
            'grossCompare': 1243533,
        },
    ];

    const dataOperating = [
        {
            name: '2018',
            'Операционная прибыль': 2394376,
            'Административные расходы': 2394376,
            'Операционные расходы': 1435427,
            'Расходы на исследования и развитие': 1432234,
        },
        {
            name: '2019',
            'operatingProfit': 2394707,
            'operatingProfitCompare': 2394707,
            'adminExp': 1105128,
            'adminExpCompare': 1105128,
            'operatingExp': 1244457,
            'operatingExpCompare': 1244457,
            'researchDev': 1234523,
            'researchDevCompare': 1234523,
        },
        {
            name: '2020',
            'Операционная прибыль': 1959015,
            'Административные расходы': 977857,
            'Операционные расходы': 2463253,
            'Расходы на исследования и развитие': 1235433,
        },
        {
            name: '2021',
            'Операционная прибыль': 2986178,
            'Административные расходы': 1405486,
            'Операционные расходы': 233543,
            'Расходы на исследования и развитие': 345452,
        },
        {
            name: '2022',
            'Операционная прибыль': 3412129,
            'Административные расходы': 315290,
            'Операционные расходы': 1243245,
            'Расходы на исследования и развитие': 2342345,
        },
        {
            name: '2023',
            'Операционная прибыль': 3519959,
            'Административные расходы': 427049,
            'Операционные расходы': 1453345,
            'Расходы на исследования и развитие': 4364534,
        },
        {
            name: 'TTM',
            'Операционная прибыль': 3557158.5,
            'Административные расходы': 779953,
            'Операционные расходы': 432425,
            'Расходы на исследования и развитие': 4354367,
        },
    ];

    const processedData = processData(isRevenue ? dataRevenue : dataOperating);

    return (
        <div style={{width: '100%'}}>

            <ResponsiveContainer height={420}>
                <BarChart
                    width={500}
                    height={300}
                    data={processedData}
                    margin={{
                        top: 20,
                        right: 30,
                        left: 20,
                        bottom: 5
                    }}
                >
                    <CartesianGrid strokeDasharray="3 3"/>
                    <XAxis dataKey="name"/>
                    <YAxis
                        tickFormatter={(value) => `${value} трлн`}
                    />
                    <Tooltip content={<CustomTooltip ticker={selectedCompany?.ticker} />}/>
                    <Legend wrapperStyle={{paddingTop: '16px'}} content={CustomLegend}/>
                    {selectedRows.map((el) => {
                        return <Bar
                            key={tableRowTitle[el - 1].id}
                            dataKey={tableRowTitle[el - 1].title}
                            fill={tableRowTitle[el - 1].fill}
                            stackId={tableRowTitle[el - 1].stackId}
                        />
                    })}
                    {tableRowTitleSecond && selectedRows.map((el) => {
                        return <Bar
                            radius={[4, 4, 0, 0]}
                            key={tableRowTitleSecond[el - 1].id}
                            dataKey={tableRowTitleSecond[el - 1].title}
                            fill={tableRowTitleSecond[el - 1].fill}
                            stackId={tableRowTitleSecond[el - 1].stackId}
                        />
                    })}

                </BarChart>
            </ResponsiveContainer>
        </div>
    );
};
