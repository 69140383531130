import {createSlice} from "@reduxjs/toolkit";

const initialState = {
    selectedItems: [],
}

const selectedItemsSlice = createSlice({
    name: 'selected-item',
    initialState,
    reducers: {
        setSelectedItems: (state, action) => {
            state.selectedItems = action.payload
        }
    }
})

export const {
    setSelectedItems
} = selectedItemsSlice.actions

export default selectedItemsSlice.reducer;