import { useRef } from 'react';
import { NavLink } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
import Close from '../../../images/close-x-sign-svg.svg';
import BgImg from '../../../images/Rectangle1103.png';
import Moneta1 from '../../../images/moneta1.svg';
import Moneta2 from '../../../images/moneta2.svg';
import Moneta3 from '../../../images/moneta3.svg';
import styles from './popUpTables.module.css';

export const PopUpTimeOutTables = ({ setShowTimeoutPopupSecond }) => {
  const isMobile = useMediaQuery({ query: '(max-width: 760px)' });
  const modalRef = useRef(null);
  const handleClickOutside = (e) => {
    if (modalRef.current && !modalRef.current.contains(e.target)) {
      setShowTimeoutPopupSecond(false);
      window.ym(96742836, 'reachGoal', 'popup-promo-start-close');
    }
  };

  const closePopupForImgCLick = () => {
    setShowTimeoutPopupSecond(false);
    window.ym(96742836, 'reachGoal', 'popup-promo-start-close');
  };

  const clickForBtn = () => {
    setShowTimeoutPopupSecond(false);
    window.ym(96742836, 'reachGoal', 'popup-promo-start-lead');
  };
  return !isMobile ? (
    <div onClick={handleClickOutside} className={styles.wrapper}>
      <div ref={modalRef} className={styles.popup}>
        <div onClick={closePopupForImgCLick} className={styles.close}>
          <img src={Close} alt="close" />
        </div>
        <div className={styles.container}>
          <h2 className={styles.title}>Готовые портфели за 10 рублей по промокоду START</h2>
          <h3 className={styles.subTitle}>
            Доходность нашего российского портфеля 102% за 2 года.
          </h3>
          <img className={styles.bg} src={BgImg} alt="bg" />
          <img className={styles.moneta1} src={Moneta1} alt="moneta1" />
          <img className={styles.moneta2} src={Moneta2} alt="moneta2" />
          <img className={styles.moneta3} src={Moneta3} alt="moneta3" />
          <NavLink onClick={clickForBtn} to={'/profile'} className={styles.btn}>
            Перейти к подпискам
          </NavLink>
        </div>
      </div>
    </div>
  ) : (
    <div onClick={handleClickOutside} className={styles.wrapper}>
      <div ref={modalRef} className={styles.popup}>
        <div onClick={closePopupForImgCLick} className={styles.close}>
          <img src={Close} alt="close" />
        </div>
        <div className={styles.container}>
          <h2 className={styles.title}>Готовые портфели за 10 рублей по промокоду START</h2>
          <h3 className={styles.subTitle}>
            Доходность нашего российского портфеля 102% за 2 года.
          </h3>
          <div className={styles.imgWrapper}>
            <img className={styles.bg} src={BgImg} alt="bg" />
            <img className={styles.moneta1} src={Moneta1} alt="moneta1" />
            <img className={styles.moneta2} src={Moneta2} alt="moneta2" />
            <img className={styles.moneta3} src={Moneta3} alt="moneta3" />
          </div>
          <NavLink onClick={clickForBtn} to={'/profile'} className={styles.btn}>
            Перейти к подпискам
          </NavLink>
        </div>
      </div>
    </div>
  );
};
