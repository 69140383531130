import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import StocksImg from '../../../images/stocks-block-img.svg';
import Copy from '../../../images/copy.svg';

import styles from './stocks.module.css';

const Stocks = () => {
  const { timerStatus } = useSelector((state) => state.timerSlice);
  const [firstValueInput, setFirstValueInput] = useState('JUNE7');
  const [secondValueInput, setSecondValueInput] = useState('JUNE14');

  const copyToClipboard = (text) => {
    navigator.clipboard
      .writeText(text)
      .then(() => {
        console.log('Copied to clipboard!');
      })
      .catch((err) => {
        console.error('Failed to copy: ', err);
      });
  };

  return (
    <div style={timerStatus === false ? { display: 'none' } : null} className={styles.stocks}>
      <h2 className={styles.title}>Акция</h2>
      <div className={`${styles.wrapper} ${styles.first}`}>
        <div className={styles.stocksImg}>
          <img src={StocksImg} alt="stocks" />
        </div>
        <div className={styles.text}>
          1 месяц бесплатно при покупке подписки на пол года с промокодом:
        </div>
        <div className={styles.copyText}>
          <input
            className={styles.input}
            type="text"
            readOnly={true}
            value={firstValueInput}
            onChange={(e) => setFirstValueInput(e.target.value)}
          />
          <div onClick={() => copyToClipboard(firstValueInput)} className={styles.copyImgBlock}>
            <img className={styles.copyImg} src={Copy} alt="copy" />
          </div>
        </div>
      </div>
      <div className={styles.line}></div>
      <div className={`${styles.wrapper} ${styles.second}`}>
        <div className={styles.stocksImg}>
          <img src={StocksImg} alt="stocks" />
        </div>
        <div className={styles.text}>
          2 месяца бесплатно при покупке подписки на год с промокодом:
        </div>
        <div className={styles.copyText}>
          <input
            className={styles.input}
            type="text"
            readOnly={true}
            value={secondValueInput}
            onChange={(e) => setSecondValueInput(e.target.value)}
          />
          <div onClick={() => copyToClipboard(secondValueInput)} className={styles.copyImgBlock}>
            <img className={styles.copyImg} src={Copy} alt="copy" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Stocks;
