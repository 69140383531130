import {NavLink} from "react-router-dom"
import SearchSection from "../search/search"
import Gallery from "../gallery/gallery"
import MainSectionSocialMediaNew from "../main-section-social-media-new/main-section-social-media-new"
import SEO from "../../utils/SEO"
import SeoScriptMain from './seo-script';
import styles from "./main.module.css"
// import {MetaTagsForPagesWithGetParams} from "../../utils/metaTagsForPagesWithGetParams";

const AppMainAnn = () => {
    return (
        <>
            {/*<MetaTagsForPagesWithGetParams/>*/}
            <SEO
                title="Аналитика и новости от Invest Era"
                description="Актуальные новости с комментариями экспертов, аналитика по компаниям из России, США и Китая."
                ogTitle="Аналитика и новости от Invest Era"
                ogDescription="Актуальные новости с комментариями экспертов, аналитика по компаниям из России, США и Китая."
                script={SeoScriptMain}
            />
            <main className={styles.app}>
                <div className={styles.linkWrapper}>
                    <NavLink to={"/"} className={styles.link}>
                        <div className={styles.arrow}></div>
                        Главная
                    </NavLink>
                </div>
                <SearchSection/>
                <Gallery/>
            </main>
            <MainSectionSocialMediaNew/>
        </>
    )
}

export default AppMainAnn
