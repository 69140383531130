import styles from "./webinarbox.module.css"
import { NavLink } from "react-router-dom"
import { useEffect, useState } from "react"

function WebinarBox({data}) {
    console.log(data)

  return (
      <NavLink to={data.link} className={styles.section}>
        <div className={styles.containerWrapper}>
          <div className={styles.bannerContainer}>
          <img src={data.banner} className={styles.banner}/>
          </div>
          <div className={styles.titleWrapper}>

            <div className={styles.title}>
            {data.name}
            </div>
          </div>

        </div>
      </NavLink>
  )
}

export default WebinarBox
