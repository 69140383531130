import styles from "./first-description-product.module.css"
import { useMediaQuery } from "react-responsive"
import Logos from "../../../../../../images/product-logos-company.svg"
import LogosMob from "../../../../../../images/product-logos-company-mobile.svg"
import ArrowGreen from "../../../../../../images/arrow-product-green-right.svg"
import logosGroup from "../../../../../../images/product-logos-group-russia.svg"
import logosGroupMob from "../../../../../../images/product-logos-group-russia-mob.svg"
import phone from "../../../../../../images/product-phone-image-russia.svg"
import { NavLink } from "react-router-dom"
const FirstDescription = () => {
  const isMobile = true;

  return (
    <div className={styles.section}>
      <div className={styles.containerWrapper}>
        <div className={styles.container}>
          {!isMobile && <img src={phone} className={styles.phoneImg} />}
          <div className={styles.title}>
            Подскажем какие акции покупать и продавать
          </div>
          <div className={styles.subTitle}>
            Аналитические разборы 100 компаний РФ <br />и инвестиционные идеи
          </div>
          {isMobile && (
            <div className={styles.imageWrapper}>
              <img src={phone} className={styles.phoneImg} />
              <img src={logosGroupMob} className={styles.logosGroupImgMob} />
            </div>
          )}
          {!isMobile && (
            <img src={logosGroup} className={styles.logosGroupImg} />
          )}
        </div>
      </div>
    </div>
  )
}

export default FirstDescription
